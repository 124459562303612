import "react-credit-cards/es/styles-compiled.css";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  height: 100vh;
  /* margin-top: 70px; */
`;

export const BotaoComprar = styled.div`
  width: 300px;
  height: 50px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

export const ViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  /* padding-bottom: 100px; */

  position: relative;
  top: -5px;
  z-index: 200;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @media screen and (min-width: 1024px) {
    top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

export const TextoComprar = styled.span`
  color: #fff;
`;

export const ViewPagamentos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BotaoPagamento = styled.div`
  width: 150px;
  height: 50px;
  background-color: ${({ ativo }) => (ativo ? "red" : "#444")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
`;

export const TextoPagamento = styled.span`
  font-size: 18px;
  color: #fff;
`;

export const Precos = styled.div`
  width: 100%;
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ViewPreco = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
`;

export const ViewPrecoConcluir = styled.div`
  width: 100%;
  max-width: 430px;
  position: fixed;
  bottom: 10px;

  /* position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  bottom: 80px; */
  display: flex;
  justify-content: center;
`;

export const BotaoConcluir = styled.button`
  width: 85%;
  border: none;
  height: 50px;
  background-color: ${({ disabled, corFundo }) => (disabled ? "gray" : corFundo)};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 20px;
  cursor: pointer;
  position: relative;
`;

export const TextoConcluir = styled.span`
  font-size: 18px;
  margin-left: 25px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  // width: 50px;
`;

export const TextoValorUnitario = styled.p`
  text-align: center;
  font-size: 18px;
`;

export const Botoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BotaoQuantidade = styled.div`
  width: 50px;
  height: 50px;
  background-color: #eee;
  margin: 0 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Quantidade = styled.div`
  font-size: 20px;
`;

export const ViewInputs = styled.form`
  margin-left: 10px;
  width: 100%;
`;

export const Input = styled.input`
  width: 86%;
  margin: 5px;
  padding: 19px;
  background-color: #e8e8e8;
  border: none;
  border-radius: 5px;
`;

export const TituloInput = styled.p``;

export const ViewModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const AlinharInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ViewSucesso = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BotaoVoltar = styled.div`
  width: 10%;
  height: 50px;
  position: absolute;
  cursor: pointer;
  right: 15px;
  bottom: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */

  &:hover {
    text-decoration: underline;
  }
`;

export const TextoVoltar = styled.span`
  color: #000;
  font-size: 20px;
`;

export const TextoSucesso = styled.span`
  color: green;
  font-size: 18px;
`;

export const Rodape = styled.footer`
  width: 100%;
  height: 300px;
  background-color: #1f1f29;
`;

export const ViewFoto = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 1024px) {
    height: 170px;
    margin-top: 15px;
    border-radius: 5px;
  }
`;

export const Foto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const NomeEvento = styled.p`
  font-size: 18px;
  color: #fff;
  margin-bottom: -5px;
  text-align: center;
`;

export const HorarioEvento = styled.p`
  font-size: 16px;
  color: #fff;
  margin: 0;
`;

export const Descricao = styled.p`
  font-size: 0.8em;
  color: #999;
  margin: 5px;
`;

export const Alinhar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 300;
  bottom: 5px;
  align-items: center;
  justify-content: center;
`;

export const BotaoVerificarPagamento = styled.div``;

export const DescricaoContainer = styled.div`
  margin-top: 15px;
`;

export const DescricaoText = styled.p`
  color: #000;
  font-size: 13px;
`;

export const ViewPassaporte = styled.div`
  width: 129px;
  height: 127.471px;
  margin-right: 10px;
  background-color: transparent;
`;

export const ImagemPassaporte = styled.img`
  width: 100%;
  height: 100%;
`;

export const EventData = styled.p`
  position: absolute;
  bottom: 5px;
  align-self: center;
  color: #000;
  font-size: 13px;
  letter-spacing: 1.2px;
  z-index: 20;
`;

export const TextoPassaporte = styled.p`
  color: #000;
  font-size: 14px;
  width: 127px;
`;

export const TextModal = styled.p`
  color: #000;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
`;

export const Titulo = styled.p`
  color: blue;
  font-size: 14px;
`;

export const TextTempoCancel = styled.p`
  color: blue;
  font-size: 14px;
`;

export const TextVermelho = styled.span`
  color: red;
  font-size: 14px;
`;

export const TextoAceitarTermos = styled.span`
  font-size: 14px;
  line-height: 20px;
`;

export const TotalConcluir = styled.span`
  font-size: 16px;
  background-color: #fff;
  padding: 0 20px;
  /* margin: 10px; */
  color: green;
  margin-left: 25px;
  border-radius: 5px;
`;

export const ViewTermos = styled.div`
  overflow-y: scroll;
  height: 80vh;
`;

export const ViewAceitarTermos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
