import React, { useRef, useState } from 'react';

import {
  Container,
  Info,
  ContainerCidade,
  ViewCidade,
  ViewCidadeInt,
} from './styles';

import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import Slider from 'react-slick';

function ListaCidades({ dados, aoClicar, cidade }) {
  const [rolando, setRolando] = useState(false);

  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 3,
    variableWidth: true,
    arrows: false,

    beforeChange: () => setRolando(true),
    afterChange: () => setRolando(false),

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true
    //     }
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       initialSlide: 2
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1
    //     }
    //   }
    // ]
  };

  function aoClicarT(item) {
    if (rolando) return;

    aoClicar(item);
  }


  return (
    <Container>
      <div style={{ padding: '5px 10px' }}>
        <Slider {...settings} >
          {dados.map((item, index) => (
            <ViewCidade
              key={item.id}
              onClick={() => aoClicarT(item?.id)}
            >
              <ViewCidadeInt ativo={cidade === item.id}>
                <MapsHomeWorkOutlinedIcon style={{ backgroundColor: 'rgba(255, 255, 255, 0.12)', height: 40, paddingLeft: 8, paddingRight: 8, borderRadius: 30 }} />
                <Info>{item.nome}</Info>
              </ViewCidadeInt>
            </ViewCidade>
          ))}
        </Slider>
      </div>
    </Container>
  );
}

export default ListaCidades;