import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  /* margin-top: 70px; */
  
`;

export const ViewCarregando = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 20;
  margin-bottom: 30px;
`;

export const ViewListas = styled.div`
  margin-top: 0px;
`;

export const Titulo = styled.span`
  font-size: 14px;
  padding: 0px 10px;
`;

export const ViewSemEventos = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TextoSemEventos = styled.span`
  font-size: 24px;
`;

export const SubTextoSemEventos = styled.span`
  width: 450px;
  font-size: 16px;
  font-weight: lighter;
  margin-top: -5px;
  text-align: center;
`;

export const ImagemIconeTicket = styled.img`
  width: 200px;
  height: 130px;
`;

export const RodapeMenu = styled.div`
  width: 100%;
  height: 50px;
  background-color: red;
`;

export const BotaoCadastro = styled.div`
  position: fixed;
  bottom: 15px;
  left: 5px;
  width: 130px;
  height: 40px;
  cursor: pointer;
  /* background-color: rgba(245, 39, 39, 0.9); */
  border-radius: 50px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 1000;
`;

export const TextoCadastro = styled.span`
  color: #fff;
  /* font-weight: bold; */
  font-size: 0.9em;
`;

export const ViewVazio = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VazioTexto = styled.span`

`;

