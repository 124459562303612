import { TextField } from "@mui/material";
import "react-credit-cards/es/styles-compiled.css";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;

  /* position: relative; */
  /* height: calc(100vh); */
  /* margin-top: 70px; */
`;

export const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
  /* position: relative; */
`;

export const ViewPagamentos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Precos = styled.div`
  width: 68vw;
  /* background-color: red; */
  // position: absolute;
  /* left: 0px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin-top: 30px;
  position: fixed;
  bottom: 0;
  /* right: 0; */
  margin-left: auto;
  margin-right: auto;

  /* position: fixed;
  left: 50%;
  right: 50%;
  bottom: 0;
  */
  /* transform: translate(-50%, -50%);  */
  background-color: #fff;

  @media screen and (max-width: 570px) {
    width: 100vw;
  }
`;

export const ViewPreco = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: -10px;
`;

export const ViewPrecoConcluir = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
`;

export const ViewTotal = styled.div`
  display: flex;
`;

export const Texto = styled.span``;

export const Preco = styled.span`
  color: green;
`;

export const TituloMetodoPagamento = styled.p`
  text-align: center;
  margin: 5px 0;
  font-size: 1.1em;
`;

export const BotaoConcluir = styled.button`
  width: 200px;
  border: none;
  height: 50px;
  background-color: ${({ disabled, corFundo }) => (disabled ? "gray" : corFundo)};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
`;

export const TextoConcluir = styled.span`
  font-size: 18px;
  color: #fff;
`;

export const ViewInputs = styled.form`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  margin: 15px;
  width: 100%;
`;

export const Input = styled(TextField)`
  width: 100%;
  /* height: 30px; */
  // height: 40px;
  // margin: 5px 0px;
  // padding: 10px;
  // height: 35px;
  // background-color: #e8e8e8;
  // border: none;
  // border-radius: 5px;
`;

export const ViewInputCupom = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

export const InputCupom = styled.input`
  width: 65%;
  margin: 0 5px;
  height: 30px;
  /* background-color: #e8e8e8; */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 15px;
  font-size: 16px;
`;

export const BotaoAplicarCupom = styled.button`
  width: 20%;
  height: 35px;
  border-radius: 5px;
  border: none;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextoAplicarCupom = styled.p`
  font-size: 16px;
  color: #fff;
`;

export const TituloInput = styled.p``;

export const ViewModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  /* padding: 10px; */
`;

export const AlinharInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 7px;
  margin-top: 15px;
`;

export const AlinharInput2 = styled.div`
  box-sizing: border-box;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const BotaoPagarCartao = styled.div`
  width: 100%;
  height: 50px;
  background-color: red;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  border: none;
  font-size: 14px;
  color: #fff;
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: -15px;
  margin-left: auto;
  margin-right: auto;
`;

export const TextoBotaoPagarCartao = styled.p`
  font-size: 16px;
  color: #fff;
  padding-top: 5px;
`;

export const ViewSucesso = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BotaoVoltar = styled.div`
  width: 10%;
  height: 50px;
  position: absolute;
  cursor: pointer;
  right: 15px;
  bottom: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */

  &:hover {
    text-decoration: underline;
  }
`;

export const TextoVoltar = styled.span`
  color: #000;
  font-size: 20px;
`;

export const TextoSucesso = styled.span`
  color: green;
  font-size: 18px;
`;

export const TituloPIX = styled.p`
  font-size: 18px;
  margin: 0;
  text-align: center;
  margin-bottom: 10px;
  user-select: none;
`;

export const TextoVerificarPix = styled.span`
  font-size: 15px;
  white-space: nowrap;
  text-align: center;
  user-select: none;
  color: #fff;
`;

export const ViewPix = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  -webkit-box-shadow: -1px 10px 29px 4px rgba(0, 0, 0, 0.53);
  box-shadow: -1px 10px 29px 4px rgba(0, 0, 0, 0.53);
`;

export const BotaoVerificarPagamento = styled.div`
  background-color: red;
  margin: 0px 10px;
  padding: 0 10px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const DescricaoContainer = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
`;

export const DescricaoText = styled.p`
  color: #000;
  font-size: 13px;
  user-select: none;
`;

export const AlinharPassaportes = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -10px;
`;

export const ViewPassaporte = styled.div`
  width: 129px;
  height: 127.471px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ViewFotoPassaporte = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 5px;
`;

export const ImagemPassaporte = styled.img`
  width: 100%;
  height: 100%;
`;

export const EventData = styled.span`
  position: absolute;
  bottom: 0px;
  color: #fff;
  font-size: 14px;
  z-index: 300;
  line-height: 20px;
`;

export const TextoPassaporte = styled.span`
  color: #000;
  font-size: 14px;
  line-height: 20px;
  width: 127px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TextModal = styled.p`
  color: #000;
  font-size: 14px;
  margin-top: 20px;
  text-align: justify;
`;

export const Titulo = styled.p`
  color: blue;
  font-size: 14px;
`;

export const TextTempoCancel = styled.p`
  color: blue;
  font-size: 14px;
`;

export const TextVermelho = styled.span`
  color: red;
  font-size: 13px;
`;

export const TextoAceitarTermos = styled.span`
  font-size: 11px;
  line-height: 20px;
`;

export const ViewTermos = styled.div`
  overflow-y: scroll;
  height: 80vh;
`;

export const ViewAceitarTermos = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  /* padding: 0 10px; */
`;

export const BotaoCopiarPIX = styled.button`
  width: 230px;
  height: 50px;
  border-radius: 5px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border: none;
`;

export const TextoCopiarPIX = styled.span`
  font-size: 1.2em;
  user-select: none;
  color: #000;
  font-weight: bold;
`;

export const ViewCancelamento = styled.div`
  max-width: 300px;
  text-align: center;
  line-height: 25px;
  margin-top: 15px;
  user-select: none;
`;

export const TempoDeCancel = styled.span`
  font-size: 1em;
  user-select: none;
`;

export const Botoes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
`;

export const Card = styled.div`
  width: 100px;
  height: 55px;
  margin-top: 10px;
  background-color: #e8e8e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0px;
  margin-right: 10px;
  border: ${({ ativo }) => (ativo ? 1 : 0)}px solid #000;
  cursor: pointer;

  display: inline-block;
  box-sizing: border-box;
`;

export const NomeParcela = styled.span`
  color: #000;
  font-size: 12px;
`;

export const ValorParcela = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: bold;
`;

export const DivInter = styled.div`
  width: 90%;

  overflow: auto;
`;
