import { Select } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* margin-top: 70px; */
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
`;

export const SelectInput = styled(Select)`
  width: 50%;
  max-width: 400px;
  display: block;
  height: 50px;
  border: 1px solid #737373;
  margin: 10px 0px;
  /* padding-left: 20px; */
  border-radius: 5px;
  font-size: 16px;
`;

export const Input = styled.input`
  width: 50%;
  /* min-width: 376px; */
  display: block;
  height: 50px;
  border: none;
  border: 1px solid #444;
  margin: 10px 5px;
  padding-left: 20px;
  border-radius: 5px;
  font-size: 16px;
`;

export const InputData = styled.input`
  width: 50%;
  display: block;
  max-height: 50px;
  border: none;
  border: 1px solid #444;
  background-color: #fff;
  margin: 10px 0px;
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 16px;
  display: flex;
`;

export const Botao = styled.input`
  width: 70%;
  max-width: 400px;
  height: 50px;
  background-color: ${({ disabled, corFundo }) => disabled ? 'gray' : corFundo};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  color: #fff;
`;

export const LogoCenterIngressos = styled.img`
  width: 180px;
  height: 120px;
  margin-top: -100px;
  margin-bottom: 10px;
`;

export const BotaoCadastrar = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 10px;
`;

export const CadastraseTexto = styled.span`

`;

export const TextoAzul = styled.span`
  color: blue;
`;

export const ViewInputs = styled.div`
  display: flex;
  width: 90%;
  max-width: 400px;
  justify-content: space-between;
`;