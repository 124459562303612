/* Slideshow */

import styled from "styled-components";

import { motion } from 'framer-motion';


export const Container = styled.div`
	position: relative;
	width: 100%;
  /* height: 66.6%; */
	// height: 330px; // hide the scroll bar
	/* margin: 1em auto; */
	height: 100px; // hide the scroll bar
  overflow: hidden;
  margin-top: 5px;

  // @media screen and (min-width: 1024px) {
  //   height: 340px;
  // }
`;

export const ContainerEvento = styled.div`
	height: 300px; // hide the scroll bar
	box-sizing: border-box;
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
  margin-left: 45px;
  margin-right: 45px;
  
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemEvento = styled.div`
	display: inline-block;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

export const SetaEsquerda = styled.div`
  position: absolute;
  left: 0px;
  width: 45px;
  height: 100%;
  z-index: 1;
  background-color: #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const SetaDireita = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 45px;
  height: 100%;
  z-index: 1;
  background-color: #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const Imagem = styled.img`
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: 5px;
`;

export const Info = styled.p`
  color: #FFF;
  font-size: 13px;
  position: absolute;
  bottom: 10px;
  z-index: 35;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;  
  position: absolute;
  top: 0;
  // left: 0;
  z-index: 30;
  border-radius: 5px;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));

  // @media screen and (max-width: 570px) {
  //   width: 100%;
  // }
`;

export const ImagemEvento = styled.img`
  width: 100%;
  height: 90px;
  object-fit: cover;
  border-radius: 5px;
  // border-bottom-left-radius: 5px;
  // border-bottom-right-radius: 5px;

  // @media screen and (min-width: 1024px) {
  //   height: 300px;
  // }
`;

export const ViewPropaganda = styled.div`
  width: 100%;
`;