import React from 'react';

import './styles.css';

export default function Responsivel({ children }) {
    return (
        <div className="container">
            {children}
        </div>
    )
}