/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import {
  BotaoCelular,
  Celular,
  Cidade,
  Container,
  ImagemPropaganda,
  Info,
  NomePropaganda,
  Obs,
  Overlay,
  Section,
  TipoServico,
  ViewBanner,
} from "./styles";

import { WhatsApp } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { BannerTotal } from "../Evento/styles";

import secureLocalStorage from "react-secure-storage";
import YouTube from "react-youtube";
import BotaoVoltar from "../../componentes/BotaoVoltar";
import Header from "../../componentes/Header";
import Responsivel from "../../componentes/Responsivel";
import Rodape from "../../componentes/Rodape";
import api from "../../servicos/api";

export default function Propaganda() {
  const { usuario, carregando } = useContext(AuthContext);

  const { id } = useParams();

  const navigate = useNavigate();

  const localEnd = secureLocalStorage.getItem("endereco");
  const id_usuario = secureLocalStorage.getItem("cliente");

  const end = JSON.parse(localEnd);

  const [dados, setDados] = useState([]);
  const [carregandoHome, setCarregandoHome] = useState(true);
  const [abrirImagemEvento, setAbrirImagemEvento] = useState(false);

  useEffect(() => {
    listar();
  }, []);

  async function listar() {
    if (!carregando) {
      setCarregandoHome(true);
    }

    await api
      .get(`propaganda/listar.php?id_cliente_publicidade=${id}`)
      .then((response) => {
        if (response.data.sucesso === true) {
          console.log(response.data.dados);
          setDados(response?.data?.dados);
        }

        setCarregandoHome(false);
      })
      .catch(console.log);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "55%",
  };

  function abrirWhatsapp() {
    const c = String(dados.celular).replace("(", "").replace(")", "").replace("-", "").replace(" ", "");

    window.open(`https://api.whatsapp.com/send?1=pt_BR&phone=55${c}`, "_blank", "noreferrer");
  }

  return (
    <Container>
      <Helmet>
        <title>{dados.nome}</title>
      </Helmet>

      <Header />

      <Responsivel>
        {dados.length <= 0 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <span style={{ fontSize: 20 }}>Essa propaganda não existe.</span>
          </div>
        ) : (
          <>
            <ViewBanner>
              <BotaoVoltar onClick={() => navigate("/")} />

              <ImagemPropaganda
                src={dados.foto}
                alt="Imagem do Evento"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dados.sem_foto;
                }}
              />

              <TipoServico>{dados.tipo_servico}</TipoServico>
              <Cidade>
                {dados?.cidade?.nome} - {dados?.cidade?.sigla_uf}
              </Cidade>
              <NomePropaganda>{dados.nome}</NomePropaganda>

              <Overlay onClick={() => setAbrirImagemEvento(true)} />
            </ViewBanner>

            <Section>
              {dados?.celular !== undefined && (
                <BotaoCelular onClick={() => abrirWhatsapp()}>
                  <WhatsApp color="success" />

                  <Celular>
                    {" "}
                    {dados.celular
                      .replace(/\D/g, "")
                      .replace(/^(\d{2})(\d)/g, "($1) $2")
                      .replace(/(\d{5})(\d{2})/, "$1-$2")}
                  </Celular>
                </BotaoCelular>
              )}

              {dados?.codigo_video && (
                <YouTube videoId={dados.codigo_video} style={{ width: "80%", marginTop: 40 }} opts={{ width: "100%", height: 400 }} />
              )}

              {dados.obs && (
                <Info>
                  <Obs>Obs: {dados.obs}</Obs>
                </Info>
              )}
            </Section>
          </>
        )}

        <Modal open={abrirImagemEvento} onClose={() => setAbrirImagemEvento(false)}>
          <Box sx={{ ...style }}>
            <BannerTotal
              src={dados.foto}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = dados.sem_foto;
              }}
            />
          </Box>
        </Modal>
      </Responsivel>

      <Rodape />
    </Container>
  );
}
