/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";

import jwt from "jwt-decode";
import secureLocalStorage from "react-secure-storage";
import api from "../servicos/api";

export const AuthContext = createContext();

const KEY_VALUE = "cliente";

function AuthProvider({ children }) {
  const [carregando, setCarregando] = useState(true);
  const [usuario, setUsuario] = useState({});
  const [endereco, setEndereco] = useState({});
  const [informacoesApp, setInformacoesApp] = useState({});

  if (window.location.host.startsWith("www")) {
    window.location = (window.location.protocol + "//" + window.location.host + window.location.pathname).replace("www.", "");
  }

  useEffect(() => {
    listarInformacoesAplicativo()
      .then(() => {
        carregarInformacoes();
      })
      .catch(console.debug);
  }, []);

  async function listarInformacoesAplicativo() {
    return new Promise(async (resolve, reject) => {
      api
        .post("app/listar.php")
        .then(async (response) => {
          if (response.data.sucesso === true) {
            setInformacoesApp(response.data.resultado);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setTimeout(() => {
            setCarregando(false);
          }, 1000);
        });
    });
  }

  async function carregarInformacoes() {
    const endereco = secureLocalStorage.getItem("endereco");
    const id = secureLocalStorage.getItem(KEY_VALUE);
    const credenciais = secureLocalStorage.getItem("credenciais");

    const end = JSON.parse(endereco);

    if (!end) {
      pegarLocalizacaoUsuario();
    }

    setEndereco(JSON.parse(endereco));

    if (id) {
      await api
        .post("login/pegar_info.php", { id })
        .then(async (response) => {
          if (response.data.success) {
            if (credenciais) {
              const decodificado = jwt(credenciais);
              const novo = Object.assign({}, response?.data?.resultado);

              novo.foto_pessoal = decodificado.picture;

              setUsuario(novo);
            } else {
              setUsuario(response?.data?.resultado);
            }
          } else {
            // navigate('/login');
          }
        })
        .catch(console.log);
    } else {
      // navigate('/login');
    }

    setCarregando(false);
  }

  async function entrar({ usuario, senha }) {
    if (!carregando) {
      setCarregando(true);
    }

    const obj = {
      usuario,
      senha,
    };

    var sucesso = false;

    await api
      .post("login/entrar.php", obj)
      .then(async (response) => {
        if (response.data.success === true) {
          setUsuario(response?.data?.resultado);

          const id = response?.data?.resultado?.id;

          secureLocalStorage.setItem(KEY_VALUE, id);
          // navigate('/');

          sucesso = true;
        } else {
          // navigate('/login');

          sucesso = false;
        }
      })
      .catch((error) => {
        console.debug(error);
        sucesso = false;
      });

    setCarregando(false);

    return sucesso;
  }

  async function entrarGoogle(credenciais) {
    if (!carregando) {
      setCarregando(true);
    }

    const decodificado = jwt(credenciais.credential);

    const obj = {
      email: decodificado?.email,
      nome: decodificado?.name,
      id_token: credenciais?.clientId,
    };

    await api
      .post("login/social_login.php", obj)
      .then(async (response) => {
        if (response.data.sucesso) {
          const novo = Object.assign({}, response?.data?.resultado);
          novo.foto_pessoal = decodificado.picture;
          setUsuario(novo);

          const id = response?.data?.resultado?.id;

          secureLocalStorage.setItem(KEY_VALUE, id);
          secureLocalStorage.setItem("credenciais", credenciais.credential);

          // navigate('/');
        } else {
          // navigate('/login');
        }
      })
      .catch((error) => {
        console.debug(error);
      });

    setCarregando(false);
  }

  async function mudarEndereco(endereco) {
    setEndereco(endereco);
    secureLocalStorage.setItem("endereco", JSON.stringify(endereco));
  }

  async function sair() {
    setUsuario({});
    secureLocalStorage.clear();
  }

  async function pegarEnderecoPorLatELong(latitude, longitude) {
    await api
      .get(`https://nominatim.openstreetmap.org/search.php?format=json&addressdetails=1&limit=1&q=${latitude},${longitude}`)
      .then(async (response) => {
        const cidade = response.data[0].address.town
          ? response.data[0].address.town
          : response.data[0].address.city
          ? response.data[0].address.city
          : response.data[0].address.county
          ? response.data[0].address.county
          : response.data[0].address.village
          ? response.data[0].address.village
          : response.data[0]?.address.municipality && response.data[0]?.address.municipality;

        await api
          .get(`cidades/pegar_uma_cidade.php?cidade=${cidade}&estado=${response.data[0]?.address.state}`)
          .then((res) => {
            const endereco = {
              id: res.data.cidades.id,
              nome: res.data.cidades.nome,
              nome_uf: res.data.cidades.nome_uf,
              sigla_uf: res.data.cidades.sigla_uf,
              latitude,
              longitude,
            };

            mudarEndereco(endereco);
          })
          .catch((error) => {
            console.debug(error);
          });
      })
      .catch((error) => {
        console.debug(error);
      });
  }

  function pegarLocalizacaoUsuario() {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        const latitude = location.coords.latitude;
        const longitude = location.coords.longitude;

        pegarEnderecoPorLatELong(latitude, longitude);
      },
      (location) => {
        const enderec = {
          id: "4236",
          latitude: -23.0410171,
          longitude: -51.814721,
          nome: "Santa Fé",
          nome_uf: "Paraná",
          sigla_uf: "PR",
        };

        mudarEndereco(enderec);
      }
    );
  }

  return (
    <AuthContext.Provider
      value={{
        usuario,
        endereco,
        carregando,
        informacoesApp,
        entrar,
        entrarGoogle,
        sair,
        mudarEndereco,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
