import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 70px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  overflow: hidden;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 0px 15px 18px -1px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #000000, 0px 15px 18px -1px rgba(0,0,0,0);
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: rgba(255, 255, 255,0.9);

`;

export const ViewInfo = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
`;

export const ViewLocalizacao = styled.div`
  position: absolute;
  left: 0px;
  display: flex;
  align-items: center;
  padding-right: 5px;
`;

export const ViewMenu = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  padding-right: 5px;
`;

export const ViewCidade = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BotaoIconeLoc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  padding: 5px;
  border-radius: 10px;
  margin: 0 10px;
`;

export const Usuario = styled.span`
  text-align: start;
  margin-left: 10px;
  font-size: 0.9em;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  overflow: hidden;
`;

export const TituloCidade = styled.span`
  text-align: end;
  margin-right: 20px;
  font-size: 0.7em;
  color: gray;

`;

export const NomeCidade = styled.span`
  text-align: end;
  margin-right: 20px;
  font-size: 0.7em;
  color: #444;
  margin-top: -5px;
`;

export const NomeEstado = styled.span`
  text-align: end;
  margin-right: 20px;
  color: #000;
`;

export const ViewInfoLocation = styled.div`
  display: flex;
`;

export const ViewInputBusca = styled.div`
  width: 100%;
  margin-top: 40px;


  @media screen and (min-width: 1024px) {
    max-width: 700px;
  }

`;

export const ViewCidades = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  background-color: #e8e8e8;
  align-items: center;
  padding-left: 10px;
  box-sizing: border-box;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background-color: #cccccc;
  }
`;

export const InputBusca = styled.input`
  width: 100%;
  height: 50px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  font-size: 16px;

  :focus {
    outline: none !important;
    border: 1px solid #444;
  }

  ::-webkit-search-cancel-button{
    -webkit-appearance: none;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50em;
    background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
    position: relative;
    right: 10px;
  }

  :focus::-webkit-search-cancel-button {
    opacity: .3;
    pointer-events: all;
  }
`;

export const ViewTodasCidades = styled.div`
  overflow: auto;
  height: calc(100% - 55px);
  width: 100%;
  max-width: 700px;

  // display: flex;
  // align-items: center;
  // flex-direction: column;
  // // justify-content: center;
  
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const FotoUsuario = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 35px;
`;
