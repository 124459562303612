import React from "react";

import { useNavigate } from "react-router-dom";

import { url_site } from "../../config";
import { Container, Imagem, Info, Overlay, ViewEmpresa } from "./styles";

function ListaEmpresas({ dados }) {
  const navigate = useNavigate();

  return (
    <Container>
      <div style={{ padding: "5px 10px" }}>
        {dados.map((item, index) => (
          <ViewEmpresa key={item.id} onClick={() => navigate(`empresa/${item.id}`)} style={{}}>
            <Imagem
              src={
                item.banner_empresa === null
                  ? url_site + "/sistema/img/banner_empresa/sem-foto10.jpg"
                  : url_site + `/sistema/img/banner_empresa/${item.banner_empresa}`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = url_site + "/sistema/img/banner_empresa/sem-foto10.jpg";
              }}
            />

            <Info>{item.nome}</Info>

            <Overlay />
          </ViewEmpresa>
        ))}
      </div>
    </Container>
  );
}

export default ListaEmpresas;
