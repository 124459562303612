export const url_site = "https://centeringressos.com.br";
// export const url_site = "https://centertickets.com.br";
// export const url_site = "https://myloungebar.com.br";

// URL_API
export const url_api = "https://sitecenteringressos.com.br/clientes";
// export const url_api = "https://centertickets.com.br/clientes";
// export const url_api = "http://192.168.2.114/clientes";
// export const url_api = "https://myloungebar.com.br/clientes";

// diferenciar cada empresa WHITE LABEL
export const tipo_empresa = 1; // 1 center ingressos / 2 center tickets / 3 my mylounge

// nome do site
export const nome_site = () => {
  if (tipo_empresa === 1) {
    return "Center Ingressos";
  } else if (tipo_empresa === 2) {
    return "Center Tickets";
  } else if (tipo_empresa === 3) {
    return "My Lounge";
  }
};

const api_key_google_centeringressos = "822983055998-hb62iergq2gn9tseig1164pudr72fc5m.apps.googleusercontent.com";
const api_key_google_centertickets = "822983055998-6275e59nqsj50qdm2j9g1ga44qmqg3ts.apps.googleusercontent.com";
const api_key_google_mylounge = "822983055998-0q2beqntnvdj0pek6lkvs586011ijfur.apps.googleusercontent.com";

export const api_key_google = () => {
  if (tipo_empresa === 1) {
    return api_key_google_centeringressos;
  } else if (tipo_empresa === 2) {
    return api_key_google_centertickets;
  } else if (tipo_empresa === 3) {
    return api_key_google_mylounge;
  }
};

export const logo_app_caminho = (url_site, logo) => {
  return `${url_site}/sistema/img/${logo}`;
};
