/* Slideshow */

import styled from "styled-components";

import { motion } from 'framer-motion';


export const Container = styled.div`
	position: relative;
	max-width: 100vw;
  /* height: 66.6%; */
	/* height: 140px; // hide the scroll bar */
	height: 150px; // hide the scroll bar
	/* margin: 1em auto; */
  overflow: hidden;
`;

export const ContainerEvento = styled.div`
	height: 100%;
	box-sizing: border-box;
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
  
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ItemEvento = styled.div`
	display: inline-block;
  width: 48%;
  margin: 0 10px;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-bottom: 23px;
`;

export const SetaEsquerda = styled.div`
  position: absolute;
  left: 0px;
  width: 45px;
  height: 100%;
  z-index: 1;
  background-color: #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const SetaDireita = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 45px;
  height: 100%;
  z-index: 1;
  background-color: #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const Imagem = styled.img`
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: 5px;
  object-fit: cover;

`;

export const Info = styled.p`
  color: #FFF;
  font-size: 16px;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  z-index: 35;
  text-align: center;
`;

export const NomeEvento = styled.p`
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  font-size: 0.8em;
  position: absolute;
  bottom: -17px;
  left: 0px;
  z-index: 500;
  text-align: center;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 85%;  
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 5px;
  z-index: 30;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
`;