import styled from "styled-components";

const Pago = {
  'Sim': '#137705',
  'Não': '#ff002f',
};

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  /* margin-top: 70px; */
`;

export const ViewCompras = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Item = styled.div`
  width: 90%;
  max-width: 400px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 5px 0px;
  align-self: center;
  cursor: pointer;
  /* padding: 0px 15px; */
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  -webkit-box-shadow: 8px 6px 15px -7px rgba(0,0,0,0.88); 
  box-shadow: 8px 6px 15px -7px rgba(0,0,0,0.88);
`;

export const ViewStatus = styled.div`
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0px;
  background-color: ${({ pago, status }) => status === 'Cancelado' ? '#FFC107' : Pago[pago]};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const ViewRodapeIngresso = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: gray;
  margin-top: 7px;
`;

export const IDCompra = styled.span`
  font-size: 0.8em;
`;

export const NomeIngresso = styled.span`
  font-size: 0.8em;
`;

export const ViewInformacoes = styled.div`
  width: 17%;
  background-color: #f5f5f5;
  border-radius: 5px;
  max-width: 70px;
`;

export const ViewInfoInterna = styled.div`
  width: 83%;
  padding: 5px;
`;

export const ViewInfo = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ViewModal = styled.div`

`;

export const ViewModalQrcode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ViewValores = styled.div`
  margin-top: 15px;
`;

export const Valores = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ViewInfo1 = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ViewInfo2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ViewInfo3 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const InfoNomeEvento = styled.span`
  width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;              
`;

export const InfoDataEvento = styled.span`

`;

export const InfoHoraEvento = styled.span`

`;

export const InfoDataCompra = styled.span`

`;

export const InfoHoraCompra = styled.span`

`;

export const InfoTipoIngresso = styled.span`

`;

export const InfoStatus = styled.span`

`;

export const InfoAviso = styled.span`

`;

export const ImportanteView = styled.div`
  margin-top: 5px;
  border: 1px solid #d8d8d8;
  padding: 10px;
  border-radius: 5px;
  line-height: 10px;
  align-self: center;
`;

export const InfoImportante = styled.p`
  color: #000;
  font-size: 10px;
  margin: 0px;
`;

export const Titulo = styled.span`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 17px;
`;

export const NomeEvento = styled.span`
  font-size: 19px;
  font-weight: bold;
`;

export const ViewInformacoesInterna = styled.div`
  padding: 0 10px;
  margin-top: 40px;
`;

export const ViewQRCodeInfo2 = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 15px;
`;

export const LinhaSerrilhado = styled.div`
  width: 95%;
  height: 13px;
  border-bottom: 2px dashed #000;
`;

export const Middle = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CircleLeft = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  top: 0px;
  left: -25px;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const CircleRight = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  top: 0px;
  right: -25px;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const ViewQRCodeInfo1 = styled.div`
  display: flex;
  margin-top: 5px;
`;

export const ViewIDCliente = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ViewIDIngresso = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const ViewDataEvento = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViewHoraEvento = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViewInfos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ViewInfoHora = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ViewInfos2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

export const ViewBotoesQRCode = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const BotaoQRCode = styled.div`
  width: 47%;
  height: 50px;
  /* background-color: red; */
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
  font-size: 17px;
`;

export const ViewInternoQRCode = styled.div`
  padding: 0 20px;
`;

export const TextoBotaoQR = styled.span`
  font-size: 0.9em;
`;

export const BotaoTransferir = styled.div`
  position: fixed;
  bottom: 15px;
  left: 5px;
  width: 130px;
  height: 40px;
  /* background-color: rgba(245, 39, 39, 0.9); */
  border-radius: 50px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 1000;
`;

export const TextoTransferir = styled.span`
  color: #fff;
  /* font-weight: bold; */
  font-size: 0.9em;
`;

export const ContainerPDF = styled.div`
  border-width: 2px;
  border-color: rgb(143, 143, 143);
  border-style: solid;
`;

export const Cabecalho = styled.div`
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: row;
  align-self: center;
  border-width: 1px;
  border-color: rgb(143, 143, 143);
  border-style: solid;
`;

export const Info = styled.div`
  border-width: 1px;
  border-color: rgb(143, 143, 143);
  border-style: solid;
  padding-left: 10px;
  font-weight: bold;
`;

export const InfoLugar = styled.span`

`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  height: 23px;
`;

export const Importante = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`;

export const Qrcode = styled.div`
  padding: 10px;
  border-left-width: 2px;
  border-left-color: rgb(143, 143, 143);
  border-left-style: solid;
  display: flex;
  justify-content: center;
`;

export const Codigo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left-width: 2px;
  border-left-color: rgb(143, 143, 143);
  border-left-style: solid;
`;

export const Logo = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleModalInfo = styled.p`
  color: #547DE5;
  font-size: 15px;
  margin-top: 0px;
  text-align: center;
`;

export const TextoPix = styled.p`
  color: #000;
  font-size: 12px;
  margin-top: 0px;
  max-width: 100%;
  text-align: center;
  line-height: 20px;
  overflow-wrap: break-word;
`;

export const PagoCorView = styled.div`
  width: 100%;
  height: 20px;
  margin-top: 10px;
  align-self: center;
  margin-bottom: 10px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExemploView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;

export const CorExemplo = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 10px;
  margin-right: 5px;
`;

export const TextoExemplo = styled.span`
  color: #000;
  font-size: 11px;
`;

export const ViewInfoUsuario = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NomeUsuario = styled.span`
  font-size: 0.9em;
  width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
`;

export const CPFUsuario = styled.span`
  font-size: 0.9em;
`;
