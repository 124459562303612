/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import { BotaoCadastro, Container, ImagemIconeTicket, SubTextoSemEventos, TextoCadastro, TextoSemEventos, Titulo, VazioTexto, ViewCarregando, ViewListas, ViewSemEventos, ViewVazio } from "./styles";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth";

import Lottie from "lottie-react";
import secureLocalStorage from "react-secure-storage";
import Radar from "../../animacoes/radar.json";
import Header from "../../componentes/Header";
import ListaCategorias from "../../componentes/ListaCategorias";
import ListaCidades from "../../componentes/ListaCidades";
import ListaEmpresas from "../../componentes/ListaEmpresas";
import ListaEventos from "../../componentes/ListaEventos";
import ListaPropagandas from "../../componentes/ListaPropagandas";
import Responsivel from "../../componentes/Responsivel";
import Rodape from "../../componentes/Rodape";
import iconeTicket from "../../imagens/ingresso.jpg";
import api from "../../servicos/api";

export default function Home() {
  const { usuario, carregando, informacoesApp } = useContext(AuthContext);

  const navigate = useNavigate();
  const localEnd = secureLocalStorage.getItem("endereco");
  const end = JSON.parse(localEnd);
  const id_usuario = secureLocalStorage.getItem("cliente");

  const [eventos, setEventos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [propagandas, setPropagandas] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("1");
  const [cidadeSelecionada, setCidadeSelecionada] = useState(end?.id ?? 4236);
  const [carregandoHome, setCarregandoHome] = useState(true);

  useEffect(() => {
    listar();
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [categoriaSelecionada, cidadeSelecionada, carregando]);

  async function listar() {
    await api
      .get(`home/listar.php?cliente=${id_usuario}&cidade=${cidadeSelecionada}&pais=BR&categoria=${categoriaSelecionada}`)
      .then((response) => {
        setEventos(response?.data?.eventos_da_regiao);
        setEmpresas(response?.data?.dados);
        setCategorias(response?.data?.categorias);
      })
      .catch(console.log)
      .finally(() => {
        buscarCidadesPerto();
      });
  }

  async function buscarCidadesPerto() {
    const estado = end?.nome_uf ?? "Paraná";
    const latitude = end?.latitude ?? -23.0410171;
    const longitude = end?.longitude ?? -51.814721;

    await api
      .get(`cidades/listar_cidades_perto.php?estado=${estado}&latitude=${latitude}&longitude=${longitude}`)
      .then(async (response) => {
        if (response.data.sucesso === true) {
          setCidades(response.data.dados);
          setPropagandas(response.data.propagandas.banners);
        }
      })
      .catch((error) => {
        console.debug(error);
      })
      .finally(() => {
        setCarregandoHome(false);
      });
  }

  return (
    <Container>
      <Header />

      <Responsivel>
        {carregandoHome === true ? (
          <ViewCarregando>
            <Skeleton variant="rectangular" width="100%" height={286} />
            <Skeleton variant="rectangular" width="100%" height={70} sx={{ marginTop: 5 }} />
            <Skeleton variant="rectangular" width="100%" height={100} sx={{ marginTop: 5 }} />
            <Skeleton variant="rectangular" width="100%" height={150} sx={{ marginTop: 5 }} />
          </ViewCarregando>
        ) : (
          <>
            {eventos?.length > 0 ? (
              <ListaEventos dados={eventos} />
            ) : (
              <ViewSemEventos>
                <ImagemIconeTicket src={iconeTicket} alt="icone ticket" />
                <TextoSemEventos>Sem eventos encontrados</TextoSemEventos>
                <SubTextoSemEventos>
                  Não existem eventos agendados no momento. <br></br> Tente novamente mais tarde.
                </SubTextoSemEventos>
              </ViewSemEventos>
            )}

            {categorias?.length > 0 && <ListaCategorias dados={categorias} aoClicar={setCategoriaSelecionada} categoria={categoriaSelecionada} />}

            {propagandas?.length > 0 && <ListaPropagandas dados={propagandas} />}

            {cidades?.length > 0 && (
              <ViewListas style={{ zIndex: 3 }}>
                <Titulo>Cidades da Região</Titulo>
                <ListaCidades dados={cidades} aoClicar={(c) => setCidadeSelecionada(c)} cidade={cidadeSelecionada} />
              </ViewListas>
            )}

            {/* <Lottie animationData={Separacao} loop={true} style={{ height: 130, marginTop: -30, zIndex: 2 }} /> */}

            {empresas?.length > 0 ? (
              <ViewListas style={{ marginTop: 10 }}>
                <Titulo>Eventos e Casa de Shows</Titulo>
                <ListaEmpresas dados={empresas} />
              </ViewListas>
            ) : (
              <ViewVazio>
                <Lottie animationData={Radar} loop={true} style={{ height: 150 }} />
                <VazioTexto>Nenhuma empresa cadastrada, ainda...</VazioTexto>
              </ViewVazio>
            )}
          </>
        )}

        {!usuario.id && !carregandoHome && (
          <BotaoCadastro style={{ backgroundColor: informacoesApp.cor_primaria }} onClick={() => navigate("/login")}>
            <TextoCadastro>Entrar</TextoCadastro>
          </BotaoCadastro>
        )}
      </Responsivel>

      <Rodape />
    </Container>
  );
}
