/* Slideshow */

import styled from "styled-components";

export const Container = styled.div`
	position: relative;
  width: 100%;
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
`;

export const ContainerEmpresa = styled.div`
  /* overflow-y: scroll; */
  width: 98%;
  margin-bottom: 30px;
  display: flex;
  padding: 5px 10px;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SetaEsquerda = styled.div`
  position: absolute;
  left: 0px;
  width: 45px;
  height: 100%;
  z-index: 1;
  background-color: #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const SetaDireita = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 45px;
  height: 100%;
  z-index: 1;
  background-color: #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

export const Imagem = styled.img`
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: 5px;
  object-fit: cover;
`;

export const Info = styled.p`
  color: #FFF;
  font-size: 14px;
  position: absolute;
  left: 10px;
  bottom: -10px;
  z-index: 35;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;  
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 10px;
  z-index: 30;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
`;

export const ViewEmpresa = styled.div`
  width: 100%;
  height: 130px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  margin-bottom: 15px;
`;