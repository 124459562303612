import React from 'react';

import {
  Container,
  Info,
  ContainerCategoria,
  ViewCategoria,
} from './styles';

function ListaCategorias({ dados, aoClicar, categoria }) {
  return (
    <Container>
      <ContainerCategoria>

        {dados.map((item, index) => (
          <ViewCategoria
            key={item.id}
            // style={{ marginLeft: 10 }}
            onClick={() => aoClicar(item.id)}
          >
            <Info style={{ color: categoria === item.id ? 'red' : '' }}>{item.nome}</Info>
          </ViewCategoria>
        ))}
      </ContainerCategoria>
    </Container>
  );
}

export default ListaCategorias;