import styled from "styled-components";

export const Container = styled.div`
  margin: 0;
  /* overflow: hidden; */
  width: 100%;
  /* position: relative; */
  /* margin-top: 70px; */
`;

export const ViewBanner = styled.div`
  width: 100%;
  height: 240px;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 1024px) {
    height: 300px;
    margin-top: 15px;
    border-radius: 5px;
  }
`;

export const Banner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;  
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
`;

export const Info1 = styled.span`
  position: absolute;
  left: 10px;
  bottom: 52px;
  z-index: 100;
  color: #999999;
  font-size: 1em;
`;

export const Info2 = styled.span`
  position: absolute;
  left: 10px;
  bottom: 23px;
  z-index: 100;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
`;

export const Info3 = styled.span`
  position: absolute;
  left: 10px;
  bottom: 5px;
  z-index: 100;
  color: #999999;
  font-size: 0.8em;
`;

export const Info4 = styled.span`
  position: absolute;
  right: 10px;
  bottom: 5px;
  z-index: 100;
  color: #999999;
  font-size: 0.8em;
`;

export const BotaoVoltar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 40px;
  background-color: rgba(255, 255, 255, .2);
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 100;
  border-radius: 5px;
`;

export const TextoVoltar = styled.span`
  color: #fff;
  font-size: 1em;
`;

export const Titulo = styled.span`
  color: #000;
  font-size: 0.9em;
  margin-left: 10px;
`;

export const Rodape = styled.footer`
  width: 100%;
  height: 300px;
  background-color: #1F1F29;
  margin-top: 30px;
`;

export const ViewEventos = styled.div`
  overflow-y: scroll;
  padding-bottom: 100px;

  position: relative;
  top: -5px;
  z-index: 200;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @media screen and (min-width: 1024px) {
    top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

export const ViewSemEvento = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

export const TextoSemEvento = styled.span`

`;

export const ViewInformacoesEmpresa = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

export const Informacao = styled.div`
  margin: 0 7px;
  background-color: #F8F8F8;
  /* background-color: #999; */
  border-radius: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
`;

export const TextoInformacao = styled.span`
  font-size: 12px;
  color: #000;
  padding-left: 7px;
`;

export const TextoModal = styled.span`
  color: #999;
`;

export const ViewPagamentos = styled.span`
  display: flex;
  gap: 10px;
`;
