import { Routes, Route } from 'react-router-dom';

import Home from '../paginas/Home';
import Login from '../paginas/Login';
import Evento from '../paginas/Evento';
import NotFound from '../paginas/NotFound';
import Compra from '../paginas/Compra';
import Empresa from '../paginas/Empresa';
import Ingressos from '../paginas/Ingressos';
import EscolherLugar from '../paginas/EscolherLugar';
import AdicionarCompra from '../paginas/AdicionarCompra';
import Cadastrar from '../paginas/Cadastrar';
import Usuario from '../paginas/Usuario';
import Propaganda from '../paginas/Propaganda';

export default function RoutesIndex() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/login" index element={<Login />} />
      <Route path="/cadastrar" element={<Cadastrar />} />
      <Route path="/ingressos" element={<Ingressos />} />

      <Route path="/evento/:id" element={<Evento />}>
        <Route path=":idpdv" element={<Evento />} />
      </Route>

      <Route path="/empresa/:id" element={<Empresa />} />

      <Route path="/adicionarcompra/:evento/:produto" element={<AdicionarCompra />}>
        <Route path=":idpdv" element={<AdicionarCompra />} />
      </Route>

      <Route path="/lugar/:evento/:produto" element={<EscolherLugar />}>
        <Route path=":idpdv" element={<EscolherLugar />} />
      </Route>

      <Route path="/compra/:evento/:produto" element={<Compra />}>
        <Route path=":idpdv" element={<Compra />} />
      </Route>

      <Route path="/propaganda/:id" element={<Propaganda />} />
      <Route path="/usuario" element={<Usuario />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}