import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  /* margin-top: 70px; */
  
`;

export const ImagemPropaganda = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ViewBanner = styled.div`
  width: 100%;
  height: 240px;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 1024px) {
    height: 300px;
    margin-top: 15px;
    border-radius: 5px;
  }
`;

export const TipoServico = styled.span`
  color: #999;
  font-size: 16px;
  position: absolute;
  left: 10px;
  bottom: 60px;
  z-index: 100;
`;

export const Cidade = styled.span`
  color: #999;
  font-size: 12px;
  letter-spacing: 0.2px;
  position: absolute;
  left: 10px;
  bottom: 40px;
  z-index: 100;
`;

export const NomePropaganda = styled.span`
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
  z-index: 100;
  position: absolute;
  left: 10px;
  bottom: 10px;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;  
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
`;

export const VideoView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;


export const Celular = styled.span`
  color: #000;
  font-size: 14px;
  margin-left: 5px;
`;

export const BotaoCelular = styled.div`
  width: 160px;
  height: 40px;
  background-color: #F8F8F8;
  border-radius: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;

export const Info = styled.div`
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  word-break:break-all;
`;

export const Obs = styled.p`
  color: #000;
  font-size: 14px;
  line-height: 1.9;
  text-align: center;
`;