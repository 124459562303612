import React from "react";

import { Container, Texto } from "./styles";

export default function NotFound() {
  return (
    <Container>
      <Texto>Página não encotrada.</Texto>
    </Container>
  )
}