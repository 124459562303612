import styled from "styled-components";

export const Container = styled.div`
  margin: 0;
  /* overflow: hidden; */
  width: 100%;
  /* position: relative; */
  /* margin-top: 70px; */
`;

export const ViewBanner = styled.div`
  width: 100%;
  height: 240px;
  overflow: hidden;
  position: relative;
  // z-index: -1;

  @media screen and (min-width: 1024px) {
    height: 300px;
    margin-top: 15px;
    border-radius: 5px;
  }
`;

export const Banner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BannerTotal = styled.img`
  width: 90%;
  height: 90%;
  object-fit: contain;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;  
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
`;

export const Info1 = styled.span`
  position: absolute;
  left: 10px;
  bottom: 45px;
  z-index: 100;
  color: #999999;
  font-size: 1em;
`;

export const Info2 = styled.span`
  position: absolute;
  left: 10px;
  bottom: 23px;
  z-index: 100;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
`;

export const Info3 = styled.span`
  position: absolute;
  left: 10px;
  bottom: 5px;
  z-index: 100;
  color: #999999;
  font-size: 0.8em;
`;

export const Info4 = styled.span`
  position: absolute;
  right: 10px;
  bottom: 5px;
  z-index: 100;
  color: #999999;
  font-size: 0.8em;
`;

export const BotaoVoltar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 40px;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 1000;
  border-radius: 5px;
`;

export const TextoVoltar = styled.span`
  color: #fff;
  font-size: 1em;
`;

export const TituloEscolha = styled.span`
  color: #000;
  font-size: 0.9em;
  margin-top: 5px;
`;

export const ViewContainerIngresso = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  padding-bottom: 100px;

  position: relative;
  top: -5px;
  z-index: 200;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @media screen and (min-width: 1024px) {
    top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

export const TituloModal = styled.p`
  text-align: center;
  font-size: 20px;
`;

export const Outros = styled.div`
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 30px;
  box-sizing: border-box;
  /* padding-bottom: 20px; */
  margin-top: 30px;
  background: linear-gradient(#f4f4f4, #fafafa, #ffffff);
`;

export const ViewInfoOutros = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const TextoInfoOutros = styled.p`
  font-size: 14px;
  margin-left: 5px;
  color: #5e5e5e;
`;

export const InfoOutros = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
`;

export const TituloDescricao = styled.p`
  font-size: 16px;
  margin-top: 20px;
`;

export const ViewBotoesOutros = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
`;

export const BotoesOutros = styled.div`
  width: 50%;
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  cursor: pointer;

  :hover {
    background-color: #eee;
  }
`;

export const Descricao = styled.p`
  margin-top: 0px;
  font-size: 14px;
  text-align: justify;
  color: #525252;
  width: 100%;
  white-space: pre-wrap;
`;

export const Descricao2 = styled.p`
  margin-top: 10px;
  font-size: 14px;
  text-align: justify;
  color: #525252;
`;

export const ViewTermos = styled.div`
  overflow-y: scroll;
  height: 80vh;
  border-radius: 5px;
  width: 80%;
  align-self: center;
  padding: 20px;
  background-color: #fff;
`;

export const ViewAceitarTermos = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;

export const TextModal = styled.p`
  color: #000;
  font-size: 14px;
  margin-top: 20px;
  text-align: justify;
`;

export const Titulo = styled.p`
  color: blue;
  font-size: 14px;
`;

export const TextTempoCancel = styled.p`
  color: blue;
  font-size: 14px;
`;

export const TextVermelho = styled.span`
  color: red;
  font-size: 13px;
`;

export const Produtos = styled.div`
  /* margin-top: 15px; */
  margin-top: 30px;
  width: 100%;
  border-radius: 5px;
  justify-content: center;
  position: relative;
`;

export const ViewProdutos = styled.div`
  width: 100%;
`;

export const HeaderProdutos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const TextProdutos = styled.span`
  font-size: 16px;
  margin-left: 10px;
  color: #525252;
  font-weight: bold;
`;

export const Line = styled.div`
  width: 1.5px;
  height: 27px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const CardProduto = styled.div`
  width: 100%;
  /* margin-top: 7px; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const ItemCategoria = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const NomeCategoria = styled.span`
  font-size: 14px;
  width: 80%;
`;

export const ValorProduto = styled.span`
  color: green;
`;

export const ViewModalDenuncia = styled.div`
  background-color: #fff;
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BotaoCheck = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextoCheck = styled.p`
  margin-left: 10px;
  font-size: 14px;
`;

export const BotaoDenunciar = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border:  1px solid ${({ disabled }) => disabled ? 'gray' : 'red'};
  margin-top: 20px;
  border-radius: 5px;
  align-self: center;
  cursor: pointer;
`;

export const TextoBotaoDenunciar = styled.p`
  color: ${({ disabled }) => disabled ? 'gray' : 'red'};
`;

export const TextoBotaoFechar = styled.p`
  color: #000;
`;

export const BotaoFechar = styled.div`
  width: 100px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;