import React from "react";

import { Container, TextoVoltar } from "./styles";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function BotaoVoltar({ onClick }) {
    return (
        <Container onClick={onClick}>
            <ArrowBackIosIcon style={{ color: '#fff', fontSize: 15 }} />
            <TextoVoltar>Voltar</TextoVoltar>
        </Container>
    )
}