export const horas = {
    '0': 'Não Cancelar',
    '1': '1 Minuto',
    '2': '2 Minutos',
    '3': '3 Minutos',
    '4': '4 Minutos',
    '5': '5 Minutos',
    '10': '10 Minutos',
    '15': '15 Minutos',
    '20': '20 Minutos',
    '30': '30 Minutos',
    '45': '45 Minutos',
    '60': '1 Hora',
    '120': '2 Horas',
    '180': '3 Horas',
    '240': '4 Horas',
    '300': '5 Horas',
    '360': '6 Horas',
    '420': '7 Horas',
    '480': '8 Horas',
    '540': '9 Horas',
    '600': '10 Horas',
    '660': '11 Horas',
    '720': '12 Horas',
    '1440': '1 Dia',
    '2880': '2 Dias',
    '4320': '3 Dias',
    '5760': '4 Dias',
    '7200': '5 Dias',
    '14400': '10 Dias',
    '21600': '15 Dias',
    '28800': '20 Dias',
    '43200': '1 Mês',
  };
  