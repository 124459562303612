import React, { useContext } from "react";

import {
  ViewIngresso,
  Valor,
  ValorOriginal,
  InformationWarning,
  MeiaEntradaTexto,
  ViewValor,
  MeiaEntradaTextoLot,
  Information,
  Wrapper,
  TitleCard,
  ViewIconesDireita,
  Lote,
  CortesiaTipo,
  Aviso,
  TextoObrigatorioDocumento,
  Aviso2,
  StatusColorBar,
} from "./styles";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DiscountIcon from "@mui/icons-material/Discount";

import { useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { AuthContext } from "../../context/auth";

function ItemEventos({ item, dados, evento, idpdv, usuario, mostrarAlerta, mostrarDialogo }) {
  const { informacoesApp } = useContext(AuthContext);

  const navigate = useNavigate();

  const ingressos_vendidos = Number(item.quantidade_vendidas);
  const ingressos_total = Number(item.quantidade_original);

  const porcetagem = Math.round((ingressos_vendidos / ingressos_total) * 100 > 100 ? 100 : (ingressos_vendidos / ingressos_total) * 100);

  function pegarIdadeUsuario() {
    if (usuario.id) {
      const hoje = new Date();

      const data_nascimento_usuario = new Date(usuario?.data_nascimento);

      const idade = Math.floor(Math.ceil(Math.abs(data_nascimento_usuario.getTime() - hoje.getTime()) / (1000 * 3600 * 24)) / 365.25);

      // CASO PERMITIR MENOR NÃO FOR UM NÚMERO IRÁ VERIFICAR SE É MENOR QUE 17 ANOS
      if (isNaN(dados?.permitir_menor)) {
        if (idade <= 17) {
          return false;
        } else {
          return true;
        }
        // CASO PERMITIR MENOR FOR UM NÚMERO IRÁ VERIFICAR COM O VALOR PASSADO PELA EMPRESA
      } else if (idade < Number(dados?.permitir_menor)) {
        return false;
      } else {
        return true;
      }
    }
  }

  function aoClicarNoEvento(item) {
    if (!usuario.id) {
      mostrarAlerta(`Para continuar, você precisa fazer o cadastro no ${informacoesApp.nome_app}.`);
      navigate("/login");
      return;
    } else if (dados?.evento?.obrigar_sexo === "Sim" && usuario.sexo === "nulo") {
      mostrarDialogo("Para continuar, você precisa selecionar o seu gênero.");
      return;
    } else if (String(item.valor) === "0.00" && item.id_tipo_de_ingresso !== 7) {
      mostrarAlerta("Ingresso não disponível para venda.");
      return;
    } else if (dados?.empresa?.cliente_e_bloqueado) {
      mostrarAlerta("Acesso negado, nesta empresa por forças maiores.");
      return;
    } else if (dados?.evento?.ativo === "Não") {
      mostrarAlerta("Ingresso não disponível para venda.");
      return;
    } else if (!pegarIdadeUsuario()) {
      mostrarDialogo("Menores de idade não podem fazer compra nessa empresa.");
      return;
    } else if (item.quantidade === "0" || item.quantidade === 0) {
      mostrarAlerta("Quantidade de ingresso disponível está esgotado.");
      return;
    } else if (usuario.cpf === "" || !usuario.cpf) {
      mostrarDialogo("Você precisa cadastrar o seu CPF para continuar a compra.");
      return;
    } else if (usuario.celular === "" || !usuario.celular) {
      mostrarDialogo("Você precisa cadastrar o seu Celular para continuar a compra.");
      return;
    }

    if (item.id === "4" || item.id === "5" || item.id === "6") {
      navigate(`/lugar/${evento}/${item.id}${idpdv ? `/${idpdv}` : ""}`);
    } else {
      navigate(`/adicionarcompra/${evento}/${item.dados.id}${idpdv ? `/${idpdv}` : ""}`);
    }
  }

  function Valores() {
    if (item.desconto_aplicado === "Nenhum" || item.desconto_aplicado === "4" || (item.desconto_aplicado === "0" && item.quantidade === "0") || item.quantidade === 0) {
      if (String(item.valor) === "0.00" && item.id_tipo_de_ingresso !== 7) {
        return <Valor>Valor à definir</Valor>;
      } else {
        return <Valor>R$ {Number(item.valor).toFixed(2).replace(".", ",")}</Valor>;
      }
    }

    return (
      <>
        <ValorOriginal>{Number(item.valor_original)}</ValorOriginal>
        <Valor>{Number(item.valor)}</Valor>
      </>
    );
  }

  function ViewValoresContainer() {
    if (item.quantidade === "0" || item.quantidade === 0) {
      return <Valor>R$ {item.valor_verdadeiro}</Valor>;
    }

    return <Valores />;
  }

  function Informacoes() {
    if (item.most_quan_ingres === "Sim") {
      return `Sobrando ${item.quantidade} ${item.sobrando}`;
    } else if (item.id_tipo_de_ingresso === "4" || item.id_tipo_de_ingresso === "5" || item.id_tipo_de_ingresso === "6") {
      if (item?.forma_valor === "Fixo") {
        return `Valor por ${item.nome}`;
      } else {
        return "Valor por lugar";
      }
    }

    return "Valor do Ingresso";
  }

  function ViewMeio() {
    if (item.quantidade === "0" || item.quantidade === 0) {
      return <InformationWarning>Ingressos esgotados</InformationWarning>;
    } else if (item.meia_entrada === "Sim") {
      return <MeiaEntradaTexto>Meia entrada</MeiaEntradaTexto>;
    } else if (item.id_tipo_de_ingresso === 7 || (item.most_quan_ingres === "Percentual" && item.meia_entrada !== "Sim")) {
      return (
        <ViewValor>
          <MeiaEntradaTextoLot>Lotação máx. em: </MeiaEntradaTextoLot>

          <LinearProgress variant="determinate" value={porcetagem} />
        </ViewValor>
      );
    } else {
      return <Information>{Informacoes()}</Information>;
    }
  }

  const IconeMeiaEntrada = () => {
    return (
      <svg width={14} height={14} viewBox="0 0 21 22" fill="#000000" style={{ marginRight: 5 }}>
        <path d="M5.79 21.61l-1.58-1.22 14-18 1.58 1.22-14 18M4 2v2h2v8h2V2H4m11 10v2h4v2h-2c-1.1 0-2 .9-2 2v4h6v-2h-4v-2h2c1.11 0 2-.89 2-2v-2a2 2 0 00-2-2h-4z" />
      </svg>
    );
  };

  function ViewIconeMeiaEntrada() {
    if (item.meia_entrada === "Sim") {
      return (
        <Wrapper>
          <IconeMeiaEntrada />

          <TitleCard>{item.nome}</TitleCard>
        </Wrapper>
      );
    }

    return <TitleCard>{item.nome}</TitleCard>;
  }

  function LoteView() {
    if (item.quantidade === "0" || item.quantidade === 0 || item.qual_lote === 0 || String(item.valor) === "0.00") {
      return <></>;
    }

    return <Lote>{item.qual_lote}° Lote</Lote>;
  }

  function ViewIconeCompras() {
    if (item.meia_entrada === "Sim") {
      return <InsertDriveFileIcon />;
    }

    return <></>;
  }

  function ViewIconePromocao() {
    if (item.meia_entrada === "Sim" || item.desconto_aplicado === "Nenhum" || item.desconto_aplicado === "4" || item.desconto_aplicado === "0" || item.quantidade === "0" || item.quantidade === 0) {
      return <></>;
    }

    return <DiscountIcon />;
  }

  function ViewCortesiaTipo() {
    if (item.id_tipo_de_ingresso === 7) {
      return <CortesiaTipo>{item.cortesia_tipoingresso}</CortesiaTipo>;
    }

    return <></>;
  }

  function ViewMeiaEntrada() {
    if (item.meia_entrada === "Sim") {
      if (item.habilitar_aviso === "Sim") {
        return (
          <>
            <Aviso>{item?.aviso ? `*${item?.aviso}` : ""}</Aviso>
            <TextoObrigatorioDocumento>*Obrigatório apresentar documento.</TextoObrigatorioDocumento>
          </>
        );
      } else {
        return <TextoObrigatorioDocumento>*Obrigatório apresentar documento.</TextoObrigatorioDocumento>;
      }
    } else if (item.habilitar_aviso === "Sim") {
      return <Aviso2 numberOfLines={1}>{item?.aviso ? `* ${item?.aviso}` : ""}</Aviso2>;
    }

    return <></>;
  }

  return (
    <ViewIngresso onClick={() => aoClicarNoEvento(item)} key={item.id}>
      <StatusColorBar ativo={dados?.evento?.ativo === "Sim" && !(item?.quantidade === 0 || item?.quantidade === "0") && !(String(item?.valor) === "0.00" && item?.id_tipo_de_ingresso !== 7)} />

      <ViewCortesiaTipo />

      <ViewIconesDireita>
        <LoteView />
        <ViewIconeCompras />
        <ViewIconePromocao />
      </ViewIconesDireita>

      <ViewIconeMeiaEntrada />

      <ViewMeio />

      <ViewValoresContainer />

      <ViewMeiaEntrada />
    </ViewIngresso>
  );
}

export default ItemEventos;
