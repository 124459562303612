/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Fade, Skeleton, Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { Overlay } from "../Evento/styles";
import { ViewCarregando } from "../Home/styles";

import {
  Alinhar,
  BotaoConcluir,
  BotaoQuantidade,
  Botoes,
  Container,
  Descricao,
  Foto,
  HorarioEvento,
  NomeEvento,
  Quantidade,
  TextoConcluir,
  TextoValorUnitario,
  TotalConcluir,
  ViewContainer,
  ViewFoto,
  ViewPrecoConcluir,
} from "./styles";

import { format, parseISO } from "date-fns";
import BotaoVoltar from "../../componentes/BotaoVoltar";
import Header from "../../componentes/Header";
import Responsivel from "../../componentes/Responsivel";
import { url_site } from "../../config";
import api from "../../servicos/api";
import { AlinharPassaportes, DescricaoContainer, DescricaoText, EventData, ImagemPassaporte, TextoPassaporte, ViewFotoPassaporte, ViewPassaporte } from "../Compra/styles";

export default function AdicionarCompra() {
  const { usuario, informacoesApp } = useContext(AuthContext);
  const { evento, produto, idpdv } = useParams();

  const navigate = useNavigate();

  const [quantidade, setQuantidade] = useState(1);
  const [total, setTotal] = useState(0);
  const [dados, setDados] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [mensagemAlerta, setMensagemAlerta] = useState("");

  useEffect(() => {
    listar();
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  async function listar() {
    await api
      .get(`compra/listar.php?evento=${evento}&produto=${produto}&cliente=${usuario.id}`)
      .then((response) => {
        setDados(response.data);
        setTotal(String(response.data?.ingresso?.valor).replace(",", ""));
      })
      .catch(console.log)
      .finally(() => {
        setCarregando(false);
      });
  }

  function irParaCompras() {
    if (dados?.evento?.usuario_jacomprou === true) {
      setMensagemAlerta("Já comprou o máximo de ingressos desse Evento.");
      return;
    }

    navigate(`/compra/${evento}/${produto}${idpdv ? `/${idpdv}` : ""}`, {
      state: { quantidade },
    });
  }

  function aumentar() {
    const quantidade_vendidos = dados?.evento?.quantidade_vendidos;
    const primeiras_pessoas = dados?.evento?.primeiras_pessoas;
    const quantidade_ingressos_comprados_usuario = dados?.evento?.quantidade_ingressos_comprados_usuario;
    const venda_por_usuario = dados?.evento?.venda_por_usuario;
    const desconto_aplicado = dados?.ingresso?.desconto_aplicado;
    const proximo_lote = dados?.ingresso?.proximo_lote;
    const primeiro_lote = dados?.ingresso?.primeiro_lote;
    const qual_lote = dados?.ingresso?.qual_lote;
    const ingressos_sobrando = dados?.ingresso?.quantidade;
    const quantidade_cortesia = dados?.ingresso?.quantidade_cortesia;

    if (quantidade >= Number(quantidade_cortesia)) {
      setMensagemAlerta("Máximos de ingressos de Cortesia atingido.");
    } else if (qual_lote === 1 ? quantidade >= primeiro_lote : qual_lote > 1 && quantidade >= proximo_lote) {
      setMensagemAlerta("Máximo de ingressos para esse lote.");
    } else if (
      quantidade >= Math.abs(primeiras_pessoas - quantidade_vendidos) &&
      desconto_aplicado !== "Nenhum" &&
      desconto_aplicado !== "4" &&
      desconto_aplicado !== "3" &&
      desconto_aplicado !== "2"
    ) {
      setMensagemAlerta("Máximos de ingressos com desconto atingido.");
    } else if (quantidade >= ingressos_sobrando) {
      setMensagemAlerta("Máximos de ingressos atingido.");
    } else if (quantidade_ingressos_comprados_usuario + quantidade >= venda_por_usuario) {
      setMensagemAlerta("Você chegou ao máximo de ingressos atingido.");
    } else {
      setQuantidade((quant) => quant + 1);
    }
  }

  function diminuir() {
    if (quantidade === 1) return;

    setQuantidade((quant) => quant - 1);
  }

  function voltar() {
    navigate(`/evento/${evento}${idpdv ? `/${idpdv}` : ""}`);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMensagemAlerta("");
  };

  return (
    <Container>
      <Header />

      <Responsivel>
        {carregando ? (
          <ViewCarregando>
            <Skeleton variant="rectangular" width="100%" height={286} />
            <Skeleton variant="rectangular" width="100%" height={60} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={80} sx={{ marginTop: 1, position: "absolute", bottom: 20 }} />
          </ViewCarregando>
        ) : (
          <>
            <ViewFoto>
              <BotaoVoltar onClick={() => voltar()} />

              <Foto
                src={url_site + `/sistema/img/eventos/${dados?.evento?.foto}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = url_site + "/sistema/img/eventos/sem-foto10.jpg";
                }}
              />

              <Alinhar>
                <NomeEvento>{dados?.evento?.nome_evento}</NomeEvento>
                <HorarioEvento>{dados?.evento?.horario_definitivo}</HorarioEvento>
              </Alinhar>

              <Overlay />
            </ViewFoto>

            {dados?.evento?.descricao && <Descricao>Descrição: {dados?.evento?.descricao}</Descricao>}

            {(dados?.ingresso?.dados?.id_tipo_de_ingresso === "12" ||
              dados?.ingresso?.dados?.id_tipo_de_ingresso === "13" ||
              dados?.ingresso?.dados?.id_tipo_de_ingresso === "14" ||
              dados?.ingresso?.dados?.id_tipo_de_ingresso === "15" ||
              dados?.ingresso?.dados?.id_tipo_de_ingresso === "16" ||
              dados?.ingresso?.dados?.id_tipo_de_ingresso === "17") && (
              <DescricaoContainer>
                <DescricaoText ellipsizeMode="clip" numberOfLines={5}>
                  Cronograma
                </DescricaoText>

                <AlinharPassaportes>
                  {dados?.ingresso?.eventosPassaporte.map((item, index) => (
                    <ViewPassaporte>
                      <ViewFotoPassaporte>
                        <ImagemPassaporte
                          style={{ width: "100%", height: 86, borderRadius: 5 }}
                          src={item.foto === null || item.foto === "sem-foto.jpg" ? url_site + `/sistema/img/eventos/${item.sem_foto}` : url_site + `/sistema/img/eventos/${item.foto}`}
                        />
                        <Alinhar>
                          <EventData>{format(parseISO(item.data), "dd/MM/yyyy")}</EventData>
                        </Alinhar>
                        <Overlay />
                      </ViewFotoPassaporte>

                      <TextoPassaporte numberOfLines={1}>{item.nome}</TextoPassaporte>
                    </ViewPassaporte>
                  ))}
                </AlinharPassaportes>
              </DescricaoContainer>
            )}

            <ViewContainer>
              <TextoValorUnitario>Valor Unitário: R$ {dados?.ingresso?.valorF}</TextoValorUnitario>

              <Botoes>
                <BotaoQuantidade onClick={diminuir}>-</BotaoQuantidade>
                <Quantidade>{quantidade}</Quantidade>
                <BotaoQuantidade onClick={aumentar}>+</BotaoQuantidade>
              </Botoes>

              <ViewPrecoConcluir>
                <BotaoConcluir corFundo={informacoesApp.cor_primaria} onClick={irParaCompras}>
                  <TextoConcluir>
                    Finalizar Compra{" "}
                    <TotalConcluir>
                      R${" "}
                      {Number(total * quantidade)
                        .toFixed(2)
                        .replace(".", ",")}
                    </TotalConcluir>
                  </TextoConcluir>
                </BotaoConcluir>
              </ViewPrecoConcluir>
            </ViewContainer>
          </>
        )}
      </Responsivel>

      <Snackbar
        open={mensagemAlerta.length > 0}
        autoHideDuration={6000}
        onClose={handleClose}
        message={mensagemAlerta}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
    </Container>
  );
}
