import styled from "styled-components";
import 'react-credit-cards/es/styles-compiled.css';

export const Container = styled.div`
  width: 100%;
  /* overflow: hidden; */
  height: 100vh;
  /* margin-top: 70px; */
`;

export const ImagemMapa = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ViewAssentos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const ViewAssentosInterior = styled.div`
  width: 70px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  position: relative;
  margin: 10px;
  margin-bottom: 20px;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  flex-direction: column;
`;

export const CodigoLugar = styled.span`
  font-size: 16px;
`;

export const QtdLugares = styled.span`
  font-size: 11px;
  text-align: center;
`;

export const Titulo = styled.p`
  font-size: 14px;
  text-align: center;
  width: 100%;
`;

export const Value = styled.p`
  color: green;
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
`;

export const Texto = styled.span`
  color: #000;
  font-size: 16px;
`;

export const Warning = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
`;
