/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Modal,
  Skeleton,
  Snackbar,
  TextField,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { horas } from "../../utils/horas";
import { BotaoCadastro, TextoCadastro, ViewCarregando } from "../Home/styles";

import {
  Banner,
  BannerTotal,
  BotaoCheck,
  BotaoDenunciar,
  BotaoFechar,
  BotoesOutros,
  CardProduto,
  Container,
  Descricao,
  Descricao2,
  HeaderProdutos,
  Info1,
  Info2,
  Info3,
  Info4,
  InfoOutros,
  ItemCategoria,
  Line,
  NomeCategoria,
  Outros,
  Overlay,
  Produtos,
  TextModal,
  TextProdutos,
  TextTempoCancel,
  TextVermelho,
  TextoBotaoDenunciar,
  TextoBotaoFechar,
  TextoCheck,
  TextoInfoOutros,
  Titulo,
  TituloDescricao,
  TituloEscolha,
  ValorProduto,
  ViewBanner,
  ViewBotoesOutros,
  ViewContainerIngresso,
  ViewInfoOutros,
  ViewModalDenuncia,
  ViewProdutos,
  ViewTermos,
} from "./styles";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import secureLocalStorage from "react-secure-storage";
import BotaoVoltar from "../../componentes/BotaoVoltar";
import Header from "../../componentes/Header";
import ItemEventos from "../../componentes/ItemEventos";
import Responsivel from "../../componentes/Responsivel";
import Rodape from "../../componentes/Rodape";
import { url_site } from "../../config";
import api from "../../servicos/api";

export default function Evento() {
  const { usuario, informacoesApp } = useContext(AuthContext);
  const { id, idpdv } = useParams();

  const [dados, setDados] = useState({});
  const [mensagemAlerta, setMensagemAlerta] = useState("");
  const [mensagemDialogo, setMensagemDialogo] = useState("");
  const [abrirMensagemDialogo, setAbrirMensagemDialogo] = useState(false);
  const [abrirImagemEvento, setAbrirImagemEvento] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [abrirModalTermos, setAbrirModalTermos] = useState(false);
  const [aparecerProdutos, setAparecerProdutos] = useState([]);

  const [abrirModalDenunciar, setAbrirModalDenunciar] = useState(false);
  const [denuncia, setdenuncia] = useState("");
  const [nome_denuncia, setnome_denuncia] = useState("");
  const [celular_denuncia, setcelular_denuncia] = useState("");
  const [checkboxDenunciar, setcheckboxDenunciar] = useState(false);

  const navigate = useNavigate();
  const localEnd = secureLocalStorage.getItem("endereco");
  const end = JSON.parse(localEnd);
  const id_usuario = secureLocalStorage.getItem("cliente");

  useEffect(() => {
    listar();
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  async function listar() {
    await api
      .get(`buscar_eventos/listar_evento.php?id=${id}&cliente=${id_usuario ?? 0}&cidade_usuario=${end?.id ?? 0}&tipo_venda=0`)
      .then((response) => {
        if (response.data.sucesso) {
          setDados(response.data);
        }
      })
      .catch(console.log)
      .finally(() => {
        setCarregando(false);
      });
  }

  function voltar() {
    navigate(`/empresa/${dados?.empresa?.id_empresa}`);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMensagemAlerta("");
  };

  const handleCloseModalTransferir = () => {
    setAbrirMensagemDialogo(false);
  };

  function aparecerProdutosFuncao(id) {
    if (aparecerProdutos.includes(id)) {
      setAparecerProdutos((prev) => prev.filter((r) => r !== id));
    } else {
      setAparecerProdutos([...aparecerProdutos, id]);
    }
  }

  async function denunciarFuncao() {
    const campos = {
      id_evento: id,
      id_empresa: dados?.empresa?.id_empresa,
      denuncia: denuncia,
      nome: nome_denuncia,
      celular: celular_denuncia,
    };

    // console.log(campos);

    await api
      .post("denunciar_evento/denunciar.php", campos)
      .then((res) => {
        // console.log(res.data);
        if (res.data.sucesso) {
          setAbrirModalDenunciar(false);
          setdenuncia("");
          setnome_denuncia("");
          setcelular_denuncia("");
        }
      })
      .catch(console.log);
  }

  const regexCelular = (value) => {
    return value
      ?.replace(/\D/g, "")
      ?.replace(/(\d{2})(\d)/, "($1) $2")
      ?.replace(/(\d{5})(\d)/, "$1-$2")
      ?.replace(/(-\d{4})\d+?$/, "$1");
  };

  const tempo_de_cancelamento = horas[dados?.empresa?.tempo_cancel];
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <Container>
      <Helmet>
        <title>{dados?.evento?.nome_evento}</title>
      </Helmet>

      <Header />

      <Responsivel>
        {carregando ? (
          <ViewCarregando>
            <Skeleton variant="rectangular" width="100%" height={286} />
            <Skeleton variant="rectangular" width="100%" height={150} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={150} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={150} sx={{ marginTop: 1 }} />
          </ViewCarregando>
        ) : (
          <>
            <ViewBanner>
              <BotaoVoltar onClick={() => voltar()} />

              <Banner
                src={url_site + `/sistema/img/eventos/${dados?.evento?.foto}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = url_site + "/sistema/img/eventos/sem-foto10.jpg";
                }}
              />

              <Info1>{dados?.empresa?.nome}</Info1>
              <Info2>{dados?.evento?.nome_evento}</Info2>
              <Info3>
                {dados?.evento?.horario_definitivo} |{" "}
                {dados?.evento?.ira_acontecer_em === 0
                  ? "É hoje"
                  : dados?.evento?.ira_acontecer_em < 0
                  ? "Evento"
                  : dados?.evento?.ira_acontecer_em && `Falta ${dados?.evento?.ira_acontecer_em} dias`}
              </Info3>
              <Info4>
                {dados?.empresa?.cidade?.nome} - {dados?.empresa?.cidade?.sigla_uf}
              </Info4>

              <Overlay onClick={() => setAbrirImagemEvento(true)} />
            </ViewBanner>

            <ViewContainerIngresso>
              <TituloEscolha>Escolha o Tipo de Ingresso.</TituloEscolha>
              {dados?.evento?.ingressosEvento.map((item, index) => (
                <ItemEventos
                  key={index}
                  item={item}
                  dados={dados}
                  evento={id}
                  idpdv={idpdv}
                  usuario={usuario}
                  mostrarAlerta={(msg) => {
                    setMensagemAlerta(msg);
                  }}
                  mostrarDialogo={(msg) => {
                    setAbrirMensagemDialogo(true);
                    setMensagemDialogo(msg);
                  }}
                />
              ))}
            </ViewContainerIngresso>

            {!usuario.id && (
              <BotaoCadastro style={{ backgroundColor: informacoesApp.cor_primaria }} onClick={() => navigate(`/login?voltarUrl=/evento/${id}`)}>
                <TextoCadastro>Entrar</TextoCadastro>
              </BotaoCadastro>
            )}

            <Snackbar
              open={mensagemAlerta.length > 0}
              autoHideDuration={6000}
              onClose={handleClose}
              message={mensagemAlerta}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />

            <Dialog open={abrirMensagemDialogo} onClose={handleCloseModalTransferir}>
              <DialogTitle id="alert-dialog-title">Verificação de Dados</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{mensagemDialogo}</DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => handleCloseModalTransferir()}>Cancelar</Button>
                <Button onClick={() => navigate(`/usuario?voltarUrl=/evento/${id}`)} autoFocus>
                  Editar
                </Button>
              </DialogActions>
            </Dialog>

            <Modal
              open={abrirImagemEvento}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClose={() => setAbrirImagemEvento(false)}
            >
              <>
                <BotaoFechar onClick={() => setAbrirImagemEvento(false)}>
                  <TextoBotaoFechar>Fechar</TextoBotaoFechar>
                </BotaoFechar>

                <BannerTotal
                  src={url_site + `/sistema/img/eventos/${dados?.evento?.foto}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = url_site + "/sistema/img/eventos/sem-foto10.jpg";
                  }}
                />
              </>
            </Modal>
          </>
        )}

        <Outros>
          <ViewInfoOutros>
            <InfoOutros>
              <LocationOnOutlinedIcon style={{ color: "#5e5e5e" }} />
              <TextoInfoOutros>{dados?.evento?.cidade ?? dados?.empresa?.nome_cidade}</TextoInfoOutros>
            </InfoOutros>

            <InfoOutros>
              <CalendarMonthOutlinedIcon style={{ color: "#5e5e5e" }} />
              <TextoInfoOutros>
                {dados?.evento?.data_evento ? format(parseISO(dados?.evento?.data_evento), "dd 'de' MMMM',' yyyy'", { locale: ptBR }) : ""}
              </TextoInfoOutros>
            </InfoOutros>
          </ViewInfoOutros>

          <TituloDescricao>Descrição do Evento</TituloDescricao>
          <Descricao>{dados?.evento?.descricoesEvento?.descricao_1 ?? "..."}</Descricao>
          {dados?.evento?.descricoesEvento?.descricao_2 ? <Descricao2>{dados?.evento?.descricoesEvento?.descricao_2} </Descricao2> : <></>}

          {dados?.evento?.produtosDispEvento ? (
            <Produtos>
              <ViewProdutos>
                {Object.keys(dados?.evento?.produtosDispEvento).map((item, index) => (
                  <ItemCategoria>
                    <HeaderProdutos onClick={() => aparecerProdutosFuncao(item)}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          marginBottom: 10,
                        }}
                      >
                        <Line style={{ backgroundColor: "red" }} />
                        <TextProdutos>{item}</TextProdutos>
                      </div>

                      {aparecerProdutos.includes(item) ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                    </HeaderProdutos>

                    {aparecerProdutos.includes(item) ? (
                      <>
                        {dados?.evento?.produtosDispEvento[item]?.map((item, index) => (
                          <CardProduto key={item}>
                            <NomeCategoria numberOfLines={1}>{item?.nome}</NomeCategoria>
                            <ValorProduto>R$ {String(item?.valor).replace(".", ",")}</ValorProduto>
                          </CardProduto>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </ItemCategoria>
                ))}
              </ViewProdutos>
            </Produtos>
          ) : (
            <></>
          )}

          <ViewBotoesOutros>
            <BotoesOutros onClick={() => setAbrirModalTermos(true)}>
              <p>Termos de Uso</p>
            </BotoesOutros>

            <BotoesOutros style={{ borderColor: "red" }} onClick={() => setAbrirModalDenunciar(true)}>
              <p style={{ color: "red" }}>Denunciar</p>
            </BotoesOutros>
          </ViewBotoesOutros>
        </Outros>
      </Responsivel>

      <Modal
        disableEnforceFocus
        open={abrirModalDenunciar}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        onClose={() => setAbrirModalDenunciar(false)}
      >
        <ViewModalDenuncia>
          <TextField
            type="text"
            value={nome_denuncia}
            style={{ marginBottom: 10, width: "100%", maxWidth: 400 }}
            label="Nome"
            fullWidth
            onChange={(e) => {
              setnome_denuncia(e.target.value);
            }}
          />

          <TextField
            type="tel"
            value={celular_denuncia}
            style={{ marginBottom: 10, width: "100%", maxWidth: 400 }}
            label="Celular"
            fullWidth
            onChange={(e) => {
              setcelular_denuncia(regexCelular(e.target.value));
            }}
          />

          <TextField
            type="text"
            value={denuncia}
            style={{ marginBottom: 0, width: "100%", maxWidth: 400 }}
            label="Mensagem"
            fullWidth
            multiline
            rows={4}
            onChange={(e) => {
              setdenuncia(e.target.value);
            }}
          />

          <BotaoCheck onClick={() => setcheckboxDenunciar((prev) => !prev)}>
            <Checkbox {...label} size="medium" checked={checkboxDenunciar} />
            <TextoCheck>Autorizo que a Center entre em contato para pedir mais informações.</TextoCheck>
          </BotaoCheck>

          <BotaoDenunciar
            disabled={!checkboxDenunciar || denuncia.length <= 0 || nome_denuncia.length <= 0 || celular_denuncia.length <= 0}
            onClick={() => denunciarFuncao()}
          >
            <TextoBotaoDenunciar disabled={!checkboxDenunciar || denuncia.length <= 0 || nome_denuncia.length <= 0 || celular_denuncia.length <= 0}>
              Enviar Denúncia
            </TextoBotaoDenunciar>
          </BotaoDenunciar>
        </ViewModalDenuncia>
      </Modal>

      <Modal
        disableEnforceFocus
        open={abrirModalTermos}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        onClose={() => setAbrirModalTermos(false)}
      >
        <ViewTermos>
          <TextModal>
            <Titulo>Aceitação dos Termos de Uso:</Titulo>
            {"\n"}
            Estes Termos de Uso disciplinam os termos e condições aplicáveis a seu uso dos Serviços. Ao utilizar os Serviços, você ratifica que leu,
            compreendeu e concordou em ser legalmente vinculado a estes Termos.
            {"\n"}
            {"\n"}
            Se você não concorda com estes Termos, por favor não utilize os Serviços.
            {"\n"}
            {"\n"}
            Sujeito às condições abaixo, o {informacoesApp.nome_app} poderá modificar estes Termos periodicamente e de modo não retroativo, e
            modificar, adicionar ou descontinuar qualquer aspecto, conteúdo ou característica dos Serviços, a seu próprio critério. Seu uso ou acesso
            continuado dos Serviços após a publicação de quaisquer mudanças nos Termos constitui sua aceitação de tais mudanças. Na medida em que uma
            lei ou decisão judicial aplicável determine que a aplicação de quaisquer mudanças a estes Termos seja inexequível, tais mudanças deverão
            ser aplicáveis apenas com relação a eventos ou circunstâncias que ocorrerem após a data de tais mudanças, na medida em que for necessário
            para evitar que estes Termos sejam considerados inexequíveis.
            {"\n"}
            {"\n"}
            Qualquer forma de transferência ou sublicença, ou acesso, distribuição, reprodução, cópia, retransmissão, publicação, venda ou exploração
            (comercial ou não) não autorizados de qualquer parte dos Serviços incluindo, mas não se limitando a todo conteúdo, serviços, produtos
            digitais, ferramentas ou produtos, é expressamente proibida por estes Termos.
            {"\n"}
            {"\n"}
            <Titulo>Condições de compra:</Titulo>
            {"\n"}- Limite de compra por Usuário: até {dados?.empresa?.venda_usuario} ingressos para cada dia de evento.
            {"\n"}
            {"\n"}
            Formas de pagamento disponibilizadas:{" "}
            {dados?.empresa?.tipo_pagamento?.status_libera_online && dados?.empresa?.pagamentos_disponiveis?.primeiro}{" "}
            {dados?.empresa?.tipo_pagamento?.status_libera_local && "e " + dados?.empresa?.pagamentos_disponiveis?.segundo}
            {"\n"}
            {"\n"}
            Cancelamentos, Trocas e Devoluções: Não serão aceitos pedidos de cancelamentos do Ingresso. Após inserir seus dados, escolher o evento,
            setor e finalizar a compra não é possível substituir nenhum desses itens tampouco devolver o ingresso.
            {"\n"}
            {"\n"}
            <Titulo>Taxa de Conveniência:</Titulo>
            {"\n"}A taxa de serviço é o valor cobrado pela {informacoesApp.nome_app} quando Você compra ingressos para Eventos parceiros da{" "}
            {informacoesApp.nome_app} através de nossos Serviços. O valor da taxa de serviço é mostrado durante o processo de compra de ingressos.
            {"\n"}
            {"\n"}
            <Titulo>Cancelamento do evento / show:</Titulo>
            {"\n"}
            Eventuais alterações na programação, tais como cancelamento do evento ou do show após a conclusão da compra, são de responsabilidade legal
            e exclusiva dos Produtores do evento, devendo estes se responsabilizarem por devoluções de valores e indenizações. Na hipótese de
            cancelamento do evento / show, seja por casos fortuitos ou motivos de força maior, ou por atos de intervenção do Poder Público, o{" "}
            {informacoesApp.nome_app} não será responsabilizada de nenhuma forma.
            {"\n"}
            {"\n"}
            <Titulo>Documentação de Entrada:</Titulo>
            {"\n"}- No dia do evento, apresente-o diretamente na entrada do evento, juntamente com documento com foto: CNH ou RG (original ou cópia
            autenticada) do(a) titular da compra.
            {"\n"}- Idosos: com idade superior a 60 anos: CNH ou RG (original ou cópia autenticada).
            {"\n"}
            {"\n"}
            <Titulo>Direito de Uso de Imagens:</Titulo>
            {"\n"}O evento / show poderá ser filmado, gravado, fotografado a critério do organizador / produtor, para posterior publicação,
            transmissão, retransmissão, reprodução ou divulgação em TV, cinema, rádio, internet, publicidade ou qualquer outro veículo de comunicação,
            produção de DVD e home-vídeo. Ao comparecer ao evento / show, você concorda, autoriza e cede o uso gratuito de sua imagem, nome, voz nos
            termos ora mencionados, sem limitação, sem que caracterize uso indevido ou qualquer outra violação de direitos e sem que deste uso decorra
            qualquer ônus / indenização. O comparecimento ao evento / show implica a aceitação incondicional do termo acima.
            {"\n"}
            {"\n"}
            <Titulo>Política de Privacidade:</Titulo>
            {"\n"}
            Seu uso dos Serviços e o tratamento, por parte da {informacoesApp.nome_app}, de qualquer informação fornecida por você ou recolhida pela{" "}
            {informacoesApp.nome_app} ou partes terceiras durante qualquer visita ou uso dos Serviços é regida pela Política de Privacidade, aqui
            incorporada por meio desta referência. A Política de Privacidade é o documento que regula o tratamento de dados pessoais e/ou
            confidenciais pela {informacoesApp.nome_app} e seus prestadores de serviços ou parceiros. A coleta, uso e compartilhamento, por parte da{" "}
            {informacoesApp.nome_app}, de suas informações pessoais serão realizados nos termos determinados na Política de Privacidade, razão pela
            qual você deve ler tal Política com atenção e descontinuar o uso dos Serviços caso não concorde com a descrição do tratamento.
            {"\n"}
            {"\n"}
            {dados?.empresa?.permitir_menor === "Não" && (
              <TextModal>
                <Titulo>Menores de Idade:</Titulo>
                {"\n"}
                Os Serviços não foram formulados nem são destinados para serem usados por indivíduos menores de 18 anos e, portanto, se você for menor
                de 18 anos, solicitamos que você não use os Serviços nem nos forneça qualquer dado pessoal.
              </TextModal>
            )}
            <Titulo style={{ color: "blue" }}>• IMPORTANTE: </Titulo>
            {"\n"}
            Em caso de cópias do e-Ticket, o sistema de segurança validará apenas o primeiro acesso.
            {"\n"}
            {"\n"}
            Após validados uma vez, o e-Ticket não poderá ser reutilizado.
            {"\n"}
            {"\n"}
            Não nos responsabilizamos por qualquer PERDA, DANO ou ROUBO do seu e-Ticket.
            {"\n"}
            {"\n"}
            Nós da {informacoesApp.nome_app} e a Empresa pelo evento não se responsabiliza pela transferência de ingressos ou revenda do mesmo.
            {"\n"}
            {"\n"}
            {tempo_de_cancelamento !== "Não Cancelar" && (
              <TextModal>
                O pagamento do ingresso deve ser realizado em através <TextTempoCancel>{tempo_de_cancelamento}</TextTempoCancel>. Após isso, o pedido
                será <TextVermelho>cancelado automaticamente</TextVermelho>.
              </TextModal>
            )}
            {"\n"}
            {"\n"}
          </TextModal>
        </ViewTermos>
      </Modal>

      <Rodape marginTop={20} />
    </Container>
  );
}
