import styled from "styled-components";

export const ViewIngresso = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 15vh;
  background-color: #fff;
  margin: 10px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  padding: 0 10px;
  -webkit-box-shadow: 0px 10px 13px -10px #000000; 
  box-shadow: 0px 10px 13px -10px #000000;
  /* -webkit-box-shadow: 10px 0px 27px -10px #000000; 
  box-shadow: 10px 0px 27px -10px #000000; */

  &:hover {
    background-color: #eee;
  }
`;

export const NomeIngresso = styled.span`
  color: #000;
  font-size: 1em;
`;

export const InfoIngresso1 = styled.span`
  color: #000;
  font-size: 0.9em;
`;

export const InfoIngresso2 = styled.span`
  color: #000;
  font-size: 0.9em;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const InfoIngresso3 = styled.span`
  color: #000;
  font-size: 0.7em;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export const ValorIngresso = styled.span`
  color: #000;
  font-size: 1em;
`;

export const Valor = styled.span`
  color: #000;
  font-size: 15px;
  position: absolute;
  left: 10px;
  bottom: 5px;
`;

export const ValorOriginal = styled.span`
  color: #000;
  font-size: 11px;
  text-decoration: line-through;
  position: absolute;
  left: 0px;
  bottom: 17px;
`;

export const InformationWarning = styled.span`
  color: red;
  font-size: 14px;
`;

export const MeiaEntradaTexto = styled.span`
  color: red;
  font-size: 14px;
`;

export const ViewValor = styled.div`

`;

export const MeiaEntradaTextoLot = styled.span`
  color: #000;
  font-size: 9px;
`;

export const Information = styled.span`
  color: #000;
  font-size: 14px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TitleCard = styled.span`
  width: 67%;
  color: #000;
  font-size: 1em;
  /* letter-spacing: 1px; */
  position: absolute;
  top: 5px;
  left: 10px;
`;

export const ViewIconesDireita = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  top: 5px;
`;

export const Lote = styled.span`
  color: #000;
  font-size: 14px;
`;

export const CortesiaTipo = styled.span`
  color: #000;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 0px;
`;

export const Aviso = styled.span`
  color: #000;
  font-size: 0.6em;
  position: absolute;
  right: 10px;
  bottom: 15px;
`;

export const TextoObrigatorioDocumento = styled.span`
  color: #000;
  font-size: 0.6em;
  position: absolute;
  right: 10px;
  bottom: 5px;
`;

export const Aviso2 = styled.span`
  max-width: 70%;
  color: #000;
  font-size: 0.6em;
  position: absolute;
  right: 10px;
  bottom: 5px;
`;

export const StatusColorBar = styled.div`
  width: 4px;
  height: 100%;
  background-color: ${({ ativo }) => ativo ? 'green' : 'red'};
  position: absolute;
  left: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;
