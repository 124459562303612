import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Input = styled.input`
  width: 80%;
  /* min-width: 376px; */
  display: block;
  height: 50px;
  border: none;
  border: 1px solid #444;
  margin: 10px 0px;
  padding-left: 20px;
  border-radius: 5px;
  font-size: 16px;
`;

export const Botao = styled.input`
  width: 80%;
  height: 50px;
  /* background-color: red; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  color: #fff;
`;

export const LogoCenterIngressos = styled.img`
  width: 180px;
  height: 120px;
  margin-top: -100px;
  margin-bottom: 10px;
`;

export const BotaoCadastrar = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
`;

export const CadastraseTexto = styled.span`

`;

export const TextoAzul = styled.span`
  color: blue;
`;