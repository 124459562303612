import React from "react";

import { useNavigate } from "react-router-dom";
import { useHorizontalScroll } from "../../utils/useSlideHorizontal";

import { url_site } from "../../config";
import { Container, ContainerEvento, Imagem, Info, ItemEvento, NomeEvento, Overlay } from "./styles";

function ListaEventosEmpresa({ dados }) {
  const navigate = useNavigate();
  const scrollRef = useHorizontalScroll();

  function aoClicarNoEvento(item) {
    console.log(item);
    navigate(`/evento/${item.id}`);
  }

  return (
    <Container>
      <ContainerEvento ref={scrollRef} id="slider">
        {dados.map((item, index) => (
          <ItemEvento key={item.id} onClick={() => aoClicarNoEvento(item)}>
            <Imagem
              src={url_site + `/sistema/img/eventos/${item.foto}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = url_site + "/sistema/img/eventos/sem-foto10.jpg";
              }}
            />

            <Info>{item.data_evento}</Info>
            <NomeEvento>{item.nome_evento}</NomeEvento>

            <Overlay />
          </ItemEvento>
        ))}
      </ContainerEvento>
    </Container>
  );
}

export default ListaEventosEmpresa;
