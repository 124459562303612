import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { url_site } from "../../config";

import { Container, ImagemEvento, Info, ItemEvento, Overlay, ViewEvento } from "./styles";

import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function ListaEventos({ dados }) {
  const [rolando, setRolando] = useState(false);

  const navigate = useNavigate();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: dados.length > 1 ? 1 : 1,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToScroll: dados.length > 1 ? 2 : 1,
    cssEase: "linear",
    pauseOnHover: true,
    centerMode: true,
    variableWidth: true,

    beforeChange: () => setRolando(true),
    afterChange: () => setRolando(false),

    responsive: [
      {
        breakpoint: 320,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  function aoClicar(item) {
    if (rolando) return;

    navigate(`/evento/${item.id}`, { replace: true });
  }

  return (
    <Container>
      <div
        style={{
          padding: "10px 0px",
        }}
      >
        <Slider {...settings}>
          {dados.map((item, index) => (
            <ItemEvento key={item.id} onClick={() => aoClicar(item)}>
              <ViewEvento tamanho={dados?.length}>
                <ImagemEvento
                  src={url_site + `/sistema/img/eventos/${item?.foto}`}
                  alt="Imagem do Evento"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = url_site + "/sistema/img/eventos/sem-foto10.jpg";
                  }}
                />

                <Info
                  style={{
                    position: "absolute",
                    left: 15,
                    bottom: 36,
                    color: "#999",
                  }}
                >
                  {item.nome_empresa}
                </Info>
                <Info
                  style={{
                    position: "absolute",
                    left: 15,
                    bottom: 16,
                    color: "#999",
                  }}
                >
                  {item.nome_cidade}
                </Info>
                <Info
                  style={{
                    position: "absolute",
                    left: 15,
                    bottom: -5,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "65%",
                    overflow: "hidden",
                  }}
                >
                  {item.nome}
                </Info>

                <Info
                  style={{
                    position: "absolute",
                    right: 25,
                    bottom: 16,
                    color: "#999",
                  }}
                >
                  {item.dia_da_semana}
                </Info>
                <Info style={{ position: "absolute", right: 25, bottom: -5 }}>{item.data_evento}</Info>

                <Overlay />
              </ViewEvento>
            </ItemEvento>
          ))}
        </Slider>
      </div>
    </Container>
  );
}

export default ListaEventos;
