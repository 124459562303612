import styled from "styled-components";

export const ContainerModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  z-index: 1000;
`;

export const Container = styled.div`
  width: 70%;
  padding: 24px;
  z-index: 1001;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  h1 {
    font-size: 22px;
  }
  p {
    margin-top: 8px;
  }

  @media (min-width: 1024px) {
    width: ${({ desktopWidth, width }) => desktopWidth ?? width};
  }

  @media (max-width: 480px) {
    width: ${({ mobileWidth, width }) => mobileWidth ?? width};
  }
`;

export const BotaoFechar = styled.div`
  position: absolute;
  right: 10px;
  top: 30px;
  cursor: pointer;
`;
