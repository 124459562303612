/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Modal, Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { BannerTotal, BotaoFechar, TextoBotaoFechar, ViewBanner } from "../Evento/styles";

import { CodigoLugar, Container, ImagemMapa, QtdLugares, Texto, Titulo, Value, ViewAssentos, ViewAssentosInterior, Warning } from "./styles";

import secureLocalStorage from "react-secure-storage";
import BotaoVoltar from "../../componentes/BotaoVoltar";
import Header from "../../componentes/Header";
import Responsivel from "../../componentes/Responsivel";
import { url_site } from "../../config";
import api from "../../servicos/api";
import { ViewCarregando } from "../Home/styles";

export default function EscolherLugar() {
  const { usuario } = useContext(AuthContext);
  const { evento, produto, idpdv } = useParams();

  const [assentos, setAssentos] = useState({});
  const [carregando, setCarregando] = useState({});

  const [abrirImagem, setAbrirImagem] = useState(false);

  const navigate = useNavigate();
  const id_usuario = secureLocalStorage.getItem("cliente");

  const modelos_ingressos = {
    1: "Venda",
    2: "Consumação",
    3: "Reserva",
  };

  useEffect(() => {
    listar();
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  async function listar() {
    if (!id_usuario) {
      navigate("/");
    }

    await api
      .get(`buscar_eventos/listar_evento.php?id=${evento}&cliente=${usuario.id}&tipo_venda=0`)
      .then((response) => {
        const ingressosEvento = response.data.evento.ingressosEvento;

        const _assentos = ingressosEvento.filter((val) => val.id === produto);

        if (response.data.sucesso) {
          setAssentos(_assentos[0]);
        }
      })
      .catch(console.log)
      .finally(() => {
        setCarregando(false);
      });
  }

  const imagem_mapa =
    assentos?.mapaBistro?.ativo === "Sim" && assentos?.mapaBistro
      ? url_site + `/sistema/img/mapa_bistro/${assentos?.mapaBistro?.foto}`
      : assentos?.mapaSofa?.ativo === "Sim" && assentos?.mapaSofa
      ? url_site + `/sistema/img/mapa_sofa/${assentos?.mapaSofa?.foto}`
      : assentos?.mapaMesa?.ativo === "Sim" && assentos?.mapaMesa
      ? url_site + `/sistema/img/mapa_mesa/${assentos?.mapaMesa?.foto}`
      : url_site + `/sistema/img/banner_evento/${assentos?.sem_foto}`;

  function aoClicar(item) {
    if (item.is_used) {
      return;
    }

    navigate(`/compra/${evento}/${item.id}${idpdv ? `/${idpdv}` : ""}`);
  }

  const msg = () => {
    if (assentos?.modelo_ingresso === "1") {
      if (assentos?.forma_valor === "Fixo") {
        return (
          <Value>
            <Texto>Valor por {assentos?.nome}</Texto>
            <br></br>R$ {Number(assentos?.valor)}
          </Value>
        );
      } else {
        return (
          <Value>
            <Texto>Valor por lugar</Texto> <br></br>R$ {Number(assentos?.valor)}
          </Value>
        );
      }
    } else if (assentos?.modelo_ingresso === "2") {
      return <Warning>Você não será cobrado, somente pelo consumo.</Warning>;
    } else {
      return <Warning>Você não será cobrado.</Warning>;
    }
  };

  function voltar() {
    navigate(`/evento/${evento}`);
  }

  return (
    <Container>
      <Header />

      <Responsivel>
        {carregando ? (
          <ViewCarregando>
            <Skeleton variant="rectangular" width="100%" height={286} />
            <Skeleton variant="rectangular" width="100%" height={150} sx={{ marginTop: 1 }} />
          </ViewCarregando>
        ) : (
          <>
            <ViewBanner>
              <BotaoVoltar onClick={() => voltar()} />

              <ImagemMapa onClick={() => setAbrirImagem(true)} src={imagem_mapa} />
            </ViewBanner>

            <Titulo>
              {modelos_ingressos[assentos?.modelo_ingresso]} | {assentos?.nome}
            </Titulo>

            {msg()}

            <ViewAssentos>
              {assentos?.dados?.map((item, index) => (
                <ViewAssentosInterior key={item.id} onClick={() => aoClicar(item)} style={item.is_used ? { backgroundColor: "red" } : {}}>
                  <CodigoLugar>{item.codigo}</CodigoLugar>
                  <QtdLugares>{item.lugares} lugares</QtdLugares>

                  {assentos.forma_valor === "Variavel" && (
                    <p
                      style={{
                        color: "#000",
                        fontSize: 14,
                        position: "absolute",
                        bottom: -40,
                      }}
                    >
                      R$ {Number(assentos.valor) * Number(item.lugares)}
                    </p>
                  )}
                </ViewAssentosInterior>
              ))}
            </ViewAssentos>

            <Modal
              open={abrirImagem}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClose={() => setAbrirImagem(false)}
            >
              <>
                <BotaoFechar onClick={() => setAbrirImagem(false)}>
                  <TextoBotaoFechar>Fechar</TextoBotaoFechar>
                </BotaoFechar>

                <BannerTotal src={imagem_mapa} />
              </>
            </Modal>
          </>
        )}
      </Responsivel>
    </Container>
  );
}
