import styled from "styled-components";

const width_ = window.innerWidth;

export const Container = styled.div`
	position: relative;
	// max-width: 100vw;
  width: 100%;
	// margin: 0 auto;
  overflow: hidden;
  // align-self: center;

`;

export const ContainerCidade = styled.div`
	box-sizing: border-box;
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
  
	-webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ViewCidade = styled.div`

  display: inline-block;
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

export const ViewCidadeInt = styled.div`
  width: 107px;
  height: 100%;
  border-radius: 5px;
  background-color: ${({ ativo }) => ativo ? '#5a5a5c' : '#202125'} ;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  text-align: center;
`;

export const Info = styled.p`
  color: #fff;
  width: 90px;
  font-size: 13px;
  z-index: 35;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  overflow: hidden;
`;