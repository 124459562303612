/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  Snackbar,
  TextField,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { AuthContext } from "../../context/auth";
import { horas } from "../../utils/horas";
import { BotaoCopiarPIX, TempoDeCancel, TextoCopiarPIX, ViewCancelamento } from "../Compra/styles";
import { ViewCarregando } from "../Home/styles";

import {
  BotaoQRCode,
  BotaoTransferir,
  CPFUsuario,
  Cabecalho,
  CircleLeft,
  CircleRight,
  Codigo,
  Container,
  CorExemplo,
  ExemploView,
  IDCompra,
  Importante,
  ImportanteView,
  Info,
  InfoAviso,
  InfoDataCompra,
  InfoDataEvento,
  InfoHoraCompra,
  InfoHoraEvento,
  InfoImportante,
  InfoLugar,
  InfoNomeEvento,
  InfoStatus,
  InfoTipoIngresso,
  Item,
  LinhaSerrilhado,
  Logo,
  Middle,
  NomeEvento,
  NomeIngresso,
  NomeUsuario,
  PagoCorView,
  Qrcode,
  TextoBotaoQR,
  TextoExemplo,
  TextoTransferir,
  TitleModalInfo,
  Titulo,
  Valores,
  ViewBotoesQRCode,
  ViewCompras,
  ViewDataEvento,
  ViewHoraEvento,
  ViewIDCliente,
  ViewIDIngresso,
  ViewInfo,
  ViewInfo1,
  ViewInfo2,
  ViewInfo3,
  ViewInfoHora,
  ViewInfoInterna,
  ViewInfoUsuario,
  ViewInformacoes,
  ViewInformacoesInterna,
  ViewInfos,
  ViewInfos2,
  ViewInternoQRCode,
  ViewModal,
  ViewModalQrcode,
  ViewQRCodeInfo1,
  ViewQRCodeInfo2,
  ViewRodapeIngresso,
  ViewStatus,
  ViewValores,
  Wrap,
} from "./styles";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PersonIcon from "@mui/icons-material/Person";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Fade from "@mui/material/Fade";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import QRCode from "react-qr-code";
import secureLocalStorage from "react-secure-storage";
import Header from "../../componentes/Header";
import Modal from "../../componentes/Modal";
import Responsivel from "../../componentes/Responsivel";
import { logo_app_caminho, url_site } from "../../config";
import api from "../../servicos/api";

export default function Ingressos() {
  const { usuario, carregando, informacoesApp } = useContext(AuthContext);

  const id_usuario = secureLocalStorage.getItem("cliente");

  const [dados, setDados] = useState([]);
  const [abrirModalInformacoesIngresso, setAbrirModalInformacoesIngresso] = useState(false);
  const [abrirModalQRCode, setAbrirModalQRCode] = useState(false);
  const [informacoesIngressos, setInformacoesIngressos] = useState({});
  const [horaAtual, setHoraAtual] = useState("");
  const [modoTransferencia, setModoTransferencia] = useState("0");
  const [mensagemAlerta, setMensagemAlerta] = useState("");
  const [modalTransferir, setModalTransferir] = useState(false);
  const [baixandoIngresso, setBaixandoIngresso] = useState(false);
  const [IDIngresso, setIDIngresso] = useState(0);
  const [clienteVinculado, setClienteVinculado] = useState("");
  const [erroTransferir, setErroTransferir] = useState("");
  const [cliente, setCliente] = useState("");
  const [copiado, setCopiado] = useState(false);
  const [carregandoCompras, setCarregandoCompras] = useState(true);

  useEffect(() => {
    listar();
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [carregando]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (abrirModalQRCode === true) {
        refreshTime();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [abrirModalQRCode]);

  async function listar() {
    if (usuario?.id || !carregando) {
      await api
        .get(`compras/listar.php?cliente=${id_usuario}`)
        .then((response) => {
          console.log(id_usuario);
          if (response.data.success === true) {
            setDados(response.data.resultado);
          } else {
            setDados([]);
          }
        })
        .catch(console.log)
        .finally(() => {
          setCarregandoCompras(false);
        });
    }
  }

  function aoAbrirModalInformacoesIngressos(item, tipo) {
    if (tipo === "info") {
      setAbrirModalInformacoesIngresso(true);
      window.scrollTo({ top: 0, behavior: "instant" });
    } else if (tipo === "qrcode") {
      setAbrirModalQRCode(true);
      window.scrollTo({ top: 0, behavior: "instant" });
    }

    setInformacoesIngressos(item);
  }

  function mudarModoDaTela() {
    if (modoTransferencia === "0") {
      setModoTransferencia("mudar_true");
    } else if (modoTransferencia === "mudar_true") {
      setModoTransferencia("mudar_false");
    } else if (modoTransferencia === "mudar_false") {
      setModoTransferencia("mudar_true");
    }

    listar();
  }

  function refreshTime() {
    // var dateString = new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" });
    // var formattedString = dateString.split(', ')[1];
    setHoraAtual(format(new Date(), "HH:mm:ss"));
  }

  function aoClicarNoIngresso(item, info) {
    if (item.status === "Cancelado") {
      setMensagemAlerta("Este Ingresso está Cancelado.");
      return;
    }

    if (modoTransferencia === "mudar_true") {
      abrirModalDeTransferencia(item);
    } else {
      aoAbrirModalInformacoesIngressos(item, info);
    }
  }

  async function abrirModalDeTransferencia(data) {
    if (data.id_cliente !== usuario.id) {
      setMensagemAlerta("Não é possível transferir, pois esse ingresso não lhe pertence.");
    } else if (data.status === "Cancelado") {
      setMensagemAlerta("Não é possível transferir, pois este ingresso foi cancelado.");
    } else if (data.permitir_transferencia === "Não") {
      setMensagemAlerta("Esta empresa ou evento, desabilitou a transferência de ingressos.");
    } else if (data.status === "Pendente") {
      if (data.pago === "Sim") {
        setIDIngresso(data.id);
        setModalTransferir(true);
        window.scrollTo({ top: 0, behavior: "instant" });
      } else {
        setMensagemAlerta("Não é possível transferir, pois você precisa pagar antes de enviar para alguém.");
      }
    } else {
      setMensagemAlerta("Não é possível transferir, ingresso já ultilizado.");
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMensagemAlerta("");
  };

  const handleCloseModalTransferir = () => {
    setModalTransferir(false);
  };

  async function vincularCliente() {
    if (clienteVinculado !== "") {
      const obj = {
        id_ingresso: IDIngresso,
        id_cliente: clienteVinculado,
      };

      await api
        .post("vincular/buscar_id_cliente.php", obj)
        .then((cliente_res) => {
          if (cliente_res.data.sucesso === true) {
            if (clienteVinculado === usuario.id) {
              setErroTransferir("Ops... Você não pode transferir para si mesmo.");
            } else {
              setCliente(cliente_res.data.resultado.nome);
              setErroTransferir("");
            }
          } else {
            setCliente("");
            setErroTransferir("Ops... Parece que esse ID não existe.");
          }
        })
        .catch((error) => {
          setErroTransferir("Ocorreu um erro, tente novamente.");
          console.debug(error);
        });
    }
  }

  function fecharModalVincular() {
    setModalTransferir(false);
    setModoTransferencia("mudar_false");

    setCliente("");
    setIDIngresso("");
    setClienteVinculado("");
  }

  async function transferir() {
    fecharModalVincular();

    const obj = {
      id_ingresso: IDIngresso,
      id_cli_emitente: usuario.id,
      id_cli_destinatario: clienteVinculado,
    };

    await api
      .post("vincular/vincular_ingresso.php", obj)
      .then((response) => {
        if (response.data.sucesso === true) {
          listar();
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  }

  // navigator.permissions.query({ name: "persistent-storage" }).then((result) => {
  //   console.log(result.state);
  // })

  // navigator.storage.persist({});

  function printDocument() {
    setBaixandoIngresso(true);
    const input = document.getElementById("divToPrint");
    // const input = document.getElementById('printto');

    html2canvas(input, {
      width: 783,
      height: 700,
      scale: 1,
      logging: true,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(`ingresso${informacoesIngressos?.id}.pdf`);
      })
      .catch(console.log)
      .finally(() => {
        setBaixandoIngresso(false);
      });
  }

  function verificarSeEMesa() {
    if (informacoesIngressos?.resMesasVinculadas?.nome) {
      return `${informacoesIngressos?.resMesasVinculadas?.nome}  ${informacoesIngressos?.resMesasVinculadas?.codigo}`;
    } else if (
      informacoesIngressos?.tipo_de_modelo_ingresso_id === "1" ||
      informacoesIngressos?.tipo_de_modelo_ingresso_id === "2" ||
      informacoesIngressos?.tipo_de_modelo_ingresso_id === "3"
    ) {
      return `${informacoesIngressos?.nome_produto}  ${informacoesIngressos?.id_produto}`;
    } else {
      return informacoesIngressos.id_tipo_ingresso;
    }
  }

  function copiarPix() {
    navigator.clipboard.writeText(informacoesIngressos?.codigo_pix);
    setCopiado(true);
  }

  const data_compra = informacoesIngressos?.data_compra_semfiltro
    ? format(
        parseISO(`${informacoesIngressos?.data_compra_semfiltro} ${informacoesIngressos?.hora_compra_semfiltro}`),
        "'Comprou no dia' dd 'de' MMMM', às 'HH:mm'h.'",
        { locale: ptBR }
      )
    : "Data";

  abrirModalInformacoesIngresso || abrirModalQRCode || modalTransferir
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  return (
    <Container>
      <Header />

      <Responsivel>
        {carregandoCompras ? (
          <ViewCarregando>
            <Skeleton variant="rectangular" width="100%" height={120} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={120} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={120} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={120} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={120} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={120} sx={{ marginTop: 1 }} />
          </ViewCarregando>
        ) : (
          <>
            <ViewCompras>
              <PagoCorView>
                <ExemploView>
                  <CorExemplo style={{ backgroundColor: "green" }} />
                  <TextoExemplo>Pago</TextoExemplo>
                </ExemploView>

                <ExemploView>
                  <CorExemplo style={{ backgroundColor: "red" }} />
                  <TextoExemplo>Não pago</TextoExemplo>
                </ExemploView>

                <ExemploView>
                  <CorExemplo style={{ backgroundColor: "blue" }} />
                  <TextoExemplo>Lib. reembolso</TextoExemplo>
                </ExemploView>

                <ExemploView>
                  <CorExemplo style={{ backgroundColor: "yellow" }} />
                  <TextoExemplo>Canc. / Reemb.</TextoExemplo>
                </ExemploView>
              </PagoCorView>

              {dados?.map((item, index) => (
                <Item key={item.id}>
                  <ViewStatus status={item.status} pago={item.pago} />

                  <ViewInfoInterna onClick={() => aoClicarNoIngresso(item, "info")}>
                    <IDCompra>
                      #{item.id} - {item.nome_empresa}
                    </IDCompra>
                    <br></br>
                    <NomeIngresso>{item.evento.nome}</NomeIngresso>

                    <ViewRodapeIngresso>
                      <NomeIngresso>{item.evento.data_evento}</NomeIngresso>
                      <NomeIngresso>{item.evento.hora_evento}</NomeIngresso>
                      <NomeIngresso style={{ marginRight: 25 }}>
                        {item.pago === "Sim" && item.status === "Pendente" ? "Liberado" : item.status}
                      </NomeIngresso>
                    </ViewRodapeIngresso>
                  </ViewInfoInterna>

                  {modoTransferencia !== "mudar_true" && (
                    <ViewInformacoes>
                      <ViewInfo
                        onClick={() => window.open(`http://api.whatsapp.com/send?1=pt_BR&phone=${item.celular_empresa}`, "_blank", "noreferrer")}
                      >
                        <WhatsAppIcon style={{ color: "green" }} />
                      </ViewInfo>

                      <ViewInfo onClick={() => aoClicarNoIngresso(item, "qrcode")}>
                        <QrCodeScannerIcon style={{ color: "#000" }} />
                      </ViewInfo>
                    </ViewInformacoes>
                  )}
                </Item>
              ))}

              {dados?.length <= 0 && <p>Nenhum ingresso Disponivel.</p>}

              {dados.length > 0 && (
                <BotaoTransferir style={{ backgroundColor: informacoesApp.cor_primaria }} onClick={() => mudarModoDaTela()}>
                  <TextoTransferir>
                    {modoTransferencia === "0" || modoTransferencia === "mudar_false" ? "Transferir" : "Escolha ingresso."}
                  </TextoTransferir>
                </BotaoTransferir>
              )}
            </ViewCompras>

            <Snackbar
              open={mensagemAlerta.length > 0}
              autoHideDuration={6000}
              onClose={handleClose}
              message={mensagemAlerta}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />

            <Dialog open={modalTransferir} onClose={handleCloseModalTransferir}>
              <DialogTitle>Transferir</DialogTitle>
              <DialogContent>
                <DialogContentText>Digite o id do destinátario ao qual você quer transferir</DialogContentText>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="ID do Destinátario"
                    type="number"
                    fullWidth
                    color={cliente.length > 0 && clienteVinculado.length > 0 ? "success" : ""}
                    focused
                    value={clienteVinculado}
                    onChange={(e) => {
                      setClienteVinculado(e.target.value);
                      setCliente("");
                    }}
                    error={erroTransferir.length > 0}
                    variant="standard"
                    helperText={erroTransferir}
                  />

                  <Button onClick={() => vincularCliente()}>Verificar</Button>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  disabled={cliente.length <= 0 || clienteVinculado.length <= 0}
                  onClick={transferir}
                  style={cliente.length <= 0 || clienteVinculado.length <= 0 ? {} : { color: "green" }}
                >
                  Transferir {cliente.length > 0 && clienteVinculado.length > 0 && `para ${cliente}`}
                </Button>
              </DialogActions>
            </Dialog>

            {abrirModalInformacoesIngresso && (
              <Modal
                desktopWidth={"30%"}
                mobileWidth={"85%"}
                padding={10}
                alturaDinamica={true}
                mostrarBotaoFechar={true}
                fechar={() => setAbrirModalInformacoesIngresso(false)}
              >
                <ViewModal>
                  <Titulo>Detalhes do seu Ingresso.</Titulo>
                  <ViewInformacoesInterna>
                    <ViewInfo1>
                      <InfoNomeEvento>{informacoesIngressos.evento.nome}</InfoNomeEvento>
                      <InfoDataCompra>{informacoesIngressos.data_compra}</InfoDataCompra>
                      <InfoHoraCompra>{informacoesIngressos.hora_compra}</InfoHoraCompra>
                    </ViewInfo1>

                    <ViewValores>
                      <Valores>
                        <span>Valor Ingresso </span>
                        <span style={{ color: "green" }}>R$ {Number(informacoesIngressos.valor_ingresso).toFixed(2)}</span>
                      </Valores>

                      <Valores>
                        <span>Valor Taxa</span>
                        <span style={{ color: "green" }}>R$ {Number(informacoesIngressos.valor_taxa).toFixed(2)}</span>
                      </Valores>

                      <Valores>
                        <span>Valor Desconto</span>
                        <span style={{ color: "green" }}>R$ {Number(informacoesIngressos.valor_desconto).toFixed(2)}</span>
                      </Valores>

                      <Valores>
                        <span>Valor Total</span>
                        <span style={{ color: "green" }}>R$ {Number(informacoesIngressos.valor_total_compras).toFixed(2)}</span>
                      </Valores>
                    </ViewValores>

                    <ViewInfo2>
                      <InfoTipoIngresso>{informacoesIngressos.id_tipo_ingresso}</InfoTipoIngresso>
                      <InfoTipoIngresso>{informacoesIngressos.id_forma_pagamento}</InfoTipoIngresso>
                      {informacoesIngressos?.meia_entrada === "Sim" && <InfoTipoIngresso>Meia Entrada</InfoTipoIngresso>}
                    </ViewInfo2>

                    <ViewInfo2>
                      {(informacoesIngressos?.tipo_de_modelo_ingresso_id === "1" ||
                        informacoesIngressos?.tipo_de_modelo_ingresso_id === "2" ||
                        informacoesIngressos?.tipo_de_modelo_ingresso_id === "3") && (
                        <>
                          <InfoLugar>Lugar Reservado</InfoLugar>
                          <InfoLugar style={{ color: "blue" }}>
                            {informacoesIngressos?.nome_produto} {informacoesIngressos?.id_produto}
                          </InfoLugar>
                          <InfoLugar style={{ color: "blue" }}>{informacoesIngressos?.quant_lugares} lugares</InfoLugar>
                        </>
                      )}

                      {informacoesIngressos?.resMesasVinculadas?.nome && (
                        <>
                          <InfoLugar>Lugar Reservado</InfoLugar>
                          <InfoLugar style={{ color: "blue" }}>
                            {informacoesIngressos?.resMesasVinculadas?.nome} {informacoesIngressos?.resMesasVinculadas?.codigo}
                          </InfoLugar>
                          <InfoLugar style={{ color: "blue" }}>{informacoesIngressos?.resMesasVinculadas?.quant_lugares} lugares</InfoLugar>
                        </>
                      )}
                    </ViewInfo2>

                    <ViewInfo3>
                      <InfoDataEvento>{informacoesIngressos.evento.data_evento}</InfoDataEvento>
                      <InfoHoraEvento>{informacoesIngressos.evento.hora_evento}</InfoHoraEvento>
                      <InfoStatus>{informacoesIngressos.status}</InfoStatus>
                    </ViewInfo3>

                    {informacoesIngressos.habilitar_aviso === "Sim" && (
                      <InfoAviso style={{ fontSize: 12 }}>{informacoesIngressos.aviso ? `*${informacoesIngressos.aviso}` : ""}</InfoAviso>
                    )}
                  </ViewInformacoesInterna>

                  <ImportanteView>
                    <InfoImportante>IMPORTANTE: </InfoImportante>
                    <InfoImportante>• Em caso de cópias do e-Ticket, o sistema de segurança validará apenas o primeiro acesso.</InfoImportante>
                    <InfoImportante>• Após validados uma vez, o e-Ticket não poderá ser reutilizado.</InfoImportante>
                    <InfoImportante>• Não nos responsabilizamos por qualquer PERDA, DANO ou ROUBO do seu e-Ticket.</InfoImportante>
                    <InfoImportante>
                      • No dia do evento, apresente-o diretamente na entrada do evento, juntamente com documento com foto: CNH ou RG (original ou
                      cópia autenticada).
                    </InfoImportante>

                    {informacoesIngressos.meia_entrada === "Sim" && (
                      <InfoImportante>• Obrigátorio comprovação de documento para a Meia-entrada.</InfoImportante>
                    )}
                  </ImportanteView>
                </ViewModal>
              </Modal>
            )}

            {abrirModalQRCode && (
              <Modal
                desktopWidth={"23%"}
                mobileWidth={"85%"}
                padding={10}
                alturaDinamica={true}
                mostrarBotaoFechar={informacoesIngressos?.pago === "Não" ? true : false}
                fechar={() => setAbrirModalQRCode(false)}
              >
                <ViewModalQrcode id="printto">
                  {informacoesIngressos.ja_aconteceu ? (
                    <p>Já Aconteceu</p>
                  ) : informacoesIngressos.pago === "Sim" ? (
                    <>
                      <QRCode
                        size={200}
                        style={{ height: 200, width: 200, marginTop: 40 }}
                        value={String(informacoesIngressos.codigo_qrcode)}
                        viewBox={`0 0 200 200`}
                      />

                      <ViewQRCodeInfo1>
                        <ViewIDCliente>
                          <PersonIcon fontSize="small" style={{ marginRight: 5 }} />
                          <span>{informacoesIngressos.id_cliente}</span>
                        </ViewIDCliente>

                        <ViewIDIngresso>
                          <ConfirmationNumberIcon fontSize="small" style={{ marginRight: 5 }} />
                          <span>{informacoesIngressos.id}</span>
                        </ViewIDIngresso>
                      </ViewQRCodeInfo1>

                      <span>{informacoesIngressos.id_tipo_ingresso}</span>

                      <Middle>
                        <CircleLeft />
                        <LinhaSerrilhado />
                        <CircleRight />
                      </Middle>

                      <ViewQRCodeInfo2>
                        <ViewInternoQRCode>
                          <ViewInfoUsuario>
                            <NomeUsuario>{usuario?.nome}</NomeUsuario>
                            <CPFUsuario>{usuario?.cpf ? usuario.cpf : "Sem CPF"}</CPFUsuario>
                          </ViewInfoUsuario>

                          <NomeEvento>{informacoesIngressos.evento.nome}</NomeEvento>

                          <ViewInfos>
                            <ViewDataEvento>
                              <span>Data</span>
                              <span style={{ color: "#999" }}>{informacoesIngressos.evento.data_evento}</span>
                            </ViewDataEvento>

                            <ViewHoraEvento>
                              <span>Hora Início</span>
                              <span style={{ color: "#999" }}>{informacoesIngressos.evento.hora_evento}</span>
                            </ViewHoraEvento>
                          </ViewInfos>

                          <ViewInfos2>
                            <ViewInfoHora>
                              <AccessTimeIcon />
                              <span style={{ color: "#999", marginLeft: 5 }}>{horaAtual}</span>
                            </ViewInfoHora>
                            <span style={{ color: "#999" }}>{informacoesIngressos.status}</span>
                          </ViewInfos2>

                          <ViewBotoesQRCode>
                            <BotaoQRCode style={{ backgroundColor: "#999" }} onClick={() => setAbrirModalQRCode(false)}>
                              <TextoBotaoQR>Fechar</TextoBotaoQR>
                            </BotaoQRCode>

                            <BotaoQRCode
                              style={{
                                backgroundColor: informacoesApp.cor_primaria,
                              }}
                              onClick={() => printDocument()}
                            >
                              {baixandoIngresso ? <CircularProgress /> : <TextoBotaoQR>Baixar Ingresso</TextoBotaoQR>}
                            </BotaoQRCode>
                          </ViewBotoesQRCode>
                        </ViewInternoQRCode>
                      </ViewQRCodeInfo2>
                    </>
                  ) : informacoesIngressos.codigo_pix ? (
                    <>
                      <TitleModalInfo style={{ marginTop: 10 }}>Seu QRCode de pagamento.</TitleModalInfo>

                      <QRCode
                        size={256}
                        style={{ height: 256, width: 256 }}
                        value={String(informacoesIngressos.codigo_pix)}
                        viewBox={`0 0 256 256`}
                      />

                      {/* <TextoPix>{informacoesIngressos?.codigo_pix}</TextoPix> */}

                      <BotaoCopiarPIX onClick={copiarPix}>
                        <TextoCopiarPIX>{copiado ? "COPIADO" : "COPIAR CÓDIGO PIX"}</TextoCopiarPIX>
                      </BotaoCopiarPIX>

                      <ViewCancelamento>
                        <TempoDeCancel>
                          O pagamento do ingresso deve ser realizado em até
                          {"\n"}
                          <TempoDeCancel style={{ color: "blue", fontSize: 20 }}>{horas[informacoesIngressos?.tempo_cancel]}</TempoDeCancel>. Após
                          isso, o pedido será {"\n"}
                          <TempoDeCancel style={{ color: "red", fontSize: 20 }}>cancelado automaticamente</TempoDeCancel>.
                        </TempoDeCancel>
                      </ViewCancelamento>
                    </>
                  ) : informacoesIngressos.status === "Cancelado" ? (
                    <p>Cancelado</p>
                  ) : (
                    <p>Você ainda não pagou pelo ingresso.</p>
                  )}
                </ViewModalQrcode>
              </Modal>
            )}

            <div
              id="divToPrint"
              style={{
                position: "absolute",
                left: -5000,
                borderWidth: 2,
                borderColor: "rgb(143, 143, 143)",
                borderStyle: "solid",
              }}
            >
              <Cabecalho>
                <Logo>
                  <img width="150" height="100" src={logo_app_caminho(url_site, informacoesApp.logo_app_ingresso)} alt="img" />
                </Logo>

                <Codigo>
                  <p style={{ fontSize: 30, fontWeight: "bold" }}>{`${informacoesIngressos.id}.${Math.abs(
                    Math.floor(Math.random() * (10000000 - 90000000)) + 10000000
                  )}`}</p>
                </Codigo>
              </Cabecalho>

              <Info
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 5,
                }}
              >
                <div style={{ width: "50%", paddingTop: 0 }}>
                  {informacoesIngressos?.plano ? (
                    <>
                      <p style={{ height: 7, margin: 0, padding: 0 }}>Plano</p>
                      <p
                        style={{
                          height: 7,
                          margin: 0,
                          paddingTop: 15,
                          paddingBottom: 10,
                        }}
                      >
                        {informacoesIngressos?.evento?.data_evento} - {informacoesIngressos?.evento?.nome}
                      </p>
                    </>
                  ) : (
                    <>
                      <p style={{ height: 7, margin: 0, padding: 0 }}>Evento</p>
                      <p
                        style={{
                          height: 7,
                          margin: 0,
                          paddingTop: 15,
                          paddingBottom: 10,
                        }}
                      >
                        {informacoesIngressos?.evento?.data_evento} - {informacoesIngressos?.evento?.nome}
                      </p>
                    </>
                  )}
                </div>

                <div
                  style={{
                    width: "50%",
                    borderLeftWidth: 2,
                    borderLeftColor: "rgb(143, 143, 143)",
                    borderLeftStyle: "solid",
                    paddingLeft: 10,
                    padding: 7,
                  }}
                >
                  <p style={{ height: 7, margin: 0, padding: 0, marginTop: -7 }}>Ingresso</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {informacoesIngressos?.meia_entrada === "Sim" ? (
                      <p
                        style={{
                          height: 7,
                          margin: 0,
                          paddingTop: 15,
                          paddingBottom: 10,
                        }}
                      >
                        {verificarSeEMesa()} - Meia Entrada
                      </p>
                    ) : (
                      <p
                        style={{
                          height: 7,
                          margin: 0,
                          paddingTop: 15,
                          paddingBottom: 10,
                        }}
                      >
                        {verificarSeEMesa()}
                      </p>
                    )}
                    {informacoesIngressos?.quant_lugares === "0" ? (
                      ""
                    ) : (
                      <p
                        style={{
                          height: 7,
                          margin: 0,
                          paddingTop: 15,
                          paddingBottom: 10,
                        }}
                      >
                        {informacoesIngressos?.quant_lugares} lugares
                      </p>
                    )}
                  </div>
                </div>
              </Info>

              <Info style={{ padding: 7, paddingLeft: 10, position: "relative" }}>
                <p style={{ height: 7, margin: 0, padding: 0 }}>Comprador</p>
                <p
                  style={{
                    fontSize: 14,
                    textAlign: "end",
                    position: "absolute",
                    right: 10,
                    bottom: -10,
                  }}
                >
                  {data_compra}
                </p>
                <p
                  style={{
                    height: 7,
                    margin: 0,
                    paddingTop: 15,
                    paddingBottom: 10,
                  }}
                >
                  {usuario?.nome ?? "Sem Nome"}
                </p>
                <p
                  style={{
                    height: 7,
                    margin: 0,
                    paddingTop: 5,
                    paddingBottom: 15,
                  }}
                >
                  {usuario?.cpf ?? "Sem CPF"}
                </p>
                <p
                  style={{
                    height: 7,
                    margin: 0,
                    paddingTop: 0,
                    paddingBottom: 15,
                  }}
                >
                  {usuario?.celular ?? "Sem Celular"}
                </p>
              </Info>

              <Info style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "50%" }}>
                  <Wrap>
                    <p>Valor ingresso</p>
                    <p>R$ {Number(informacoesIngressos?.valor_ingresso).toFixed(2).replace(".", ",")}</p>
                  </Wrap>
                  <Wrap>
                    <p>Valor desconto</p>
                    <p>- R$ {Number(informacoesIngressos?.valor_desconto).toFixed(2).replace(".", ",")}</p>
                  </Wrap>
                  <Wrap>
                    <p>Valor taxa</p>
                    <p>+ R$ {Number(informacoesIngressos?.valor_taxa).toFixed(2).replace(".", ",")}</p>
                  </Wrap>
                  <Wrap>
                    <p>Valor total</p>
                    <p>R$ {Number(informacoesIngressos?.valor_total_compras).toFixed(2).replace(".", ",")}</p>
                  </Wrap>
                </div>

                <Qrcode style={{ width: "50%" }}>
                  <QRCode size={120} style={{ height: 120, width: 120 }} value={String(informacoesIngressos.codigo_qrcode)} viewBox={`0 0 120 120`} />
                  {/* <img width="100" height="100" src={`data:image/png;base64,${informacoesIngressos?.qrcode}`} alt="ol" /> */}
                </Qrcode>
              </Info>

              <Importante>
                <span style={{ fontWeight: "bold" }}>IMPORTANTE:</span>
                <ul style={{ marginTop: -2 }}>
                  <li style={{ fontSize: 12 }}>Em caso de cópias do e-Ticket, o sistema de segurança validará apenas o primeiro acesso.</li>
                  <li style={{ fontSize: 12 }}>Após validados uma vez, o e-Ticket não poderá ser reutilizado.</li>
                  <li style={{ fontSize: 12 }}>Não nos responsabilizamos por qualquer PERDA, DANO ou ROUBO do seu e-Ticket.</li>
                  <li style={{ fontSize: 12 }}>
                    No dia do evento, apresente-o diretamente na entrada do evento, juntamente com documento com foto: CNH ou RG (original ou cópia
                    autenticada).
                  </li>
                  {informacoesIngressos?.meia_entrada === "Sim" && (
                    <li style={{ fontSize: 12 }}>Obrigátorio comprovação de documento para a Meia-entrada.</li>
                  )}
                </ul>
              </Importante>
            </div>
          </>
        )}
      </Responsivel>
    </Container>
  );
}
