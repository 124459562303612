/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, IconButton, Skeleton, Snackbar } from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { horas } from "../../utils/horas";
import { cartao_elo, cartoes, formatCVC, formatCreditCardNumber, formatExpirationDate, formatExpirationDate4 } from "../../utils/verificacoes";
import { ViewCarregando } from "../Home/styles";

import {
  AlinharInput,
  AlinharInput2,
  BotaoAplicarCupom,
  BotaoConcluir,
  BotaoCopiarPIX,
  BotaoPagarCartao,
  BotaoVerificarPagamento,
  Botoes,
  Card,
  Container,
  DivInter,
  Input,
  InputCupom,
  NomeParcela,
  Preco,
  Precos,
  TempoDeCancel,
  TextModal,
  TextTempoCancel,
  TextVermelho,
  Texto,
  TextoAceitarTermos,
  TextoAplicarCupom,
  TextoConcluir,
  TextoCopiarPIX,
  TextoSucesso,
  TextoVerificarPix,
  Titulo,
  TituloMetodoPagamento,
  TituloPIX,
  ValorParcela,
  ViewAceitarTermos,
  ViewCancelamento,
  ViewContainer,
  ViewInputCupom,
  ViewInputs,
  ViewModal,
  ViewPagamentos,
  ViewPix,
  ViewPreco,
  ViewPrecoConcluir,
  ViewSucesso,
  ViewTermos,
  ViewTotal,
} from "./styles";

import CloseIcon from "@mui/icons-material/Close";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import PixOutlinedIcon from "@mui/icons-material/PixOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Checkbox from "@mui/material/Checkbox";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import axios from "axios";
import Lottie from "lottie-react";
import Payment from "payment";
import Cards from "react-credit-cards";
import QRCode from "react-qr-code";
import secureLocalStorage from "react-secure-storage";
import Sucesso from "../../animacoes/sucesso.json";
import Header from "../../componentes/Header";
import Modal from "../../componentes/Modal";
import Responsivel from "../../componentes/Responsivel";
import api from "../../servicos/api";

export default function Compra() {
  const { usuario, informacoesApp, carregando } = useContext(AuthContext);
  const { evento, produto, idpdv } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [pagamento, setPagamento] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [cupom, setCupom] = useState("");
  const [cvc, setCvc] = useState("");
  const [numeroCartao, setNumeroCartao] = useState("");
  const [nomeCartao, setNomeCartao] = useState("");
  const [expiracao, setExpiracao] = useState("");
  const [focado, setFocado] = useState("");
  const [copiado, setCopiado] = useState(false);
  const [abrirModal, setAbrirModal] = useState(false);
  const [abrirModalTermos, setAbrirModalTermos] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [salvando, setSalvando] = useState(false);
  const [modalCartao, setModalCartao] = useState(false);
  const [aceitouTermos, setAceitouTermos] = useState(false);
  const [dadosPagamento, setDadosPagamento] = useState({});
  const [dados, setDados] = useState([]);
  const [mensagemDialogo, setMensagemDialogo] = useState("");
  const [abrirMensagemDialogo, setAbrirMensagemDialogo] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState("");
  const [carregandoCompra, setCarregandoCompra] = useState(true);
  const [verificandoPagamento, setVerificandoPagamento] = useState(false);
  const [tipoCartao, setTipoCartao] = useState("");
  const [parcelasCartao, setParcelasCartao] = useState(1);
  const [bandeiras, setBandeiras] = useState([]);
  const [bandeira, setBandeira] = useState({});

  const quantidade = location?.state?.quantidade ?? 1;
  const id_usuario = secureLocalStorage.getItem("cliente");

  useEffect(() => {
    listar();
    listarBandeiras();
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [carregando]);

  useEffect(() => {
    if (abrirModal === true && !sucesso && (pagamento === 1 || pagamento === 9 || pagamento === 11 || pagamento === 14)) {
      const intervalId = setInterval(() => {
        verificarPagamento(true);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [abrirModal, sucesso, dadosPagamento]);

  async function listar() {
    if (!id_usuario) {
      navigate("/");
    }

    await api
      .get(`compra/listar.php?evento=${evento}&produto=${produto}&cliente=${id_usuario}`)
      .then((response) => {
        setDados(response.data);
        setDesconto(Number(response.data?.ingresso?.valor_desconto));
      })
      .catch(console.log)
      .finally(() => {
        setCarregandoCompra(false);
      });
  }

  async function listarBandeiras() {
    await axios
      .get(
        "https://api.mercadopago.com/v1/payment_methods/search?public_key=&locale=pt-BR&js_version=2.0.0&referer=http%3A%2F%2F192.168.2.107&marketplace=NONE&status=active&limit=1000&public_key=APP_USR-7119aaff-31ec-473a-baf7-e83dc9003787#json"
      )
      .then((r) => {
        console.log(r);
        setBandeiras(r.data.results);
      });
  }

  function pegarIdadeUsuario() {
    if (id_usuario) {
      const hoje = new Date();

      const data_nascimento_usuario = new Date(usuario?.data_nascimento);

      const idade = Math.floor(Math.ceil(Math.abs(data_nascimento_usuario.getTime() - hoje.getTime()) / (1000 * 3600 * 24)) / 365.25);

      // CASO PERMITIR MENOR NÃO FOR UM NÚMERO IRÁ VERIFICAR SE É MENOR QUE 17 ANOS
      if (isNaN(dados?.permitir_menor)) {
        if (idade <= 17) {
          return false;
        } else {
          return true;
        }
        // CASO PERMITIR MENOR FOR UM NÚMERO IRÁ VERIFICAR COM O VALOR PASSADO PELA EMPRESA
      } else if (idade < Number(dados?.permitir_menor)) {
        return false;
      } else {
        return true;
      }
    }
  }

  async function concluir(e) {
    e.preventDefault();

    if (dados?.evento?.usuario_jacomprou === true) {
      setMensagemAlerta("Já comprou o máximo de ingressos desse Evento.");
      return;
    }

    if (!usuario.id) {
      setMensagemAlerta(`Para continuar, você precisa fazer o cadastro no ${informacoesApp.nome_app}.`);
      return;
    } else if (dados?.evento?.obrigar_sexo === "Sim" && usuario.sexo === "nulo") {
      setAbrirMensagemDialogo(true);
      setMensagemDialogo("Para continuar, você precisa selecionar o seu gênero.");
      return;
    } else if (String(dados?.ingresso?.valor) === "0.00" && dados?.ingresso?.id_tipo_de_ingresso !== 7) {
      setMensagemAlerta("Ingresso não disponível para venda.");
      return;
    } else if (dados?.empresa?.cliente_e_bloqueado) {
      setMensagemAlerta("Acesso negado, nesta empresa por forças maiores.");
      return;
    } else if (dados?.evento?.ativo === "Não") {
      setMensagemAlerta("Ingresso não disponível para venda.");
      return;
    } else if (!pegarIdadeUsuario()) {
      setMensagemAlerta("Menores de idade não podem fazer compra nessa empresa.");
      return;
    } else if (dados?.ingresso?.quantidade === "0" || dados?.ingresso?.quantidade === 0) {
      setMensagemAlerta("Quantidade de ingresso disponível está esgotado.");
      return;
    } else if (usuario.celular === "" || !usuario.celular) {
      setAbrirMensagemDialogo(true);
      setMensagemDialogo("Você precisa cadastrar o seu Celular para continuar a compra.");
      return;
    } else if (usuario.cpf === "" || !usuario.cpf) {
      setAbrirMensagemDialogo(true);
      setMensagemDialogo("Você precisa cadastrar o seu CPF para continuar a compra.");
      return;
    }

    setSalvando(true);
    setAbrirModal(true);
    window.scrollTo({ top: 0, behavior: "instant" });

    const exp = expiracao.split("/");

    const obj = {
      liberacao_cons_reser: dados?.ingresso?.modelo_ingresso === "1" ? 0 : dados?.ingresso?.tipo_de_reserva_consu,
      quantidade_original: quantidade,
      id_forma_pagamento: pagamento,
      desconto_aplicado: dados?.ingresso?.desconto_aplicado,
      id_tipo_ingresso: dados?.ingresso?.dados?.id_tipo_de_ingresso,
      cupom_desconto: cupom,
      codigo_qrcode: 0,
      tipo_de_venda: 0,
      id_modelo_pagamento: 0,
      id_logs_view: "",
      meia_entrada: "",
      data_evento: dados.evento.data_evento,
      id_cliente: id_usuario,
      plataforma: "Site",
      quantidade,
      codigo_pix: "",
      id_evento: evento,
      empresa: dados.empresa.id,
      pago: "Não",
      txid: "",
      dias: dados?.ingresso?.eventosPassaporte ? Number(dados?.ingresso?.eventosPassaporte[0]?.dias) : 0,
      // valores
      tipo_desconto: 0,
      valor_desconto: desconto,
      numero_lote: dados?.ingresso?.qual_lote ?? 0,
      valor_ingresso: Number(String(dados?.ingresso?.valor).replace(",", "")),
      valor_taxa: valorTaxa(),
      SubTotal: valorTotal(),
      tipo_de_modelo_ingresso: dados?.ingresso?.modelo_ingresso ? dados?.ingresso?.modelo_ingresso : 0,
      quant_lugares: dados?.ingresso?.dados?.lugares,
      id_produto: dados?.ingresso?.dados?.id,
      // cartoes
      numeroCartao: numeroCartao.replace(/\s/g, ""),
      mes_expiracao: exp[0],
      ano_expiracao: exp[1],
      cvc: cvc,
      nomeCartao: nomeCartao,
      tipoCartao: pagamento === 4 ? "credito" : tipoCartao,
      parcelasCartao: parcelasCartao,
      email: usuario.email,
      cpf: usuario.cpf,
      usuario: usuario,
      bandeira: bandeira,
      // cpf: '07785533105',
      valor: valorTotal(),
      idpdv,
      // valor: 0.60,
    };

    console.log("campos", obj);
    // return;

    if (pagamento === 10) {
      await api
        .post("pagamentos/mercadopago/gerarLinkPagamento.php", obj)
        .then(async (response) => {
          if (response.data.link) {
            // setLinkPagamentoMercadoPago(response.data.link);

            window.open(response.data.link, "_self");
          }
        })
        .finally(() => {
          setSalvando(false);
        });

      return;
    }

    await api.post("venda/concluir.php", obj).then((response) => {
      console.log(response.data);
      if (response.data.sucesso) {
        // console.log('deu certo');
        if (pagamento !== 1 && pagamento !== 9 && pagamento !== 11 && pagamento !== 14) {
          setSucesso(true);
        }

        setAbrirModal(true);
        setDadosPagamento(response.data);
      } else {
        setMensagemAlerta(response.data.mensagem);
        setAbrirModal(false);
      }

      setSalvando(false);
    });
  }

  function pegarFocado(e) {
    setFocado(e.target.name);
  }

  function aoMudar({ target }) {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      setNumeroCartao(target.value);

      // console.log(target.value)

      if (target.value.length > 0) {
        // if (Payment.fns.cardType(target.value) === 'mastercard' || Payment.fns.cardType(target.value) === 'maestro') {
        //   setTipoCartao('master');
        // } else {
        //   setTipoCartao(Payment.fns.cardType(target.value));
        // }

        if (pagamento === 12) {
          if (Payment.fns.cardType(target.value) === "mastercard" || Payment.fns.cardType(target.value) === "maestro") {
            setBandeira(
              bandeiras.filter((r) => {
                return r.id === "master";
              })[0]
            );
          } else {
            setBandeira(
              bandeiras.filter((r) => {
                return r.id === Payment.fns.cardType(target.value);
              })[0]
            );
          }
        } else {
          if (Payment.fns.cardType(target.value) === "elo") {
            setBandeira(cartao_elo);
          }
        }
      }
    } else if (target.name === "expiry") {
      if (pagamento === 4) {
        target.value = formatExpirationDate4(target.value);
        setExpiracao(target.value);
      } else {
        target.value = formatExpirationDate(target.value);
        setExpiracao(target.value);
      }
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
      setCvc(target.value);
    } else {
      setNomeCartao(target.value);
    }
  }

  function aoMudarTermos({ target }) {
    setAceitouTermos(target.checked);
  }

  function aoMudarPagamento(event, value) {
    setPagamento(value ?? 0);
  }

  function copiarPix() {
    navigator.clipboard.writeText(dadosPagamento?.pix);
    setCopiado(true);
  }

  function voltar() {
    navigate("/ingressos");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMensagemAlerta("");
  };

  const handleCloseModalTransferir = () => {
    setAbrirMensagemDialogo(false);
  };

  async function verificarPagamento(naoCarregar) {
    if (naoCarregar !== true) {
      setVerificandoPagamento(true);
    }

    const id_ingresso = dadosPagamento?.codigo_compra;

    if (pagamento === 1) {
      if (dados?.empresa?.geracao_de_pix === "1") {
        await api
          .post("pagamentos/pix_bb/verificarpagamento.php", { id_ingresso })
          .then((response) => {
            if (response.data.sucesso) {
              setSucesso(true);
            }
          })
          .catch(console.log)
          .finally(() => {
            setVerificandoPagamento(false);
          });
      } else if (dados?.empresa?.geracao_de_pix === "2") {
        await api
          .post("pagamentos/pix/verificarpagamento.php", { id_ingresso })
          .then((response) => {
            if (response.data.sucesso) {
              setSucesso(true);
            }
          })
          .catch(console.log)
          .finally(() => {
            setVerificandoPagamento(false);
          });
      } else if (dados?.empresa?.geracao_de_pix === "3") {
        await api
          .post("pagamentos/pix_inter/verificarpagamento.php", { id_ingresso })
          .then((response) => {
            if (response.data.sucesso) {
              setSucesso(true);
            }
          })
          .catch(console.log)
          .finally(() => {
            setVerificandoPagamento(false);
          });
      } else if (dados?.empresa?.geracao_de_pix === "4") {
        await api
          .post("pagamentos/mercadopago/verificarPix.php", { id_ingresso })
          .then((response) => {
            if (response.data.sucesso) {
              setSucesso(true);
            }
          })
          .catch(console.log)
          .finally(() => {
            setVerificandoPagamento(false);
          });
      }
    } else if (pagamento === 9) {
      await api
        .post("pagamentos/pix_bb/verificarpagamento.php", { id_ingresso })
        .then((response) => {
          if (response.data.sucesso) {
            setSucesso(true);
          }
        })
        .catch(console.log)
        .finally(() => {
          setVerificandoPagamento(false);
        });
    } else if (pagamento === 11) {
      await api
        .post("pagamentos/pix_inter/verificarpagamento.php", { id_ingresso })
        .then((response) => {
          if (response.data.sucesso) {
            setSucesso(true);
          }
        })
        .catch(console.log)
        .finally(() => {
          setVerificandoPagamento(false);
        });
    } else if (pagamento === 14) {
      await api
        .post("pagamentos/mercadopago/verificarPix.php", { id_ingresso })
        .then((response) => {
          if (response.data.sucesso) {
            setSucesso(true);
          }
        })
        .catch(console.log)
        .finally(() => {
          setVerificandoPagamento(false);
        });
    }
  }

  function enviarMensagem() {
    window.open(`http://api.whatsapp.com/send?1=pt_BR&phone=${dados?.empresa?.celular}`, "_blank", "noreferrer");
  }

  function valorTaxa1() {
    if (Number(dados?.ingresso?.dados?.lugares) > 0) {
      return Number(dados?.empresa?.taxa_admin) * Number(dados?.ingresso?.dados?.lugares);
    } else {
      return Number(dados?.empresa?.taxa_admin) * quantidade;
    }
  }

  function valorTotal1() {
    return Number(valorSubTotal()) + valorTaxa1();
  }

  function valorTaxa() {
    if (pagamento === 4 || pagamento === 8 || pagamento === 10 || pagamento === 12 || pagamento === 13) {
      return (dados?.empresa?.perc_de_add_cart_cred ? (Number(valorTotal1()) * Number(dados?.empresa?.perc_de_add_cart_cred)) / 100 : 0) + valorTaxa1();
    } else {
      return valorTaxa1();
    }
  }

  function valorSubTotal() {
    if (Number(dados?.ingresso?.dados?.lugares) > 0 && dados?.ingresso?.forma_valor === "Variavel") {
      return String(dados?.ingresso?.valor).replace(",", "") * Number(dados?.ingresso?.dados?.lugares);
    } else {
      return String(dados?.ingresso?.valor).replace(",", "") * quantidade;
    }
  }

  function valorTotal() {
    return valorSubTotal() + valorTaxa();
    // return 0.5;
  }

  const cardToken = async () => {
    const exp = expiracao.split("/");

    const campos = {
      numeroCartao: numeroCartao.replace(/\s/g, ""),
      mes_expiracao: exp[0],
      ano_expiracao: exp[1],
      cvc: cvc,
      nomeCartao: nomeCartao,
      tipoCartao: tipoCartao,
      parcelasCartao: cartoes.filter((r) => r.id === tipoCartao)[0].payment_type_id === "credit_card" ? parcelasCartao : 1,
      email: usuario.email,
      cpf: usuario.cpf,
      valor: valorTotal(),

      empresa: dados.empresa.id,
      id_produto: produto,
    };

    await api
      .post("pagamentos/mercadopago/cartaoManual.php", campos)
      .then((response) => {
        // console.log(response.data);
      })
      .catch(console.log);
  };

  const renderItemParcelas = (i) => {
    const parcela = i + 1;
    const valor_ = String(parcela === 1 ? "Crédito a vista" : (valorTotal() / parcela).toFixed(2)).split(".");
    const valorTotal_ = String(valorTotal().toFixed(2)).split(".");

    return (
      <Card ativo={parcelasCartao === parcela} onClick={() => setParcelasCartao(parcela)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NomeParcela>
            {parcela === 1 ? "" : `${parcela}x de `}
            {valor_[0]}
            {parcela === 1 ? "" : ","}
            <NomeParcela style={{ fontSize: 9 }}>{valor_[1]}</NomeParcela>
          </NomeParcela>
          <ValorParcela>
            R$ {valorTotal_[0]},<ValorParcela style={{ fontSize: 13 }}>{valorTotal_[1]}</ValorParcela>
          </ValorParcela>
        </div>
      </Card>
    );
  };

  const tempo_de_cancelamento = horas[dados?.empresa?.tempo_cancel];

  abrirModal || abrirModalTermos || modalCartao ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "auto");

  return (
    <Container>
      <Header />

      <Responsivel>
        {carregandoCompra ? (
          <ViewCarregando>
            <Skeleton variant="rectangular" width="100%" height={120} />
            <Skeleton variant="rectangular" width="100%" height={60} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={200} sx={{ marginTop: 1, position: "absolute", bottom: 80 }} />
            <Skeleton variant="rectangular" width="100%" height={60} sx={{ marginTop: 1, position: "absolute", bottom: 10 }} />
          </ViewCarregando>
        ) : (
          <>
            <ViewContainer>
              <TituloMetodoPagamento>Método de Pagamento</TituloMetodoPagamento>
              <ViewPagamentos>
                <ToggleButtonGroup color="primary" value={pagamento} exclusive size="medium" onChange={aoMudarPagamento} aria-label="Forma de Pagamento">
                  {dados?.empresa?.tipo_pagamento?.status_libera_online && (
                    <ToggleButton sx={{ borderRadius: 10, fontSize: "0.7em" }} value={1}>
                      <PixOutlinedIcon style={{ marginRight: 5 }} />
                      {dados?.empresa?.pagamentos_disponiveis?.primeiro}
                    </ToggleButton>
                  )}

                  {dados?.empresa?.tipo_pagamento?.status_libera_cartao && (
                    <ToggleButton sx={{ borderRadius: 10, fontSize: "0.7em" }} value={4}>
                      <CreditCardOutlinedIcon style={{ marginRight: 5 }} />
                      {dados?.empresa?.pagamentos_disponiveis?.decimo}
                    </ToggleButton>
                  )}

                  {dados?.empresa?.tipo_pagamento?.status_libera_whats && (
                    <ToggleButton sx={{ borderRadius: 10, fontSize: "0.7em" }} value={3}>
                      <WhatsAppIcon style={{ marginRight: 5 }} />
                      {dados?.empresa?.pagamentos_disponiveis?.terceiro}
                    </ToggleButton>
                  )}

                  {dados?.empresa?.tipo_pagamento?.status_libera_bancodobrasil_pix && (
                    <ToggleButton sx={{ borderRadius: 10, fontSize: "0.7em" }} value={9}>
                      <PixOutlinedIcon style={{ marginRight: 5 }} />
                      {dados?.empresa?.pagamentos_disponiveis?.quarto}
                    </ToggleButton>
                  )}

                  {dados?.empresa?.tipo_pagamento?.status_libera_mercadopago && (
                    <ToggleButton sx={{ borderRadius: 10, fontSize: "0.7em" }} value={10}>
                      <LocalGroceryStoreOutlinedIcon style={{ marginRight: 5 }} />
                      {dados?.empresa?.pagamentos_disponiveis?.quinto}
                    </ToggleButton>
                  )}

                  {dados?.empresa?.tipo_pagamento?.status_libera_inter_pix && (
                    <ToggleButton sx={{ borderRadius: 10, fontSize: "0.7em" }} value={11}>
                      <PixOutlinedIcon style={{ marginRight: 5 }} />
                      {dados?.empresa?.pagamentos_disponiveis?.sexto}
                    </ToggleButton>
                  )}

                  {dados?.empresa?.tipo_pagamento?.status_libera_mercado_pago_cc_trans && (
                    <ToggleButton sx={{ borderRadius: 10, fontSize: "0.7em" }} value={12}>
                      <CreditCardOutlinedIcon style={{ marginRight: 5 }} />
                      {dados?.empresa?.pagamentos_disponiveis?.setimo}
                    </ToggleButton>
                  )}

                  {dados?.empresa?.tipo_pagamento?.status_libera_mercado_pago_cd_trans && (
                    <ToggleButton sx={{ borderRadius: 10, fontSize: "0.7em" }} value={13}>
                      <CreditCardOutlinedIcon style={{ marginRight: 5 }} />
                      {dados?.empresa?.pagamentos_disponiveis?.oitavo}
                    </ToggleButton>
                  )}

                  {dados?.empresa?.tipo_pagamento?.status_libera_mercado_pago_pix && (
                    <ToggleButton sx={{ borderRadius: 10, fontSize: "0.7em" }} value={14}>
                      <CreditCardOutlinedIcon style={{ marginRight: 5 }} />
                      {dados?.empresa?.pagamentos_disponiveis?.nono}
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
              </ViewPagamentos>

              <ViewInputCupom>
                <InputCupom value={cupom} onChange={(e) => setCupom(e.target.value)} placeholder="Cupom de Desconto" />

                <BotaoAplicarCupom style={{ backgroundColor: informacoesApp.cor_primaria }}>
                  <TextoAplicarCupom>Aplicar</TextoAplicarCupom>
                </BotaoAplicarCupom>
              </ViewInputCupom>

              {(pagamento === 12 || pagamento === 4) && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 8,
                    position: "relative",
                  }}
                >
                  {/* 
                    <div style={{ position: 'absolute', top: 50, zIndex: 1000 }}>
                      <Cards
                        cvc={cvc}
                        expiry={expiracao}
                        focused={focado}
                        name={nomeCartao}
                        number={numeroCartao}
                        placeholders={{ name: 'SEU NOME' }}
                        locale={{ valid: 'expiração' }}
                      />
                    </div> 
                  */}

                  <DivInter>
                    <AlinharInput2>
                      {/* 
                      <TextField style={{ width: '100%' }} SelectProps={{ displayEmpty: true }} value={tipoCartao} select label="Tipo Cartão" InputLabelProps={{ shrink: true }} onChange={(e) => setTipoCartao(e.target.value)}>
                        <MenuItem value="">Selecione um tipo</MenuItem>
                          {cartoes?.map(({ id, name, secure_thumbnail }, index) => (
                            <MenuItem key={index} value={id}>
                              <img width={20} style={{ marginRight: 10 }} src={secure_thumbnail} alt="imagem cartão" />
                              {name}
                            </MenuItem>
                          ))}
                      </TextField> 
                      */}

                      {[...Array(Number(dados.empresa.cartao_de_credito_parcela))].map((x, i) => renderItemParcelas(i))}
                    </AlinharInput2>

                    <AlinharInput>
                      <Input type="tel" name="number" pattern="[\d| ]{16,22}" label="Número Cartão" required size="small" onFocus={pegarFocado} onChange={aoMudar} />
                    </AlinharInput>

                    <AlinharInput>
                      <Input type="text" name="name" label="Nome Cartão" size="small" onFocus={pegarFocado} required onChange={aoMudar} />
                    </AlinharInput>

                    <AlinharInput>
                      <Input
                        type="tel"
                        name="expiry"
                        label="Expiração"
                        onFocus={pegarFocado}
                        placeholder={pagamento === 4 ? "01/30" : "01/2030"}
                        required
                        size="small"
                        pattern={pagamento === 4 ? "dd/dd" : "dd/dddd"}
                        onChange={aoMudar}
                      />

                      <Input type="tel" name="cvc" label="Código" onFocus={pegarFocado} required size="small" pattern="\d{3,4}" onChange={aoMudar} />
                    </AlinharInput>
                  </DivInter>
                </div>
              )}

              <Precos>
                <ViewPreco>
                  <Texto>Subtotal</Texto>
                  <Preco>R$ {Number(valorSubTotal()).toFixed(2).replace(".", ",")}</Preco>
                </ViewPreco>

                {Number(dados?.empresa?.taxa_admin) ? (
                  <ViewPreco>
                    <Texto>Taxa Admin.</Texto>
                    <Preco>+ R$ {Number(valorTaxa()).toFixed(2).replace(".", ",")}</Preco>
                  </ViewPreco>
                ) : (
                  <></>
                )}

                {desconto ? (
                  <ViewPreco>
                    <Texto>Desconto</Texto>
                    <Preco>R$ {Number(desconto).toFixed(2).replace(".", ",")}</Preco>
                  </ViewPreco>
                ) : (
                  <></>
                )}

                {parcelasCartao ? (
                  <ViewPreco>
                    <Texto>Parcela</Texto>
                    <Preco>
                      {parcelasCartao}x de {(valorTotal() / parcelasCartao).toFixed(2)}
                    </Preco>
                  </ViewPreco>
                ) : (
                  <></>
                )}

                <ViewPrecoConcluir>
                  <ViewTotal>
                    <Texto>Total: </Texto>
                    <Preco style={{ marginLeft: 5 }}>R$ {Number(valorTotal()).toFixed(2).replace(".", ",")}</Preco>
                  </ViewTotal>

                  <BotaoConcluir corFundo={informacoesApp.cor_primaria} disabled={pagamento === 0 || !aceitouTermos} onClick={concluir}>
                    <TextoConcluir>Concluir</TextoConcluir>
                  </BotaoConcluir>
                </ViewPrecoConcluir>

                <ViewAceitarTermos>
                  <Checkbox checked={aceitouTermos} onChange={aoMudarTermos} />
                  <TextoAceitarTermos>
                    Li e aceito o contrato, a política de privacidade e os <TextVermelho onClick={() => setAbrirModalTermos(true)}>termos de uso</TextVermelho>.
                  </TextoAceitarTermos>
                </ViewAceitarTermos>
              </Precos>
            </ViewContainer>

            {/* {modalCartao &&
            <Modal desktopWidth="100%" mobileWidth="100%" padding={10} alturaDinamica overflow="" fechar={() => setModalCartao(false)} marginTop={100}>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ position: 'absolute', top: -100 }}>
                  <Cards
                    cvc={cvc}
                    expiry={expiracao}
                    focused={focado}
                    name={nomeCartao}
                    number={numeroCartao}
                    placeholders={{ name: 'SEU NOME' }}
                    locale={{ valid: 'expiração' }}
                  />
                </div>
              </div>
            </Modal>
          } */}

            {salvando ? (
              <Modal width={"100%"} height={"100%"}>
                <ViewModal>
                  <CircularProgress />
                  <span style={{ marginTop: 40 }}>Carregando</span>
                  <span>Aguarde um momento.</span>
                  <span>Processando o seu pagamento...</span>
                </ViewModal>
              </Modal>
            ) : abrirModal && (pagamento === 8 || pagamento === 3 || pagamento === 12 || pagamento === 4) ? (
              <Modal width={"100%"} height={"100%"}>
                <ViewModal>
                  {sucesso ? (
                    <ViewSucesso>
                      <TextoSucesso>Parabéns, deu tudo certo.</TextoSucesso>
                      <Lottie animationData={Sucesso} loop={false} style={{ height: 200 }} />

                      <div style={{ display: "flex" }}>
                        {pagamento === 3 && (
                          <BotaoVerificarPagamento onClick={() => enviarMensagem()}>
                            <WhatsAppIcon style={{ color: "#fff", marginRight: 10 }} />
                            <TextoVerificarPix>Enviar Mensagem</TextoVerificarPix>
                          </BotaoVerificarPagamento>
                        )}

                        <BotaoVerificarPagamento onClick={() => voltar()}>
                          <HomeOutlinedIcon style={{ color: "#fff", marginRight: 10 }} />
                          <TextoVerificarPix>Compras</TextoVerificarPix>
                        </BotaoVerificarPagamento>
                      </div>
                    </ViewSucesso>
                  ) : (
                    <>
                      <Cards cvc={cvc} expiry={expiracao} focused={focado} name={nomeCartao} number={numeroCartao} placeholders={{ name: "NOME DO SEU CARTÃO AQUI" }} locale={{ valid: "expiração" }} />

                      <ViewInputs onSubmit={concluir}>
                        <Input type="text" name="name" placeholder="Nome Cartão" onFocus={pegarFocado} required onChange={aoMudar} />

                        <Input type="tel" name="number" pattern="[\d| ]{16,22}" placeholder="Número Cartão" required onFocus={pegarFocado} onChange={aoMudar} />

                        <AlinharInput>
                          <Input type="tel" name="expiry" placeholder="Expiração" onFocus={pegarFocado} required pattern="\d\d/\d\d" onChange={aoMudar} />

                          <Input type="tel" name="cvc" placeholder="Código de segurança" onFocus={pegarFocado} required pattern="\d{3,4}" onChange={aoMudar} />
                        </AlinharInput>

                        <BotaoPagarCartao value="Pagar" type="submit" />
                      </ViewInputs>
                    </>
                  )}
                </ViewModal>
              </Modal>
            ) : abrirModal && (pagamento === 1 || pagamento === 9 || pagamento === 11 || pagamento === 14) ? (
              <Modal width={"100%"} height={"100%"} padding={0}>
                <ViewModal>
                  {sucesso ? (
                    <ViewSucesso>
                      <TextoSucesso>Parabéns, deu tudo certo.</TextoSucesso>
                      <Lottie animationData={Sucesso} loop={false} style={{ height: 200 }} />

                      <BotaoVerificarPagamento style={{ position: "absolute", bottom: 50 }} onClick={() => voltar()}>
                        <HomeOutlinedIcon style={{ color: "#fff", marginRight: 10 }} />
                        <TextoVerificarPix style={{ fontSize: "1.2em" }}>Compras</TextoVerificarPix>
                      </BotaoVerificarPagamento>
                    </ViewSucesso>
                  ) : dadosPagamento?.pix ? (
                    <ViewPix>
                      <Lottie animationData={Sucesso} loop={false} style={{ height: 150, marginBottom: -20 }} />
                      <TituloPIX>Código PIX gerado!</TituloPIX>

                      <QRCode size={256} style={{ height: 256, width: 256 }} value={String(dadosPagamento.pix)} viewBox={`0 0 256 256`} />

                      {/* <TextoPix>{dadosPagamento.pix}</TextoPix> */}

                      <BotaoCopiarPIX onClick={copiarPix}>
                        <TextoCopiarPIX>{copiado ? "COPIADO" : "COPIAR CÓDIGO PIX"}</TextoCopiarPIX>
                      </BotaoCopiarPIX>

                      <ViewCancelamento>
                        <TempoDeCancel>
                          O pagamento do ingresso deve ser realizado em até
                          {"\n"}
                          <TempoDeCancel style={{ color: "blue", fontSize: 20 }}>{tempo_de_cancelamento}</TempoDeCancel>. Após isso, o pedido será {"\n"}
                          <TempoDeCancel style={{ color: "red", fontSize: 20 }}>cancelado automaticamente</TempoDeCancel>.
                        </TempoDeCancel>
                      </ViewCancelamento>

                      <Botoes>
                        <BotaoVerificarPagamento onClick={() => verificarPagamento()}>
                          {verificandoPagamento ? <CircularProgress style={{ color: "#fff" }} /> : <TextoVerificarPix>Verificar Pagamento</TextoVerificarPix>}
                        </BotaoVerificarPagamento>

                        <BotaoVerificarPagamento onClick={() => voltar()}>
                          <HomeOutlinedIcon style={{ color: "#fff", marginRight: 10 }} />
                          <TextoVerificarPix>Compras</TextoVerificarPix>
                        </BotaoVerificarPagamento>
                      </Botoes>
                    </ViewPix>
                  ) : (
                    <>
                      <p>Ocorreu um erro, tente novamente.</p>
                    </>
                  )}
                </ViewModal>
              </Modal>
            ) : (
              pagamento === 3 && <></>
            )}

            {abrirModalTermos && (
              <Modal width={"80%"} fechar={() => setAbrirModalTermos(false)}>
                <ViewTermos>
                  <TextModal>
                    <Titulo>Aceitação dos Termos de Uso:</Titulo>
                    {"\n"}
                    Estes Termos de Uso disciplinam os termos e condições aplicáveis a seu uso dos Serviços. Ao utilizar os Serviços, você ratifica que leu, compreendeu e concordou em ser legalmente
                    vinculado a estes Termos.
                    {"\n"}
                    {"\n"}
                    Se você não concorda com estes Termos, por favor não utilize os Serviços.
                    {"\n"}
                    {"\n"}
                    Sujeito às condições abaixo, o {informacoesApp.nome_app} poderá modificar estes Termos periodicamente e de modo não retroativo, e modificar, adicionar ou descontinuar qualquer
                    aspecto, conteúdo ou característica dos Serviços, a seu próprio critério. Seu uso ou acesso continuado dos Serviços após a publicação de quaisquer mudanças nos Termos constitui sua
                    aceitação de tais mudanças. Na medida em que uma lei ou decisão judicial aplicável determine que a aplicação de quaisquer mudanças a estes Termos seja inexequível, tais mudanças
                    deverão ser aplicáveis apenas com relação a eventos ou circunstâncias que ocorrerem após a data de tais mudanças, na medida em que for necessário para evitar que estes Termos sejam
                    considerados inexequíveis.
                    {"\n"}
                    {"\n"}
                    Qualquer forma de transferência ou sublicença, ou acesso, distribuição, reprodução, cópia, retransmissão, publicação, venda ou exploração (comercial ou não) não autorizados de
                    qualquer parte dos Serviços incluindo, mas não se limitando a todo conteúdo, serviços, produtos digitais, ferramentas ou produtos, é expressamente proibida por estes Termos.
                    {"\n"}
                    {"\n"}
                    <Titulo>Condições de compra:</Titulo>
                    {"\n"}- Limite de compra por Usuário: até {dados?.empresa?.venda_usuario} ingressos para cada dia de evento.
                    {"\n"}
                    {"\n"}
                    Formas de pagamento disponibilizadas: {dados?.empresa?.tipo_pagamento?.status_libera_online && dados?.empresa?.pagamentos_disponiveis?.primeiro}{" "}
                    {dados?.empresa?.tipo_pagamento?.status_libera_local && "e " + dados?.empresa?.pagamentos_disponiveis?.segundo}
                    {"\n"}
                    {"\n"}
                    Cancelamentos, Trocas e Devoluções: Não serão aceitos pedidos de cancelamentos do Ingresso. Após inserir seus dados, escolher o evento, setor e finalizar a compra não é possível
                    substituir nenhum desses itens tampouco devolver o ingresso.
                    {"\n"}
                    {"\n"}
                    <Titulo>Taxa de Conveniência:</Titulo>
                    {"\n"}A taxa de serviço é o valor cobrado pela {informacoesApp.nome_app} quando Você compra ingressos para Eventos parceiros da {informacoesApp.nome_app} através de nossos
                    Serviços. O valor da taxa de serviço é mostrado durante o processo de compra de ingressos.
                    {"\n"}
                    {"\n"}
                    <Titulo>Cancelamento do evento / show:</Titulo>
                    {"\n"}
                    Eventuais alterações na programação, tais como cancelamento do evento ou do show após a conclusão da compra, são de responsabilidade legal e exclusiva dos Produtores do evento,
                    devendo estes se responsabilizarem por devoluções de valores e indenizações. Na hipótese de cancelamento do evento / show, seja por casos fortuitos ou motivos de força maior, ou
                    por atos de intervenção do Poder Público, o {informacoesApp.nome_app} não será responsabilizada de nenhuma forma.
                    {"\n"}
                    {"\n"}
                    <Titulo>Documentação de Entrada:</Titulo>
                    {"\n"}- No dia do evento, apresente-o diretamente na entrada do evento, juntamente com documento com foto: CNH ou RG (original ou cópia autenticada) do(a) titular da compra.
                    {"\n"}- Idosos: com idade superior a 60 anos: CNH ou RG (original ou cópia autenticada).
                    {"\n"}
                    {"\n"}
                    <Titulo>Direito de Uso de Imagens:</Titulo>
                    {"\n"}O evento / show poderá ser filmado, gravado, fotografado a critério do organizador / produtor, para posterior publicação, transmissão, retransmissão, reprodução ou divulgação
                    em TV, cinema, rádio, internet, publicidade ou qualquer outro veículo de comunicação, produção de DVD e home-vídeo. Ao comparecer ao evento / show, você concorda, autoriza e cede o
                    uso gratuito de sua imagem, nome, voz nos termos ora mencionados, sem limitação, sem que caracterize uso indevido ou qualquer outra violação de direitos e sem que deste uso decorra
                    qualquer ônus / indenização. O comparecimento ao evento / show implica a aceitação incondicional do termo acima.
                    {"\n"}
                    {"\n"}
                    <Titulo>Política de Privacidade:</Titulo>
                    {"\n"}
                    Seu uso dos Serviços e o tratamento, por parte da {informacoesApp.nome_app}, de qualquer informação fornecida por você ou recolhida pela {informacoesApp.nome_app} ou partes
                    terceiras durante qualquer visita ou uso dos Serviços é regida pela Política de Privacidade, aqui incorporada por meio desta referência. A Política de Privacidade é o documento que
                    regula o tratamento de dados pessoais e/ou confidenciais pela {informacoesApp.nome_app} e seus prestadores de serviços ou parceiros. A coleta, uso e compartilhamento, por parte da{" "}
                    {informacoesApp.nome_app}, de suas informações pessoais serão realizados nos termos determinados na Política de Privacidade, razão pela qual você deve ler tal Política com atenção
                    e descontinuar o uso dos Serviços caso não concorde com a descrição do tratamento.
                    {"\n"}
                    {"\n"}
                    {dados?.empresa?.permitir_menor === "Não" && (
                      <TextModal>
                        <Titulo>Menores de Idade:</Titulo>
                        {"\n"}
                        Os Serviços não foram formulados nem são destinados para serem usados por indivíduos menores de 18 anos e, portanto, se você for menor de 18 anos, solicitamos que você não use
                        os Serviços nem nos forneça qualquer dado pessoal.
                      </TextModal>
                    )}
                    <Titulo style={{ color: "blue" }}>• IMPORTANTE: </Titulo>
                    {"\n"}
                    Em caso de cópias do e-Ticket, o sistema de segurança validará apenas o primeiro acesso.
                    {"\n"}
                    {"\n"}
                    Após validados uma vez, o e-Ticket não poderá ser reutilizado.
                    {"\n"}
                    {"\n"}
                    Não nos responsabilizamos por qualquer PERDA, DANO ou ROUBO do seu e-Ticket.
                    {"\n"}
                    {"\n"}
                    Nós da {informacoesApp.nome_app} e a Empresa pelo evento não se responsabiliza pela transferência de ingressos ou revenda do mesmo.
                    {"\n"}
                    {"\n"}
                    {tempo_de_cancelamento !== "Não Cancelar" && (
                      <TextModal>
                        O pagamento do ingresso deve ser realizado em através <TextTempoCancel>{tempo_de_cancelamento}</TextTempoCancel>. Após isso, o pedido será{" "}
                        <TextVermelho>cancelado automaticamente</TextVermelho>.
                      </TextModal>
                    )}
                    {"\n"}
                    {"\n"}
                  </TextModal>
                </ViewTermos>
              </Modal>
            )}

            <Snackbar
              open={mensagemAlerta?.length > 0}
              // autoHideDuration={5000}
              onClose={handleClose}
              message={mensagemAlerta}
              TransitionComponent={Fade}
              action={
                <React.Fragment>
                  <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </React.Fragment>
              }
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />

            <Dialog open={abrirMensagemDialogo} onClose={handleCloseModalTransferir}>
              <DialogTitle id="alert-dialog-title">Verificação de Dados</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{mensagemDialogo}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseModalTransferir()}>Cancelar</Button>
                <Button onClick={() => navigate(`/usuario?voltarUrl=/compra/${evento}/${produto}`)} autoFocus>
                  Editar
                </Button>
              </DialogActions>
            </Dialog>

            {/* <Rodape></Rodape> */}
          </>
        )}
      </Responsivel>
    </Container>
  );
}
