import styled from "styled-components";

const width_ = window.innerWidth;

export const Container = styled.div`
	position: relative;
	width: 100%;
	margin: 0 auto;
  overflow: hidden;

  align-self: center;
`;

export const ContainerCategoria = styled.div`
  height: 50px;
	box-sizing: border-box;
	white-space: nowrap;
  padding: 0px 10px;
	overflow-x: auto;
	overflow-y: hidden;
  
	-webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ViewCategoria = styled.div`
	display: inline-block;
  margin-right: 25px;
  height: 50px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
  // padding: 0px 10px;
  border-radius: 5px;
`;

export const Info = styled.p`
  color: #878787;
  font-size: 14px;
  z-index: 35;
  text-overflow: ellipsis;
  text-transform: capitalize;
  overflow: hidden; /*needed here*/
`;