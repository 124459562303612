import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { AuthProvider } from "./context/auth";

import { api_key_google, nome_site } from "./config";
import Routes from "./rotas";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }

  html,
  body {
    scroll-behavior: smooth;
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #666666 #ffffff;
    font-family: 'Cairo';
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff00;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #a7a4a4;
    border: 3px solid #ffffff00;
  }
  
`;

function App() {
  document.title = nome_site();

  return (
    <AuthProvider>
      {/* <HashRouter> */}
      <BrowserRouter>
        {/* <GoogleOAuthProvider clientId="508074056862-vnkgrvcpv1c8v8tp6mt34juh4f0mhm7d.apps.googleusercontent.com"> */}
        <GoogleOAuthProvider clientId={api_key_google()}>
          {/* <GoogleOAuthProvider clientId="822983055998-hb62iergq2gn9tseig1164pudr72fc5m.apps.googleusercontent.com"> */}
          <GlobalStyles />
          <Routes />
        </GoogleOAuthProvider>
      </BrowserRouter>
      {/* </HashRouter> */}
    </AuthProvider>
  );
}

export default App;
