/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Backdrop, Button, Fade, IconButton, InputAdornment, Snackbar, TextField } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { Botao, Container, Form, InputData, SelectInput, ViewInputs } from "./styles";

import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Header from "../../componentes/Header";
import Responsivel from "../../componentes/Responsivel";
import api from "../../servicos/api";

export default function Usuario() {
  const { usuario, carregando, informacoesApp } = useContext(AuthContext);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const voltarUrl = searchParams.get("voltarUrl");

  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [senha, setSenha] = useState("");
  const [celular, setCelular] = useState("");
  const [sexo, setSexo] = useState("");
  const [mensagemAlerta, setMensagemAlerta] = useState("");
  const [data_nascimento, setDataNascimento] = useState("2022-01-01");
  const [editando, setEditando] = useState(false);
  const [mudouAlgumaCoisa, setMudouAlgumaCoisa] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setNome(usuario?.nome ?? "");
    setCpf(usuario?.cpf ?? "");
    setSenha(usuario?.senha ?? "");
    setCelular(usuario?.celular ?? "");
    setSexo(usuario?.sexo ?? "");
    setDataNascimento(usuario?.data_nascimento ?? "2022-01-01");
  }, [carregando]);

  const handleChange = (event) => {
    setSexo(event.target.value);

    if (event.target.value !== (usuario?.sexo ?? "")) {
      setMudouAlgumaCoisa(true);
    } else {
      setMudouAlgumaCoisa(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMensagemAlerta("");
  };

  async function editar(e) {
    e.preventDefault();

    setEditando(true);

    return new Promise(async (resolve, reject) => {
      const obj = {
        id: usuario?.id,
        nome: nome ?? usuario?.nome,
        sexo: sexo ?? usuario?.sexo,
        data_nascimento: data_nascimento ?? usuario?.data_nascimento,
        celular: celular ?? usuario?.celular,
        cpf: cpf ?? usuario?.cpf,
        senha: senha ?? usuario?.senha,
      };

      await api
        .post("usuario/editar.php", obj)
        .then(async (response) => {
          setMensagemAlerta(response.data.mensagem);

          if (response.data.sucesso === true) {
            resolve(true);
            setMudouAlgumaCoisa(false);
            window.location.reload();
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });

      setTimeout(() => {
        setEditando(false);
      }, 1500);
    });
  }

  const cpfMask = (value) => {
    return value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  const regexCelular = (value) => {
    return value
      ?.replace(/\D/g, "")
      ?.replace(/(\d{2})(\d)/, "($1) $2")
      ?.replace(/(\d{5})(\d)/, "$1-$2")
      ?.replace(/(-\d{4})\d+?$/, "$1");
  };

  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const action = (
    <Button color="primary" size="small" onClick={() => (voltarUrl ? navigate(voltarUrl) : null)}>
      Continuar com a Compra
    </Button>
  );

  if (carregando) {
    return <p>carregando</p>;
  }

  return (
    <Container>
      <Header />

      <Backdrop open={editando}>
        <CircularProgress />
      </Backdrop>

      <Responsivel>
        <Form onSubmit={editar}>
          <TextField
            type="text"
            value={nome}
            style={{ marginBottom: 10, width: "90%", maxWidth: 400 }}
            label="Nome Completo"
            fullWidth
            maxLength={40}
            minLength={5}
            onChange={(e) => {
              setNome(e.target.value);

              if (e.target.value !== (usuario?.nome ?? "")) {
                setMudouAlgumaCoisa(true);
              } else {
                setMudouAlgumaCoisa(false);
              }
            }}
          />

          {usuario?.email && <TextField type="text" value={usuario?.email} style={{ marginBottom: 10, width: "90%", maxWidth: 400 }} label="Email" disabled fullWidth />}

          <TextField
            type="tel"
            style={{ marginBottom: 10, width: "90%", maxWidth: 400 }}
            value={celular}
            label="Celular"
            fullWidth
            onChange={(e) => {
              setCelular(regexCelular(e.target.value));

              if (e.target.value !== (usuario?.celular ?? "")) {
                setMudouAlgumaCoisa(true);
              } else {
                setMudouAlgumaCoisa(false);
              }
            }}
          />

          <TextField
            type="text"
            style={{ width: "90%", maxWidth: 400 }}
            value={cpf}
            fullWidth
            // inputProps={{ minLength: 11 }}
            label="CPF"
            onChange={(e) => {
              setCpf(cpfMask(e.target.value));

              if (e.target.value !== (usuario?.cpf ?? "")) {
                setMudouAlgumaCoisa(true);
              } else {
                setMudouAlgumaCoisa(false);
              }
            }}
          />

          <ViewInputs>
            <SelectInput
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sexo}
              label="Sexo"
              onChange={handleChange}
              required
              disabled={sexo.length > 0 || usuario?.sexo?.length > 0}
              displayEmpty
            >
              <MenuItem value="">Sexo</MenuItem>
              <MenuItem value="Outro">Não Informar</MenuItem>
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Feminino">Feminino</MenuItem>
            </SelectInput>

            <InputData
              type="date"
              value={data_nascimento}
              placeholder="Data Nascimento"
              required
              onChange={(e) => {
                setDataNascimento(e.target.value);

                if (e.target.value !== (usuario?.data_nascimento ?? "")) {
                  setMudouAlgumaCoisa(true);
                } else {
                  setMudouAlgumaCoisa(false);
                }
              }}
            />
          </ViewInputs>

          {usuario?.senha && (
            <TextField
              type={showPassword ? "text" : "password"}
              style={{ width: "90%", maxWidth: 400 }}
              value={senha}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClick} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Senha"
              required
              onChange={(e) => {
                setSenha(e.target.value);

                if (e.target.value !== (usuario?.senha ?? "")) {
                  setMudouAlgumaCoisa(true);
                } else {
                  setMudouAlgumaCoisa(false);
                }
              }}
            />
          )}

          <Snackbar
            open={mensagemAlerta.length > 0}
            autoHideDuration={6000}
            onClose={handleClose}
            message={mensagemAlerta}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          />

          <Botao disabled={!mudouAlgumaCoisa} corFundo={informacoesApp.cor_primaria} type="submit" value="Salvar" />
        </Form>
      </Responsivel>

      <Snackbar
        open={voltarUrl && mensagemAlerta.length <= 0}
        message="Deseja continuar com a compra?"
        action={action}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </Container>
  );
}
