import React, { useContext } from "react";

import { logo_app_caminho, url_site } from "../../config";
import { AuthContext } from "../../context/auth";

import { Container, ImagemLogo, Informacao, LogoCenterIngressos, TextoDireitos, TextoL, ViewLogos } from "./styles";

import InstagramIcon from "@mui/icons-material/Instagram";
import Apple from "../../imagens/black.svg";
import Google from "../../imagens/google-play-badge.png";

export default function Rodape({ marginTop }) {
  const { informacoesApp } = useContext(AuthContext);

  return (
    <Container style={{ marginTop, backgroundColor: informacoesApp.cor_rodape }}>
      <LogoCenterIngressos src={logo_app_caminho(url_site, informacoesApp?.logo_app)} alt={`Logo ${informacoesApp?.nome_app}`} />

      <div
        style={{ position: "absolute", right: 15, top: 10, cursor: "pointer" }}
        onClick={() => window.open("https://www.instagram.com/center_ingressos/", "_blank")}
      >
        <InstagramIcon style={{ color: "#fff" }} />
      </div>

      <TextoL>Experiência final em todos os dispositivos</TextoL>
      <ViewLogos>
        <ImagemLogo onClick={() => window.open(url_site + "compartilhamento.php", "_blank")} src={Apple} width={100} alt="apple logo" />
        <ImagemLogo onClick={() => window.open(url_site + "compartilhamento.php", "_blank")} src={Google} width={100} alt="apple logo" />
      </ViewLogos>

      <Informacao>
        {informacoesApp?.razao_social_app} - CNPJ {informacoesApp?.cnpj_app}
      </Informacao>

      <TextoDireitos>
        Todos os direitos reservados. © {informacoesApp?.ano_lanc_app} {informacoesApp?.nome_app}.
      </TextoDireitos>
    </Container>
  );
}
