/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Skeleton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { url_site } from "../../config";
import { AuthContext } from "../../context/auth";
import { BannerTotal, BotaoFechar, TextoBotaoFechar } from "../Evento/styles";
import { BotaoCadastro, TextoCadastro, ViewCarregando } from "../Home/styles";

import {
  Banner,
  Container,
  Info1,
  Info2,
  Info3,
  Info4,
  Informacao,
  Overlay,
  TextoInformacao,
  TextoModal,
  TextoSemEvento,
  Titulo,
  ViewBanner,
  ViewEventos,
  ViewInformacoesEmpresa,
  ViewPagamentos,
  ViewSemEvento,
} from "./styles";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaymentsIcon from "@mui/icons-material/Payments";
import PixIcon from "@mui/icons-material/Pix";
import Lottie from "lottie-react";
import secureLocalStorage from "react-secure-storage";
import SemEventos from "../../animacoes/semevento.json";
import BotaoVoltar from "../../componentes/BotaoVoltar";
import Header from "../../componentes/Header";
import ListaEventosEmpresa from "../../componentes/ListaEventosEmpresa";
import Responsivel from "../../componentes/Responsivel";
import Rodape from "../../componentes/Rodape";
import api from "../../servicos/api";

export default function Empresa() {
  const { usuario, informacoesApp, carregando } = useContext(AuthContext);
  const { id } = useParams();

  const [dados, setDados] = useState([]);
  const [abrirModalLocalizacao, setAbrirModalLocalizacao] = useState(false);
  const [abrirModalPagamentos, setAbrirModalPagamentos] = useState(false);
  const [abrirImagemEvento, setAbrirImagemEvento] = useState(false);
  const [carregandoEmpresa, setCarregandoEmpresa] = useState(true);

  const navigate = useNavigate();
  const localEnd = secureLocalStorage.getItem("endereco");
  const end = JSON.parse(localEnd);

  useEffect(() => {
    listar();
    window.scrollTo({ top: 0, behavior: "instant" });
    return () => {
      document.title = informacoesApp.nome_app;
    };
  }, [carregando]);

  async function listar() {
    const cliente = secureLocalStorage?.getItem("cliente");

    await api
      .get(`empresa/listar.php?id=${id}&cliente=${cliente}&cidade_usuario=${end?.id ?? 0}`)
      .then((response) => {
        if (response.data.sucesso === true) {
          setDados(response.data.dados);
          document.title = response?.data?.dados?.nome;
        }
      })
      .catch(console.log)
      .finally(() => {
        setCarregandoEmpresa(false);
      });
  }

  const handleCloseModalLocalizacao = () => {
    setAbrirModalLocalizacao(false);
  };

  const handleCloseModalPagamentos = () => {
    setAbrirModalPagamentos(false);
  };

  return (
    <Container>
      <Header />

      <Responsivel>
        {carregandoEmpresa || carregando ? (
          <ViewCarregando>
            {/* <CircularProgress /> */}
            <Skeleton variant="rectangular" width="100%" height={286} />
            <Skeleton variant="rectangular" width="100%" height={70} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={150} sx={{ marginTop: 1 }} />
            <Skeleton variant="rectangular" width="100%" height={150} sx={{ marginTop: 3 }} />
          </ViewCarregando>
        ) : (
          <>
            <ViewBanner>
              <BotaoVoltar onClick={() => navigate("/")} />

              <Banner
                src={url_site + `/sistema/img/banner_evento/${dados?.banner_evento}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = url_site + `/sistema/img/banner_evento/sem-foto10.jpg`;
                }}
              />

              <Info1>{dados?.categoria?.nome}</Info1>
              <Info2>{dados?.nome}</Info2>
              <Info3>{dados?.horario?.horario_definitivo}</Info3>
              <Info4>
                {dados?.cidade?.nome} - {dados?.cidade?.sigla_uf}
              </Info4>

              <Overlay onClick={() => setAbrirImagemEvento(true)} />
            </ViewBanner>

            <ViewEventos>
              <ViewInformacoesEmpresa>
                <Informacao onClick={() => setAbrirModalLocalizacao(true)}>
                  <LocationOnIcon fontSize="small" style={{ color: "#777777" }} />
                  <TextoInformacao>Localização</TextoInformacao>
                </Informacao>

                <Informacao onClick={() => setAbrirModalPagamentos(true)}>
                  <PaymentsIcon fontSize="small" style={{ color: "#777777" }} />
                  <TextoInformacao>Pagamentos</TextoInformacao>
                </Informacao>
              </ViewInformacoesEmpresa>

              {dados?.eventos?.length <= 0 && (
                <ViewSemEvento>
                  <Lottie animationData={SemEventos} loop={true} style={{ height: 200 }} />
                  <TextoSemEvento>Parece que não tem nenhum Evento, ainda...</TextoSemEvento>
                </ViewSemEvento>
              )}

              {dados?.eventos?.ja_aconteceu?.length > 0 && (
                <>
                  <Titulo>Eventos que já Aconteceram</Titulo>
                  <ListaEventosEmpresa dados={dados?.eventos?.ja_aconteceu} />
                </>
              )}

              {dados?.eventos?.esta_semana?.length > 0 && (
                <>
                  <Titulo>Esta Semana</Titulo>
                  <ListaEventosEmpresa dados={dados?.eventos?.esta_semana} />
                </>
              )}

              {dados?.eventos?.semana_que_vem?.length > 0 && (
                <>
                  <Titulo>Semana que vem</Titulo>
                  <ListaEventosEmpresa dados={dados?.eventos?.semana_que_vem} />
                </>
              )}

              {dados?.eventos?.em_breve?.length > 0 && (
                <>
                  <Titulo>Em breve</Titulo>
                  <ListaEventosEmpresa dados={dados?.eventos?.em_breve} />
                </>
              )}
            </ViewEventos>

            {!usuario.id && (
              <BotaoCadastro style={{ backgroundColor: informacoesApp.cor_primaria }} onClick={() => navigate(`/login?voltarUrl=/empresa/${id}`)}>
                <TextoCadastro>Entrar</TextoCadastro>
              </BotaoCadastro>
            )}

            <Dialog open={abrirModalLocalizacao} onClose={handleCloseModalLocalizacao} sx={{ m: 0, p: 2 }}>
              <DialogTitle id="alert-dialog-title">Localização & Outros</DialogTitle>
              <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                  <Typography>Localização</Typography>
                  <Typography sx={{ marginTop: 1 }}>
                    Rua <TextoModal>{dados?.endereco}</TextoModal>
                  </Typography>
                  <Typography>
                    Bairro <TextoModal>{dados?.bairro}</TextoModal>
                  </Typography>
                  <Typography>
                    Número <TextoModal>{dados?.numero}</TextoModal>
                  </Typography>

                  <Typography sx={{ marginTop: 2 }}>Outras Informações</Typography>
                  <Typography sx={{ marginTop: 1 }}>
                    Celular <TextoModal>{dados?.celular}</TextoModal>
                  </Typography>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => handleCloseModalLocalizacao()}>OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={abrirModalPagamentos} onClose={handleCloseModalPagamentos} sx={{ m: 0, p: 2 }}>
              <DialogTitle id="alert-dialog-title">Pagamentos disponíveis</DialogTitle>
              <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                  <ViewPagamentos>
                    <PixIcon />
                    <Typography>Pix</Typography>
                  </ViewPagamentos>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => handleCloseModalPagamentos()}>OK</Button>
              </DialogActions>
            </Dialog>

            <Modal
              open={abrirImagemEvento}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClose={() => setAbrirImagemEvento(false)}
            >
              <>
                <BotaoFechar onClick={() => setAbrirImagemEvento(false)}>
                  <TextoBotaoFechar>Fechar</TextoBotaoFechar>
                </BotaoFechar>

                <BannerTotal
                  src={url_site + `/sistema/img/banner_evento/${dados?.banner_evento}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = url_site + `/sistema/img/banner_evento/sem-foto10.jpg`;
                  }}
                />
              </>
            </Modal>
          </>
        )}
      </Responsivel>

      <Rodape />
    </Container>
  );
}
