import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 40px;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 1000;
  border-radius: 5px;
`;

export const TextoVoltar = styled.span`
  color: #fff;
  font-size: 1em;
`;
