/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { AdminPanelSettings, LocationOnOutlined } from "@mui/icons-material";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { url_site } from "../../config";
import { AuthContext } from "../../context/auth";

import {
  BotaoIconeLoc,
  Container,
  FotoUsuario,
  InputBusca,
  NomeCidade,
  NomeEstado,
  TituloCidade,
  Usuario,
  ViewCidade,
  ViewCidades,
  ViewInfo,
  ViewInputBusca,
  ViewLocalizacao,
  ViewMenu,
  ViewTodasCidades,
} from "./styles";

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import api from "../../servicos/api";
import Modal from "../Modal";

const Header = () => {
  const { sair, usuario, mudarEndereco, endereco, informacoesApp } = useContext(AuthContext);

  const [abrirModalCidades, setAbrirModalCidades] = useState(false);
  const [cidade, setCidade] = useState("");
  const [cidades, setCidades] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (abrirModalCidades && cidade.length > 0) {
      listarCidades();
    }
  }, [cidade, abrirModalCidades]);

  const aoAbrirModalCidades = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
    setAbrirModalCidades(true);
    listarCidades();
  };

  const aoFecharModalCidades = () => {
    setCidade("");
    setCidades([]);
    setAbrirModalCidades(false);
  };

  async function listarCidades() {
    await api
      .get(`cidades/buscar_cidade.php?cidade=${cidade}`)
      .then((response) => {
        setCidades(response?.data?.cidades);
      })
      .catch(console.log);
  }

  function aoMudarEndereco(item) {
    mudarEndereco(item);
    aoFecharModalCidades();
    window.location.reload();
  }

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setDrawerOpen(!drawerOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function aoSair() {
    setOpen(true);
  }

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {usuario?.id && (
          <ListItem disablePadding>
            <ListItemButton>
              <ViewInfo>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Abrir Opções">
                    <IconButton sx={{ p: 0 }}>
                      <FotoUsuario alt="profile-pic" src={usuario ? usuario.foto_pessoal : ""} referrerPolicy="no-referrer" />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Usuario>
                  #{usuario.id} <br></br>
                  {usuario.nome} <br></br> {usuario.email}
                </Usuario>
              </ViewInfo>
            </ListItemButton>
          </ListItem>
        )}

        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography style={{ fontSize: 15 }}>Início</Typography>} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={() => window.open(url_site + "/sistema/index.php", "_self")}>
            <ListItemIcon>
              <AdminPanelSettings />
            </ListItemIcon>
            <ListItemText primary={<Typography style={{ fontSize: 15 }}>Painel Admin</Typography>} />
          </ListItemButton>
        </ListItem>

        {usuario?.id && (
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/usuario")}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography style={{ fontSize: 15 }}>Editar Dados</Typography>} />
            </ListItemButton>
          </ListItem>
        )}

        {usuario?.id && (
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/ingressos")}>
              <ListItemIcon>
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography style={{ fontSize: 15 }}>Compras</Typography>} />
            </ListItemButton>
          </ListItem>
        )}

        {usuario?.id && (
          <ListItem disablePadding>
            <ListItemButton style={{ color: "red" }} onClick={() => aoSair()}>
              <ListItemIcon>
                <SubdirectoryArrowLeftIcon style={{ color: "red" }} />
              </ListItemIcon>

              <ListItemText primary={<Typography style={{ fontSize: 15 }}>Sair</Typography>} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider />
    </Box>
  );

  abrirModalCidades ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "auto");

  return (
    <Container>
      <ViewMenu>
        <Button onClick={toggleDrawer(true)}>
          <MenuIcon fontSize="medium" style={{ color: "#000" }} />
        </Button>
        <Drawer anchor={"right"} open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </ViewMenu>

      <ViewLocalizacao>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Mudar de Localização">
            <Button
              onClick={aoAbrirModalCidades}
              color="inherit"
              style={{
                fontFamily: "Arial",
                fontSize: 16,
                textTransform: "none",
              }}
            >
              <BotaoIconeLoc style={{ backgroundColor: informacoesApp.cor_primaria }}>
                <LocationOnOutlined style={{ color: "#fff" }} fontSize="medium" />
              </BotaoIconeLoc>

              <ViewCidade>
                <TituloCidade>Sua localização</TituloCidade>
                <NomeCidade>{endereco?.nome ? endereco?.nome + " - " + endereco?.nome_uf : "Selecione uma Cidade"}</NomeCidade>
              </ViewCidade>
            </Button>
          </Tooltip>
        </Box>
      </ViewLocalizacao>

      {abrirModalCidades && (
        <Modal width="100%" height="100%" fechar={aoFecharModalCidades}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <ViewInputBusca>
              <InputBusca type="search" placeholder="Selecione uma Cidade" onChange={(e) => setCidade(e.target.value)} />
            </ViewInputBusca>

            <ViewTodasCidades>
              {cidades?.map((item, index) => (
                <ViewCidades key={item.id} onClick={() => aoMudarEndereco(item)}>
                  <NomeCidade>
                    {item.nome}, <NomeEstado>{item.nome_uf}</NomeEstado>
                  </NomeCidade>
                </ViewCidades>
              ))}
            </ViewTodasCidades>
          </div>
        </Modal>
      )}

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Sair</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Deseja realmente sair do Site?</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Não</Button>
          <Button
            onClick={() =>
              sair().then(() => {
                setOpen(false);
                navigate("/");
              })
            }
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default React.memo(Header);
