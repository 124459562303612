import React, { useContext, useState } from "react";

import { Fade, Snackbar } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import { Botao, Container, Form, Input, InputData, LogoCenterIngressos, SelectInput, ViewInputs } from "./styles";
import { logo_app_caminho, url_site } from "../../config";

import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Responsivel from "../../componentes/Responsivel";
import api from "../../servicos/api";

export default function Cadastrar() {
  const { informacoesApp } = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const voltarUrl = searchParams.get("voltarUrl");

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [cadastrando, setCadastrando] = useState(false);
  const [sexo, setSexo] = useState("");
  const [cpf, setCPF] = useState("");
  const [celular, setCelular] = useState("");
  const [data_nascimento, setDataNascimento] = useState("2022-05-03");
  const [mensagemAlerta, setMensagemAlerta] = useState("");

  const handleChange = (event) => {
    setSexo(event.target.value);
  };

  function voltarParaLogin() {
    if (voltarUrl) {
      navigate(`/login?voltarUrl=${voltarUrl}`);
    } else {
      navigate("/login");
    }
  }

  async function cadastrar(e) {
    e.preventDefault();

    if (confirmarSenha !== senha) {
      setMensagemAlerta("As senhas digitadas não coincidem.");
      return;
    }

    const obj = {
      nome,
      email,
      senha,
      data_nascimento,
      sexo,
      cpf,
      celular,
      cidade: "Santa Fé",
      estado: "PR",
      id_token: "",
    };

    await api
      .post("cadastro/cadastrar.php", obj)
      .then((response) => {
        if (response.data.sucesso === true) {
          setCadastrando(false);
          voltarParaLogin();
        } else {
          setCadastrando(false);
        }

        setMensagemAlerta(response?.data?.mensagem);
      })
      .catch((error) => {
        console.debug(error);
        setCadastrando(false);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMensagemAlerta("");
  };

  const cpfMask = (value) => {
    return value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  const regexCelular = (value) => {
    return value
      ?.replace(/\D/g, "")
      ?.replace(/(\d{2})(\d)/, "($1) $2")
      ?.replace(/(\d{5})(\d)/, "$1-$2")
      ?.replace(/(-\d{4})\d+?$/, "$1");
  };

  return (
    <Container>
      <Responsivel>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <LogoCenterIngressos onClick={() => navigate("/")} src={logo_app_caminho(url_site, informacoesApp.logo_app)} alt={`Logo ${informacoesApp.nome_app}`} />

          {cadastrando && <CircularProgress />}

          <Form onSubmit={cadastrar}>
            <Input type="text" value={nome} placeholder="Nome Completo" maxLength={40} minLength={5} onChange={(e) => setNome(e.target.value)} required />

            <ViewInputs>
              <Input
                type="text"
                value={cpf}
                placeholder="CPF"
                required
                onChange={(e) => {
                  setCPF(cpfMask(e.target.value));
                }}
              />

              <Input
                type="tel"
                value={celular}
                placeholder="Celular"
                required
                onChange={(e) => {
                  setCelular(regexCelular(e.target.value));
                }}
              />
            </ViewInputs>

            <Input type="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />

            <ViewInputs>
              <SelectInput labelId="demo-simple-select-label" id="demo-simple-select" value={sexo} label="Sexo" onChange={handleChange} required displayEmpty>
                <MenuItem value="">Sexo</MenuItem>
                <MenuItem value="Outro">Não Informar</MenuItem>
                <MenuItem value="Masculino">Masculino</MenuItem>
                <MenuItem value="Feminino">Feminino</MenuItem>
              </SelectInput>

              <InputData type="date" value={data_nascimento} placeholder="Data Nascimento" onChange={(e) => setDataNascimento(e.target.value)} required />
            </ViewInputs>

            <ViewInputs>
              <Input type="password" value={senha} placeholder="Senha" onChange={(e) => setSenha(e.target.value)} required />

              <Input type="password" value={confirmarSenha} placeholder="Confirmar Senha" onChange={(e) => setConfirmarSenha(e.target.value)} required />
            </ViewInputs>

            <Botao style={{ backgroundColor: informacoesApp.cor_primaria }} type="submit" value="Cadastrar-se" />
          </Form>
        </div>
      </Responsivel>

      <Snackbar
        open={mensagemAlerta.length > 0}
        autoHideDuration={6000}
        onClose={handleClose}
        message={mensagemAlerta}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
    </Container>
  );
}
