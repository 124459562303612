import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
  /* background-color: #151B26; */
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 100px;
`;

export const ViewLogos = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImagemLogo = styled.img`
  margin-right: 15px;
  cursor: pointer;
`;

export const TextoL = styled.p`
  font-size: 1em;
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0px;
  margin-top: 10px;
`;

export const ViewInformacoes = styled.div`

`;

export const Informacao = styled.p`
  font-size: 0.8em;
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0px;
  margin: 10px 0px;
`;

export const TextoDireitos = styled.p`
  font-size: 0.8em;
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0px;
  position: absolute;
  bottom: 10px;
`;

export const LogoCenterIngressos = styled.img`
  width: 100px;
  /* height: 80px; */
  margin-bottom: 10px;
`;