/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate, useSearchParams } from "react-router-dom";
import { logo_app_caminho, url_site } from "../../config";
import { AuthContext } from "../../context/auth";

import { Botao, BotaoCadastrar, CadastraseTexto, Container, Form, Input, LogoCenterIngressos, TextoAzul } from "./styles";

import Responsivel from "../../componentes/Responsivel";
import api from "../../servicos/api";

export default function Login() {
  const { entrar, entrarGoogle, usuario, informacoesApp, carregando } = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const voltarUrl = searchParams.get("voltarUrl");

  const navigate = useNavigate();

  const [usuarioL, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [mensagemAlerta, setMensagemAlerta] = useState("");
  const [emailRecuperacao, setEmailRecuperacao] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [enviandoEmail, setEnviandoEmail] = useState(false);
  const [abrirModalEmailRecuperacao, setAbrirModalEmailRecuperacao] = useState(false);

  useEffect(() => {
    if (usuario.id) {
      navigate("/");
    }
  }, []);

  async function logar(e) {
    e.preventDefault();

    const obj = {
      usuario: usuarioL,
      senha,
    };

    entrar(obj).then((sucesso) => {
      if (sucesso) {
        setMensagemAlerta("Logou com Sucesso!");
        if (voltarUrl) {
          navigate(voltarUrl);
        } else {
          navigate("/");
        }
      } else {
        setMensagemAlerta("Dados Incorretos, tente novamente!");
      }
    });
  }

  function irCadastrar() {
    if (voltarUrl) {
      navigate(`/cadastrar?voltarUrl=${voltarUrl}`);
    } else {
      navigate("/cadastrar");
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMensagemAlerta("");
  };

  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const aoFecharModalEmail = () => {
    setAbrirModalEmailRecuperacao(false);
  };

  // enviar email de recuperação de senha
  async function enviarEmailRecuperacao() {
    setEnviandoEmail(true);
    const obj = {
      emailCliente: emailRecuperacao,
    };

    await api
      .post("email/recuperar_senha.php", obj)
      .then((response) => {
        if (response.data.mensagem) {
          setMensagemAlerta(response.data.mensagem);
        } else {
          setMensagemAlerta("O email foi enviado com Sucesso.");
          aoFecharModalEmail();
        }

        setEnviandoEmail(false);
      })
      .catch((error) => {
        console.debug(error);
        setEnviandoEmail(false);
      });
  }

  return (
    <Container>
      <Responsivel>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <LogoCenterIngressos
            onClick={() => navigate("/")}
            src={logo_app_caminho(url_site, informacoesApp.logo_app)}
            alt={`Logo ${informacoesApp.nome_app}`}
          />

          <Form onSubmit={logar}>
            <Input type="email" value={usuarioL} placeholder="Email" onChange={(e) => setUsuario(e.target.value)} required />

            <Input
              type="password"
              value={senha}
              placeholder="Senha"
              onChange={(e) => setSenha(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClick} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Botao style={{ backgroundColor: informacoesApp.cor_primaria }} type="submit" value="Entrar" />

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={mensagemAlerta.length > 0}
              autoHideDuration={6000}
              onClose={handleClose}
              message={mensagemAlerta}
              TransitionComponent={Fade}
            />

            <GoogleLogin
              width={"100%"}
              onSuccess={(c) =>
                entrarGoogle(c).then(() => {
                  navigate(-1);
                })
              }
              onError={() => {
                alert("Falha no login, tente novamente.");
              }}
            />

            <BotaoCadastrar onClick={() => irCadastrar()}>
              <CadastraseTexto>
                Não está cadastrado? <TextoAzul>Cadastra-se</TextoAzul>
              </CadastraseTexto>
            </BotaoCadastrar>

            <BotaoCadastrar onClick={() => setAbrirModalEmailRecuperacao(true)}>
              <CadastraseTexto>
                Recuperar Conta <TextoAzul>Clique Aqui</TextoAzul>
              </CadastraseTexto>
            </BotaoCadastrar>
          </Form>

          <Dialog open={abrirModalEmailRecuperacao} onClose={aoFecharModalEmail}>
            <DialogTitle>Recuperação de Dados</DialogTitle>
            <DialogContent>
              <DialogContentText>Coloque o seu e-mail abaixo para recuperar sua conta.</DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="E-mail"
                type="email"
                fullWidth
                variant="standard"
                value={emailRecuperacao}
                onChange={(e) => setEmailRecuperacao(e.target.value)}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={aoFecharModalEmail}>Cancelar</Button>
              <Button disabled={enviandoEmail} onClick={enviarEmailRecuperacao}>
                {enviandoEmail ? <CircularProgress size={24} /> : "Recuperar"}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Responsivel>
    </Container>
  );
}
