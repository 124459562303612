import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Container, ImagemEvento, Info, ItemEvento, ViewPropaganda } from "./styles";

import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { url_site } from "../../config";

function ListaPropagandas({ dados }) {
  const [rolando, setRolando] = useState(false);

  const navigate = useNavigate();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToScroll: 1,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 320,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  function aoClicar(item) {
    if (rolando) return;

    if (item.tipo_banner === "1" && item.interno_publicidade !== null) {
      navigate(`/propaganda/${item.id_cliente_publicidade}`);
    } else if (item.tipo_banner === "2" && item.externo_publicidade !== null) {
      window.open(item.externo_publicidade);
    } else if (item.tipo_banner === "4" && item.id_evento !== null) {
      navigate(`/evento/${item.id_evento}`, { replace: true });
    }
  }

  return (
    <Container>
      <div style={{ padding: "2px 10px" }}>
        <Slider {...settings} afterChange={() => setRolando(false)} onSwipe={() => setRolando(true)}>
          {dados.map((item, index) => (
            <ItemEvento key={item.id} onClick={() => aoClicar(item)}>
              {/* <div style={{ width: '98%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}

              <ViewPropaganda>
                <ImagemEvento
                  src={item.foto}
                  alt="Imagem do Evento"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = url_site + "/sistema/img/eventos/sem-foto10.jpg";
                  }}
                />

                <Info
                  style={{
                    position: "absolute",
                    left: 15,
                    bottom: 36,
                    color: "#999",
                  }}
                >
                  {item.nome_empresa}
                </Info>
                <Info
                  style={{
                    position: "absolute",
                    left: 15,
                    bottom: 16,
                    color: "#999",
                  }}
                >
                  {item.nome_cidade}
                </Info>
                <Info
                  style={{
                    position: "absolute",
                    left: 15,
                    bottom: -5,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "65%",
                    overflow: "hidden",
                  }}
                >
                  {item.nome}
                </Info>

                <Info
                  style={{
                    position: "absolute",
                    right: 25,
                    bottom: 16,
                    color: "#999",
                  }}
                >
                  {item.dia_da_semana}
                </Info>
                <Info style={{ position: "absolute", right: 25, bottom: -5 }}>{item.data_evento}</Info>

                {/* <Overlay /> */}
              </ViewPropaganda>
            </ItemEvento>
          ))}
        </Slider>
      </div>
    </Container>
  );
}

export default ListaPropagandas;
