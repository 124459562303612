import ReactDOM from 'react-dom';

import { BotaoFechar, Container, ContainerModal, Overlay } from './styles';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

export default function Modal({ danger, width = '70%', marginTop = 0, desktopWidth, mobileWidth, height = '80%', overflow = 'hidden', alturaDinamica = false, padding = 24, children, mostrarBotaoFechar, fechar }) {
  return ReactDOM.createPortal(
    <ContainerModal>
      <Overlay onClick={fechar}></Overlay>
      <Container desktopWidth={desktopWidth} mobileWidth={mobileWidth} width={width} style={alturaDinamica ? { padding, overflow, marginTop } : {  height, padding, overflow, marginTop }} danger={danger}>
        {(mostrarBotaoFechar && fechar) &&
          <BotaoFechar onClick={fechar}>
            <CloseIcon fontSize="small" />
          </BotaoFechar>
        }

        {children}
      </Container>
    </ContainerModal>
    ,
    document.getElementById('modal-root'),
  );
}

Modal.propTypes = {
  danger: PropTypes.bool,
};

Modal.defaultProps = {
  danger: false,
};